import { PlusOutlined } from '@ant-design/icons'
import { Form, Radio } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import { FormAutoComplete, FormInput, FormMaskInput, FormSelect } from 'ui'
import {
  FormError,
  handleValuesChange,
  SelectOption,
  validateBirthday,
  validateCarrier,
} from 'utils'

import {
  Attachment,
  Carrier,
  DocumentType,
  TrellisRelationshipTypes,
} from '../../../../api/attachment-standalone/attachment-standalone-client'
import {
  ProviderType,
  TrellisProvider,
} from '../../../../api/provider/providerApi'
import { Errors } from '../../../../constants/errors'
import { NotifyText } from '../../../../constants/notifyText'
import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import { showMessage } from '../../../../utilities/general'
import { FilteredOption } from '../../../Eligibility/shared/form/utilities/eligibilityFormTyping'
import { ImageRules, PatientInfo } from '../../AttachmentsTypes'
import {
  addTrellisProvider,
  updateAttachmentPatientInformation,
  updatePatientProviderFields,
} from '../../AttachmentView/_attachmentViewServices'
import { useAttachmentsContext } from '../../Context/AttachmentsContext'
import AddProviderForm from './Forms/AddProviderForm'

import './PatientInfoModal.scss'

import { VyneButton, VyneModal, VyneRadio } from '@vynedental/design-system'
import _ from 'lodash'

type PatientInfoModalProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  attachment: Attachment
  setAttachment: Dispatch<SetStateAction<Attachment>>
  setAttachmentLoaded: Dispatch<SetStateAction<boolean>>
  providers: TrellisProvider[]
  providerOptions: SelectOption[]
  defaultProvider: TrellisProvider
  setAttachmentId: Dispatch<SetStateAction<number>>
  setImageTypes: Dispatch<SetStateAction<DocumentType[]>>
  setImageRules: Dispatch<SetStateAction<ImageRules>>
  lastSavedState: PatientInfo
}

const PatientInfoModal: FC<PatientInfoModalProps> = ({
  attachment,
  defaultProvider,
  isOpen,
  lastSavedState,
  providerOptions,
  providers,
  setAttachment,
  setAttachmentId,
  setAttachmentLoaded,
  setImageTypes,
  setImageRules,
  setIsOpen,
}) => {
  const {
    authentication: trellisAuthentication,
    getTrellisProviderDetails,
    trellisProviderDetails,
  } = useGlobalContext()
  const {
    authentication,
    carriers,
    facilityId,
    patientInfo,
    setPatientInfo,
    setVdsPatientResponse,
    vdsPatientResponse,
  } = useAttachmentsContext()

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showSubscriberContent, setShowSubscriberContent] =
    useState<boolean>(false)
  const [carrierOptions, setCarrierOptions] = useState<FilteredOption[]>([])
  const [filteredCarrierOptions, setFilteredCarrierOptions] =
    useState<FilteredOption[]>(carrierOptions)
  const [patientInfoFormErrors, setPatientInfoFormErrors] = useState<
    FormError[]
  >([])

  const addProviderRef = useRef<boolean>(false)
  const providerWasEditedRef = useRef<boolean>(false)
  const selectedDropdownProviderRef = useRef<TrellisProvider>({
    ...defaultProvider,
  })

  const [patientInfoForm] = Form.useForm<PatientInfo>()

  useEffect(() => {
    if (carriers) {
      try {
        setLoading(true)
        const options: FilteredOption[] = carriers.map((carrier, index) => {
          return { value: carrier.name, key: index }
        })

        options?.sort((a, b) => (a.value > b.value ? 1 : -1))

        setCarrierOptions(options)
        setFilteredCarrierOptions(options)
      } catch (ex) {
        showMessage(NotifyText.getCarriersError, 'error')
      } finally {
        setLoading(false)
      }
    }
  }, carriers)

  useEffect(() => {
    if (patientInfo) {
      // Checking for changes made to any provider related input fields
      const currentProviderInfo = {
        providerFirstName: patientInfo?.providerFirstName,
        providerLastName: patientInfo?.providerLastName,
        taxId: patientInfo?.providerTaxId,
        licenseNumber: patientInfo?.providerLicenseNumber,
        providerNPI: patientInfo?.providerNPI,
        taxonomyCode: patientInfo?.providerTaxonomyCode,
      }
      const originalProviderFields = _.pick(
        selectedDropdownProviderRef.current,
        [
          'providerFirstName',
          'providerLastName',
          'taxId',
          'licenseNumber',
          'providerNPI',
          'taxonomyCode',
        ],
      )
      const providersMatch = _.isEqual(
        currentProviderInfo,
        originalProviderFields,
      )
      providerWasEditedRef.current = !providersMatch
      addProviderRef.current =
        !patientInfo.providerId || patientInfo.providerId == 0

      if (patientInfo.subscriberRelationship) {
        patientInfo.subscriberRelationship !== TrellisRelationshipTypes.Self
          ? setShowSubscriberContent(true)
          : setShowSubscriberContent(false)
      }
    }
  }, [patientInfo])

  const onValuesChange = (
    changedValues: { [key in keyof PatientInfo]: string },
    allValues: PatientInfo,
  ) => {
    // Custom handling for subscriber relationship radio group
    if (changedValues.subscriberRelationship) {
      changedValues.subscriberRelationship !== TrellisRelationshipTypes.Self
        ? setShowSubscriberContent(true)
        : setShowSubscriberContent(false)
    }

    // Custom handling for the provider name FormSelect input
    if (changedValues.providerFullName) {
      addProviderRef.current = false
      const selectedProvider = providers.find(
        (provider) =>
          provider.eligibilityDefaultProviderID ===
          parseInt(changedValues.providerFullName),
      )

      selectedDropdownProviderRef.current = { ...selectedProvider }
      if (selectedProvider) {
        const patientInfoCopy = updatePatientProviderFields(
          patientInfo,
          selectedProvider,
        )

        patientInfoForm.setFieldsValue({ ...patientInfoCopy })
        setPatientInfo(patientInfoCopy)
      }
    } else {
      handleValuesChange<PatientInfo>(
        allValues,
        changedValues,
        patientInfoFormErrors,
        patientInfoForm,
        setPatientInfoFormErrors,
        setPatientInfo,
        patientInfo,
      )
    }
  }

  const handlePatientUpdate = async () => {
    setIsSaving(true)

    let patientInfoCopy: PatientInfo

    //new or updated trellis provider
    if (
      addProviderRef.current ||
      providerWasEditedRef.current ||
      selectedDropdownProviderRef.current
    ) {
      patientInfoCopy = { ...patientInfo }
      const providerResponse = await addTrellisProvider(
        authentication,
        facilityId,
        patientInfoCopy,
        addProviderRef.current,
        trellisProviderDetails,
        getTrellisProviderDetails,
      ).catch((e) => {
        setIsSaving(false)
        throw e
      })
      patientInfoCopy = updatePatientProviderFields(
        patientInfo,
        providerResponse.provider,
      )
      providerWasEditedRef.current = false
      addProviderRef.current = false
      setPatientInfo(patientInfoCopy)
      patientInfoForm.setFieldsValue({ ...patientInfoCopy })

      try {
        await updateAttachmentPatientInformation(
          authentication,
          trellisAuthentication,
          facilityId,
          vdsPatientResponse,
          setVdsPatientResponse,
          patientInfoCopy || patientInfo,
          attachment,
          setAttachment,
          providerResponse.fastAttachProviderId,
          setIsOpen,
          setAttachmentLoaded,
          setImageTypes,
          setImageRules,
          setPatientInfo,
        )
      } finally {
        setIsSaving(false)
      }
    }
  }

  const updateCarrier = (value: string) => {
    if (!value) return

    const patientInfoCopy = { ...patientInfo }
    const match = carriers.find(
      (carrier: Carrier) =>
        carrier.name.toLowerCase().trim() === value.toLowerCase().trim(),
    )
    if (match) {
      patientInfoCopy.carrierName = match.name
      patientInfoCopy.carrierId = match.id
      patientInfoCopy.masterId = match.neaMasterId
    }

    setPatientInfo({ ...patientInfoCopy })
  }

  const handleOnCarrierSearch = (value: string) => {
    if (value) {
      value = value.toLowerCase()
      const filteredOptions: FilteredOption[] = carrierOptions.filter(
        (option: FilteredOption) => option.value.toLowerCase().includes(value),
      )
      setFilteredCarrierOptions(filteredOptions)
    } else setFilteredCarrierOptions(carrierOptions)
  }

  const handleModalClose = () => {
    setIsOpen(false)
    if (
      !attachment?.attachmentId &&
      !attachment?.providerId &&
      !attachment?.patientId &&
      !attachment?.patientInsuranceId
    ) {
      patientInfoForm.resetFields()
      setPatientInfo(null)
      setAttachmentId(null)
      setVdsPatientResponse(null)
      return
    }

    patientInfoForm.setFieldsValue({ ...lastSavedState })
    setPatientInfo(lastSavedState)
  }

  const validateProvider = (provider: string, addProvider: boolean) => {
    let error: string = null
    if (!addProvider && !provider) error = 'Provider selection is required'

    if (error) return Promise.reject(new Error(error))
    else return Promise.resolve()
  }

  const handleAddProvider = () => {
    addProviderRef.current = true

    const patientInfoCopy: PatientInfo = {
      ...patientInfo,
      providerId: 0,
      providerFullName: '',
      providerFirstName: '',
      providerLastName: '',
      providerServiceType: ProviderType.Billing,
      providerTaxId: '',
      providerLicenseNumber: '',
      providerNPI: '',
      providerTaxonomyCode: '',
      providerIsDefault: false,
    }

    setPatientInfo(patientInfoCopy)
    patientInfoForm.setFieldsValue({ ...patientInfoCopy })
  }

  return (
    <VyneModal
      dataTestId='standalone-attachments-patient-info-modal'
      title={'Patient Information'}
      closable
      destroyOnClose={true}
      onCancel={handleModalClose}
      open={isOpen}
      width={600}
      footer={[
        <VyneButton
          dataTestId='cancel-patient-info-button'
          key='cancel'
          onClick={handleModalClose}
        >
          Cancel
        </VyneButton>,
        <VyneButton
          dataTestId='save-patient-info-button'
          form='patientInfoForm'
          htmlType='submit'
          loading={isSaving}
          key='save'
          type='primary'
        >
          Save
        </VyneButton>,
      ]}
      style={{ top: 20 }}
    >
      <Form
        colon={false}
        form={patientInfoForm}
        name='patientInfoForm'
        onFinish={handlePatientUpdate}
        onFinishFailed={() => showMessage(Errors.formValidationErrors)}
        onValuesChange={(changedValues, allValues) =>
          onValuesChange(changedValues, allValues)
        }
        requiredMark={false}
        initialValues={{
          ...patientInfo,
        }}
      >
        <section className='sa-patient-modal__patient'>
          <p className='sa-patient-modal__patient--title'>Patient</p>
          <div className='sa-patient-modal__patient--content'>
            <FormInput
              label='Last'
              name='patientLastName'
              required
            />
            <FormInput
              label='First'
              name='patientFirstName'
              required
            />
            <FormMaskInput
              format='##/##/####'
              label='DOB'
              mask='_'
              name='patientDateOfBirth'
              required
              validator={validateBirthday}
            />
          </div>
        </section>
        <section className='sa-patient-modal__subscriber'>
          <p className='sa-patient-modal__subscriber--title'>
            Relationship to Subscriber?
          </p>
          <Form.Item name='subscriberRelationship'>
            <Radio.Group style={{ display: 'flex', gap: 8 }}>
              <VyneRadio
                dataTestId='patient-info-modal-self-button'
                value='Self'
              >
                Self
              </VyneRadio>
              <VyneRadio
                dataTestId='patient-info-modal-spouse-button'
                value='Spouse'
              >
                Spouse
              </VyneRadio>
              <VyneRadio
                dataTestId='patient-info-modal-dependent-child-button'
                value='DependentChild'
              >
                Dependent Child
              </VyneRadio>
              <VyneRadio
                dataTestId='patient-info-modal-other-button'
                value='Other'
              >
                Other
              </VyneRadio>
            </Radio.Group>
          </Form.Item>
          {showSubscriberContent && (
            <div className='sa-patient-modal__subscriber--content'>
              <FormInput
                label='Subscriber Last'
                name='subscriberLastName'
                required={
                  patientInfo?.subscriberRelationship !==
                  TrellisRelationshipTypes.Self
                }
              />
              <FormInput
                label='Subscriber First'
                name='subscriberFirstName'
                required={
                  patientInfo?.subscriberRelationship !==
                  TrellisRelationshipTypes.Self
                }
              />
            </div>
          )}
        </section>
        <section className='sa-patient-modal__insurance'>
          <p className='sa-patient-modal__insurance--title'>Insurance</p>
          <div className='sa-patient-modal__insurance-info'>
            <FormAutoComplete
              customHandler={updateCarrier}
              label='Carrier Name'
              name='carrierName'
              onSearchHandler={handleOnCarrierSearch}
              options={filteredCarrierOptions}
              required
              validator={validateCarrier}
            />
            <FormInput
              label='ID/SSN'
              name='memberId'
              required
            />
          </div>
        </section>
        <section className='sa-patient-modal__provider'>
          <span className='sa-patient-modal__provider--title'>
            Treating Provider
          </span>
          <VyneButton
            className='sa-patient-modal__provider-btn'
            dataTestId='add-provider-button'
            icon={<PlusOutlined />}
            onClick={handleAddProvider}
            type='text'
          >
            Add Provider
          </VyneButton>
          <FormSelect
            label='Provider Name'
            name='providerFullName'
            options={providerOptions}
            validator={(value) =>
              validateProvider(value, addProviderRef.current)
            }
          />
        </section>
        <AddProviderForm />
      </Form>
    </VyneModal>
  )
}

export default PatientInfoModal
