import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { CarrierDirectoryApiFactory, Configuration } from './carrier-client'

export namespace CarrierApi {
  const trellisApi: string = trellisConfiguration.trellis_apiUrl

  export const GetCarrierList = async () => {
    return await CarrierDirectoryApiFactory(
      new Configuration({
        basePath: trellisApi + 'carrier-directory',
        accessToken: GlobalState.Auth.AccessToken.peek(),
      }),
    ).carrierDirectoryCarriers()
  }

  export const ToggleCarrierPreference = async (carrierId: string) => {
    return await CarrierDirectoryApiFactory(
      new Configuration({
        basePath: trellisApi + 'carrier-directory',
        accessToken: GlobalState.Auth.AccessToken.peek(),
      }),
    ).carrierDirectoryToggleCarrierPreference(carrierId)
  }

  export const GetNeaCarrierRequirements = async (neaMasterId: number) => {
    return await CarrierDirectoryApiFactory(
      new Configuration({
        basePath: trellisApi + 'carrier-directory',
        accessToken: GlobalState.Auth.AccessToken.peek(),
      }),
    ).carrierDirectoryCarrierRequirements(neaMasterId)
  }
}
