import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Popconfirm, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { compareDesc } from 'date-fns'
import { FC, useState } from 'react'

import { NotifyText } from '../../../../../constants/notifyText'
import api from '../../../../../utilities/api'
import { showMessage } from '../../../../../utilities/general'
import AddProviderModal, { Provider } from './AddProviderModal/AddProviderModal'

import './ProviderList.scss'

import {
  observer,
  useObservable,
  useObserveEffect,
} from '@legendapp/state/react'
import { VyneButton } from '@vynedental/design-system'

interface ProviderResponse extends Provider {
  addDate: string
  lastUpdateDate: string
}

const ProviderList: FC = observer(() => {
  const hasUpdatedProviders = useObservable<boolean>(true)
  const showAddProviderModal = useObservable<boolean>(false)

  const [isAddProviderModalEditMode, setIsAddProviderModalEditMode] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [provider, setProvider] = useState<Provider>()
  const [providers, setProviders] = useState<Provider[]>([])

  useObserveEffect(hasUpdatedProviders, ({ value }) => {
    if (value) getProviders()
  })

  const getProviders = async () => {
    setLoading(true)

    await api
      .getProviders()
      .then(({ data }) => {
        const sortedProviders: ProviderResponse[] = data.providers.sort(
          (providerA: ProviderResponse, providerB: ProviderResponse) => {
            return compareDesc(
              new Date(providerA.addDate),
              new Date(providerB.addDate),
            )
          },
        )
        setProviders(sortedProviders)
      })
      .catch(() => showMessage(NotifyText.getProvidersError, 'error'))
      .finally(() => {
        hasUpdatedProviders.set(false)
        setLoading(false)
      })
  }

  const handleEditProvider = (record: Provider) => {
    setIsAddProviderModalEditMode(true)
    setProvider({ ...record })
    showAddProviderModal.set(true)
  }

  const handleDeleteProvider = (record: Provider) => {
    api
      .deleteProvider(record.eligibilityDefaultProviderID)
      .then(() => {
        hasUpdatedProviders.set(true)
        showMessage(NotifyText.deleteProviderSuccess, 'success')
      })
      .catch(() => showMessage(NotifyText.deleteProviderError, 'error'))
  }

  const tableColumns: ColumnsType<Provider> = [
    {
      dataIndex: ['providerFirstName', 'providerLastName'],
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      render: (_, record: Provider) => (
        <p>
          {record.providerFirstName} {record.providerLastName}
        </p>
      ),
      title: 'Provider Name',
    },
    {
      dataIndex: 'taxId',
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      title: 'Tax ID',
    },
    {
      dataIndex: 'licenseNumber',
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      title: 'License No.',
    },
    {
      dataIndex: 'providerNPI',
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      title: 'NPI No.',
    },
    {
      dataIndex: 'taxonomyCode',
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      title: 'Taxonomy Code',
    },
    {
      dataIndex: 'serviceType',
      onCell: (record: Provider) => ({
        onClick: () => handleEditProvider(record),
      }),
      title: 'Service Type',
    },
    {
      render: (_, record: Provider) => {
        return (
          <section
            aria-label='provider settings'
            className='provider-settings-cell'
          >
            {record.isDefault && (
              <p className='provider-settings-cell__text--default-provider'>
                Default
              </p>
            )}
            <EditOutlined
              className='mr-100'
              onClick={() => handleEditProvider(record)}
            />
            {record.isDefault ? (
              <Tooltip
                placement='topRight'
                title='Default provider cannot be deleted'
                trigger='click'
              >
                <DeleteOutlined />
              </Tooltip>
            ) : (
              <Popconfirm
                cancelText='No'
                className='delete-provider-warning'
                disabled={record.isDefault}
                onCancel={(e) => e.currentTarget.blur()}
                onConfirm={() => handleDeleteProvider(record)}
                okText='Yes'
                placement='topRight'
                title='Are you sure you want to delete this provider?'
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
          </section>
        )
      },
      title: '',
    },
  ]

  return (
    <section className='page-section provider-list__container'>
      {showAddProviderModal.get() && (
        <AddProviderModal
          hasProviders={providers.length > 0}
          hasUpdatedProviders={hasUpdatedProviders}
          isEditMode={isAddProviderModalEditMode}
          isOpen={showAddProviderModal}
          provider={provider}
          setIsEditMode={setIsAddProviderModalEditMode}
          setProvider={setProvider}
        />
      )}
      <div className='flex-row gap-14-px mb-14-px'>
        <h3>Provider List</h3>
        <VyneButton
          dataTestId='add-provider-button'
          onClick={() => showAddProviderModal.set(true)}
          type='primary'
        >
          Add Provider
        </VyneButton>
      </div>
      <p className='page-section__description'>
        Manage your billing and treating provider(s).
      </p>
      <Table
        className='table--non-smart'
        columns={tableColumns}
        dataSource={providers}
        loading={loading}
        pagination={false}
        rowClassName={(_, i) => i % 2 !== 0 && 'table__row--gray'}
        rowKey='eligibilityDefaultProviderID'
        scroll={{ x: 'max-content' }}
      />
    </section>
  )
})

export default ProviderList
