import { Col, Row } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'

import { useClaimDetailContext } from '../../claims/context/claimDetailContext'
import { SavedAttachmentContext } from '../attachment'
import {
  getUrl,
  IAttachment,
  IAttachmentGridItem,
  IAttachmentImage,
  ImageGridEventType,
  ImageOrientationType,
} from '../attachment.interface'
import AttachementsCarrierReference from './attachmentCarrierReference/attachmentCarrierReference'
import AttachmentImageCapture from './attachmentImageCapture/attachmentImageCapture'
import AttachmentImageGrid from './attachmentImageGrid/attachmentImageGrid'
import SentAttachmentImageGrid from './attachmentImageGridSent/attachmentImageGridSent'
import AttachmentImageView from './attachmentImageView/attachmentImageView'
import AttachmentNarrative from './attachmentNarrative/attachmentNarrative'
import AttachmentRequirementGrid from './attachmentRequirementGrid/attachmentRequirementGrid'

import '../attachment.scss'

import _ from 'lodash'

import { ImageType } from '../../../api/attachment/attachment-client'
import {
  AttachmentInformation,
  PatientName,
} from '../../attachmentsShared/shared/shared.interface'
import {
  getAttachmentImageArray,
  handleImageType,
  patientNameParse,
} from '../../attachmentsShared/shared/sharedFunctions'
import { useClaimGridContext } from '../../claims/context/claimGridContext'
import { isAttachmentTypeXray } from '../attachmentFunctions'
import {
  imagesOptions,
  optionType,
  otherOptions,
} from '../imageDetails/components/imageTypeDropdown/dropdownOptions'
import { AttachmentDetailsProps } from './attachmentDetails.interface'

const AttachmentDetails: FC<AttachmentDetailsProps> = ({
  attachment,
  attachmentRequirements,
  handleAttachment,
  handleAttachmentImageDetailsView,
  storeAttachmentImageLocal,
  handleAttachmentImages,
  showList,
}) => {
  const [showSentImageView, toggleSentImageView] = useState<boolean>(false)
  const [gridDataSource, setGridDataSource] = useState<IAttachmentGridItem[]>(
    [],
  )
  const {
    claim,
    initialClaimState,
    isCreatedFromSentAttachment,
    allowsPayorRefGlobal,
    refImageTypes,
  } = useClaimDetailContext()
  const { detailsTab } = useClaimGridContext()
  const isSavedAttachment = useContext(SavedAttachmentContext)
  const isCreateNewAttachmentScreen =
    isCreatedFromSentAttachment && detailsTab === 'Attachments'
  const isSentAttachmentScreen =
    attachment.isSentAttachment && detailsTab === 'Attachments'
  const showNewRef =
    isCreateNewAttachmentScreen &&
    allowsPayorRefGlobal &&
    _.isEqual(initialClaimState, claim)
  const showOldRef = isSentAttachmentScreen && attachment.isNeaNumber
  const canShowCarrierRef = showNewRef || showOldRef

  let defaultOrientationType = ImageOrientationType.LeftIsRight
  if (localStorage.getItem('localStorageImageOrientation') === 'LEFT') {
    defaultOrientationType = ImageOrientationType.LeftIsLeft
  }

  useEffect(() => {
    generateGridDataSource(attachment?.attachmentImages)
  }, [])

  const handleUpdateAttachmentNarrative = (narrative: string) => {
    attachment.narrative = narrative
    handleAttachment(attachment)
  }

  const handleUpdateAttachmentPayorRef = (payorRefId: string) => {
    if (payorRefId && _.isEqual(initialClaimState, claim)) {
      attachment.payorRefId = payorRefId
      handleAttachment(attachment)
    }
  }

  const showAttachmentList = () => {
    showList()
    attachment = null
  }

  const updateAttachment = (newAttachment: IAttachment) => {
    handleAttachment(newAttachment)
    generateGridDataSource(newAttachment.attachmentImages)
  }

  const updateAttachmentImage = (attachmentImage: IAttachmentImage) => {
    storeAttachmentImageLocal(attachmentImage, false)
    generateGridDataSource(attachment.attachmentImages)
  }

  const updateAttachmentImages = (attachmentImages: IAttachmentImage[]) => {
    const images: IAttachmentImage[] = handleAttachmentImages(attachmentImages)
    generateGridDataSource(images)
  }

  const generateGridDataSource = (images: IAttachmentImage[]) => {
    if (images) {
      const dataSource: IAttachmentGridItem[] = []
      let rowId = 1

      images.forEach((image) => {
        if (!image.isDeleted) {
          const item: IAttachmentGridItem = {
            key: rowId,
            recordId: image.imageId.toString(),
            rowNumber: rowId,
            date: image.imageDateTaken,
            editable: true,
            imageTypeId: image.imageTypeId?.toString(),
            imageTypeName: image.imageTypeName,
            orientation: image.imageOrientationType,
            showOrientation: isAttachmentTypeXray(
              refImageTypes.current,
              image.imageTypeId?.toString(),
            ),
            attachmentImagePath: getUrl(image.imageData),
          }
          dataSource.push(item)
          rowId++
        }
      })
      setGridDataSource(dataSource)
    }
  }

  const handleImageGridEvent = (
    attachmentImage: IAttachmentGridItem,
    eventType: ImageGridEventType,
    updateData: any,
  ) => {
    const attachmentCopy = { ...attachment }
    const imageIndex = attachment.attachmentImages?.findIndex(
      (data) => data.imageId.toString() === attachmentImage.recordId,
    )

    switch (eventType) {
      case ImageGridEventType.OrientationUpdated:
        localStorage.setItem('localStorageImageOrientation', updateData)
        attachmentCopy.attachmentImages[imageIndex].imageOrientationType =
          updateData
        break
      case ImageGridEventType.ImageTypeUpdated:
        attachmentCopy.attachmentImages[imageIndex] =
          handleAttachmentTypeUpdate(
            attachmentCopy.attachmentImages[imageIndex],
            updateData,
          )
        break
      case ImageGridEventType.DateTakenUpdated:
        attachmentCopy.attachmentImages[imageIndex].imageDateTaken = updateData
        break
      case ImageGridEventType.EditClicked:
        handleAttachmentImageDetailsView(
          attachment.attachmentImages[imageIndex],
          true,
        )
        break
      case ImageGridEventType.DeleteClicked:
        attachmentCopy.attachmentImages[imageIndex].isDeleted = true
        break
      default:
        return
    }
    updateAttachment(attachmentCopy)
  }

  const handleAttachmentTypeUpdate = (
    attachmentImage: IAttachmentImage,
    option: optionType,
  ): IAttachmentImage => {
    attachmentImage.imageTypeId = option.imageTypeId
    attachmentImage.imageTypeName = option.imageTypeName

    if (
      option.isXray &&
      (!attachmentImage.imageOrientationType ||
        attachmentImage.imageOrientationType === ImageOrientationType.None)
    ) {
      attachmentImage.imageOrientationType = defaultOrientationType
    } else if (!option.isXray) {
      attachmentImage.imageOrientationType = ImageOrientationType.None
    }

    return attachmentImage
  }

  const getAttachmentInformation = (
    claim: any,
  ): AttachmentInformation<ImageType> => {
    const patientName: PatientName = patientNameParse(claim.PatientName)

    const attachmentInfo = {
      patientFirstName: patientName.firstName,
      patientLastName: patientName.lastName,
      patientDateOfBirth: claim.PatientBirthDate,
      dateOfServiceFrom: claim?.LineItems[0]?.SERVICE_DATE_String,
      dateOfServiceTo: claim?.LineItems[0]?.SERVICE_DATE_String,
      imageTypes: refImageTypes.current,
      handleImageType: handleImageType,
    }
    return attachmentInfo
  }

  return (
    <>
      <Row justify={canShowCarrierRef ? 'start' : 'center'}>
        {canShowCarrierRef && (
          <Col
            span={6}
            order={1}
            xl={{ push: 1, order: 3 }}
            className='attachment-carrier-ref-column'
          >
            <AttachementsCarrierReference
              isDisabled={attachment.isSentAttachment ? true : false}
              payorRefId={attachment?.payorRefId}
              handleUpdateAttachmentPayorRef={handleUpdateAttachmentPayorRef}
            />
          </Col>
        )}
        {!isSavedAttachment ? (
          <Col
            span={24}
            order={2}
            xl={{ span: canShowCarrierRef ? 24 : 20, order: 1 }}
            className='mb-100 mt-100'
          >
            <p className='vyne-lg-text'>
              <strong>Date of Service: </strong>
              {claim?.LineItems[0]?.SERVICE_DATE_String}
            </p>
          </Col>
        ) : (
          <></>
        )}
        <Col
          span={24}
          order={3}
          xl={{ span: canShowCarrierRef ? 16 : 20, order: 2 }}
        >
          <AttachmentRequirementGrid
            attachmentRequirements={attachmentRequirements}
          />
          <Row className='attachment-details-row'>
            <Col
              span={24}
              className='attachment-details-column'
            >
              {!isSavedAttachment ? (
                <>
                  <div className='attachment-image-grid mb-100'>
                    <Col span={12}>
                      <h3 className='mb-16-px'>Add Images</h3>
                    </Col>
                    <AttachmentImageCapture
                      attachmentInformation={getAttachmentInformation(claim)}
                      updateAttachmentImage={updateAttachmentImage}
                      updateAttachmentImages={updateAttachmentImages}
                      handleAttachmentImageAssembly={getAttachmentImageArray}
                    />
                    <AttachmentImageGrid
                      dataSource={gridDataSource}
                      onEvent={handleImageGridEvent}
                      editable={true}
                      imageOptions={imagesOptions}
                      otherOptions={otherOptions}
                    />
                  </div>
                </>
              ) : (
                <SentAttachmentImageGrid
                  toggleSentImageView={toggleSentImageView}
                  attachmentImages={attachment.attachmentImages}
                  showAttachmentList={showAttachmentList}
                  attachment={attachment}
                />
              )}
            </Col>
          </Row>
          <AttachmentNarrative
            narrative={attachment?.narrative}
            handleUpdateAttachmentNarrative={handleUpdateAttachmentNarrative}
          />
          {showSentImageView && (
            <AttachmentImageView
              dialogTitle={'View All Images'}
              attachmentImages={attachment.attachmentImages}
              toggleImageView={toggleSentImageView}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default AttachmentDetails
