import type { TimeRangePickerProps } from 'antd'

import dayjs from 'dayjs'

export interface DateRange {
  dates: [Date, Date]
  numberOfDays: number
}

export const themeConfig = {
  primaryColor: 'var(--primary-lime)',
  selectedCircleBg: 'var(--secondary-200)',
  hoverBg: 'var(--primary-100)',
  textColor: 'var(--foundation-white)',
}

export const rangeThemeConfig = {
  primaryColor: 'var(--primary-lime, #91c73d)',
  startCircleBg: 'var(--secondary-main, #1A4A93)',
  hoverBg: 'var(--primary-100, #E7F2D4)',
  textColor: 'var(--character-primary, #333)',
}

export const rangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Today',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    label: '7 Days',
    value: [dayjs().startOf('day').subtract(7, 'day'), dayjs().endOf('day')],
  },
  {
    label: '30 Days',
    value: [dayjs().startOf('day').subtract(30, 'day'), dayjs().endOf('day')],
  },
  {
    label: '60 Days',
    value: [dayjs().startOf('day').subtract(60, 'day'), dayjs().endOf('day')],
  },
  {
    label: '90 Days',
    value: [dayjs().startOf('day').subtract(90, 'day'), dayjs().endOf('day')],
  },
  {
    label: '3-6 mos.',
    value: [
      dayjs().startOf('day').subtract(180, 'day'),
      dayjs().endOf('day').subtract(90, 'day'),
    ],
  },
]

export const archiveRangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Last 6 Months',
    value: [dayjs().startOf('day').subtract(6, 'month'), dayjs().endOf('day')],
  },
  {
    label: 'Last Year',
    value: [dayjs().startOf('day').subtract(1, 'year'), dayjs().endOf('day')],
  },
  {
    label: 'Last 2 Years',
    value: [dayjs().startOf('day').subtract(2, 'year'), dayjs().endOf('day')],
  },
]

export const appointmentRangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Today',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    label: 'Tomorrow',
    value: [
      dayjs().startOf('day').add(1, 'day'),
      dayjs().endOf('day').add(1, 'day'),
    ],
  },
  {
    label: '3 Days',
    value: [dayjs().startOf('day'), dayjs().endOf('day').add(3, 'day')],
  },
  {
    label: '1 Week',
    value: [dayjs().startOf('day'), dayjs().endOf('day').add(7, 'day')],
  },
]

export const sentAttachmentRangePresets: TimeRangePickerProps['presets'] = [
  {
    label: 'Today',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    label: '7 Days',
    value: [dayjs().startOf('day').subtract(7, 'day'), dayjs().endOf('day')],
  },
  {
    label: '30 Days',
    value: [dayjs().startOf('day').subtract(30, 'day'), dayjs().endOf('day')],
  },
  {
    label: '30-60 Days',
    value: [
      dayjs().startOf('day').subtract(60, 'day'),
      dayjs().endOf('day').subtract(30, 'day'),
    ],
  },
  {
    label: '60-90 Days',
    value: [
      dayjs().startOf('day').subtract(90, 'day'),
      dayjs().endOf('day').subtract(60, 'day'),
    ],
  },
]
