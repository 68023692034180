import { observer, Show, useObservable } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { Tooltip } from 'antd'
import { FC } from 'react'

import { LogError } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'
import { UpdatePmsCustomerToken } from 'trellis:api/practice/practiceApi'
import GlobalState from 'trellis:state/globalState'

import styles from './_pmsCustomerToken.module.scss'
import {
  GenerateTokenButtonProps,
  PMSCustomerTokenState,
} from './PmsCustomerTokenTypes'

import CheckCircle from 'trellis:assets/check_circle.svg?react'
import CopyToken from 'trellis:assets/copy.svg?react'
import HideToken from 'trellis:assets/hide.svg?react'
import ShowToken from 'trellis:assets/show.svg?react'

type PMSCustomerTokenProps = {
  practiceInfo: PracticeInfo
}

const { info } = VyneModal

export const PMSCustomerToken: FC<PMSCustomerTokenProps> = observer(
  ({ practiceInfo }) => {
    const state$ = useObservable<PMSCustomerTokenState>({
      loading: false,
      showCopySuccess: false,
      tokenIsVisible: false,
    })

    const copyToken = (token: string) => {
      state$.showCopySuccess.set(true)
      navigator.clipboard.writeText(token)
      setTimeout(() => {
        state$.showCopySuccess.set(false)
      }, 1500)
    }

    const getToken = async () => {
      state$.loading.set(true)

      try {
        const response = await UpdatePmsCustomerToken()
        if (response.data) {
          GlobalState.PracticeInfo.pmsCustomerToken.set(
            response.data?.pmsCustomerToken,
          )
        }
      } catch (error) {
        LogError(error, 'Failed to generate new token.')
      } finally {
        state$.tokenIsVisible.set(true)
        state$.loading.set(false)
      }
    }

    const handleGenerateToken = async () => {
      if (practiceInfo?.pmsCustomerToken) showInfo()
      else await getToken()
    }

    const ModalContent = (
      <>
        <p className={styles['pms-token__modal-warning']}>
          Generating a new token will void your current token.
        </p>
        <p>Are you sure you want to generate a new token?</p>
      </>
    )

    const ContentCopied = (
      <div className={styles['pms-token__alert']}>
        <CheckCircle />
        <p>Content copied</p>
      </div>
    )

    const showInfo = () => {
      const modal = info({
        closable: true,
        content: ModalContent,
        footer: () => [
          <VyneButton
            dataTestId='cancel-generate-token-button'
            key='cancel'
            onClick={modal.destroy}
          >
            Cancel
          </VyneButton>,
          <GenerateTokenButton
            key='ok'
            hasToken={practiceInfo?.pmsCustomerToken ? true : false}
            handleClick={async () => {
              await getToken()
              modal.destroy()
            }}
            isLoading={state$.loading.get()}
          />,
        ],
        onClose: () => modal.destroy(),
        title: 'Generate New Token',
      })
    }

    return (
      <>
        <div className={styles['pms-token']}>
          <h3 className='mb-14-px'>PMS Customer Token</h3>
          <p className='vyne-lg-text mb-14-px'>
            Please provide this token to your participating Practice Management
            System vendor for claim upload to Vyne Trellis.
          </p>
          <Show
            if={practiceInfo?.pmsCustomerToken}
            else={() => (
              <GenerateTokenButton
                hasToken={practiceInfo?.pmsCustomerToken ? true : false}
                handleClick={handleGenerateToken}
                isLoading={state$.loading.get()}
              />
            )}
          >
            {() => (
              <div className={styles['pms-token__container']}>
                <div className='flex-row items-center justify-between gap-14-px mb-14-px'>
                  <p>
                    <b>Token:</b>
                    <span data-testid='token-text'>
                      {` ${
                        state$.tokenIsVisible.get()
                          ? practiceInfo?.pmsCustomerToken
                          : '***********************************'
                      }`}
                    </span>
                  </p>
                  <div className='flex-row items-center'>
                    <VyneButton
                      dataTestId='show-hide-button'
                      icon={
                        state$.tokenIsVisible.get() ? (
                          <HideToken />
                        ) : (
                          <ShowToken />
                        )
                      }
                      onClick={() =>
                        state$.tokenIsVisible.set(!state$.tokenIsVisible.peek())
                      }
                      type='text'
                    >
                      {state$.tokenIsVisible.get() ? 'Hide' : 'Show'}
                    </VyneButton>
                    <Tooltip
                      color='var(--white-000, #fff)'
                      showArrow={false}
                      trigger={'click'}
                      title={ContentCopied}
                      open={state$.showCopySuccess.get()}
                      overlayInnerStyle={{
                        border: '1px solid var(--color-primary)',
                        borderLeft: '5px solid var(--color-primary)',
                        borderRadius: '5px',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <VyneButton
                        dataTestId='copy-button'
                        icon={<CopyToken />}
                        onClick={() =>
                          copyToken(practiceInfo?.pmsCustomerToken)
                        }
                        type='text'
                      >
                        Copy
                      </VyneButton>
                    </Tooltip>
                  </div>
                </div>
                <GenerateTokenButton
                  hasToken={practiceInfo?.pmsCustomerToken ? true : false}
                  handleClick={handleGenerateToken}
                  isLoading={state$.loading.get()}
                />
              </div>
            )}
          </Show>
        </div>
      </>
    )
  },
)

const GenerateTokenButton: FC<GenerateTokenButtonProps> = ({
  hasToken,
  handleClick,
  isLoading,
}) => {
  return (
    <VyneButton
      dataTestId='generate-token-button'
      loading={isLoading}
      onClick={handleClick}
      type='primary'
    >
      {hasToken ? 'Generate New Token' : 'Generate Token'}
    </VyneButton>
  )
}
