import { VyneButton, VyneModal, VyneSwitch } from '@vynedental/design-system'
import { Form, Row } from 'antd'
import React, { useState } from 'react'

import { FormInput } from 'trellis:components/_siteWide/form/formInput'
import { validateRequiredFields } from 'trellis:components/_siteWide/form/util'
import GlobalState from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { showMessage } from 'trellis:utilities/general'

import { useClaimGridContext } from '../../context/claimGridContext'

export const InstallModal = () => {
  const { state, setState, newInstall, setNewInstall } = useClaimGridContext()
  const [install, setInstall] = useState({ ...state.Install, errors: [] })
  const [saving, setSaving] = useState(false)

  const [form] = Form.useForm()
  form.setFieldsValue({ ...install })

  const handleSaveInstall = () => {
    setSaving(true)
    validateForm(install)
    if (install.isValid) saveInstall()
  }

  const validateForm = (formData: any) => {
    formData.isValid = true
    formData.errors = []
    validateRequiredFields(formData, ['SerialId', 'Isolate'])

    if (formData.errors.length > 0) {
      formData.isValid = false
      setSaving(false)
      showMessage(
        'There was a problem saving your install settings. Please fix the errors and try again.',
      )
    }

    const copy = { ...state }
    copy.Install.Isolate = install.Isolate
    copy.Install.SerialNickname = install.SerialNickname

    setState(copy)
  }

  const saveInstall = async () => {
    const payload = {
      Isolate: install.Isolate,
      Serial_ID: install.SerialId,
      Serial_Nickname: install.SerialNickname,
      PC_Name: null, // Null for now since it is null anyway without the model reference
    }

    const { data } = await api.saveInstallSettings(payload.Serial_ID, payload)
    if (data.message) {
      showMessage(data.message)
    } else {
      const copy = { ...state }
      copy.Key = ++copy.Key
      copy.Install = install
      setState(copy)
      showMessage('Install settings have been saved.', 'success')
      setSaving(false)
      setNewInstall(false)
    }
  }

  const clearInstall = () => {
    GlobalState.SerialId.set(null)
    setNewInstall(false)
  }

  return (
    <VyneModal
      dataTestId='new-install-modal'
      title='New Install Detected'
      open={newInstall}
      closable={true}
      onCancel={clearInstall}
      footer={[
        <VyneButton
          dataTestId='save-install-settings-button'
          key='save-install-settings-button'
          loading={saving}
          onClick={handleSaveInstall}
          type='primary'
        >
          Save and Close
        </VyneButton>,
      ]}
    >
      <Form
        form={form}
        name='InstallSettings'
      >
        <p className='mb-100'>
          We noticed that you are accessing RPractice for the first time from
          this computer. Please name this computer for easier access.
        </p>
        <FormInput
          name='SerialNickname'
          label='Computer Name'
          element={install}
          setElement={setInstall}
          initialValue={install.SerialNickname}
          required
        />
      </Form>
      <Row>
        <p>Only show claims from this computer</p>
        <VyneSwitch
          dataTestId='install-isolate-switch'
          className='ml-100'
          onChange={() => setInstall({ ...install, Isolate: !install.Isolate })}
        />
      </Row>
    </VyneModal>
  )
}
