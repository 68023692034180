import { SelectOption } from 'utils'

export const TermsAndConditions = {
  billingPartOne: `By clicking "Submit" you agree to our online `,
  billingPartTwo: ` and authorize this card to be charged on a recurring basis for payments in accordance with your applicable subscription term and fees.`,
  billingTermsAlias: `Terms and Conditions`,
  logoutPath: `/Account/Logout`,
  redirectRootPath: `/`,
  termsPath: `terms-and-conditions`,
}

export const SSOPaymentOnFile = {
  logoutPath: `/Account/Logout`,
  redirectRootPath: `/`,
  submitPaymentPath: `payment-information`,
}

export const CreditCardTypes = {
  AMERICAN_EXPRESS: 'American Express',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
}

export const DetailFilterNames = {
  attachments: `Attachments`,
  claimReceipt: `ClaimReceipt`,
  era: `ERA`,
  eob: `EOB`,
  hasAttachment: `HasAttachment`,
  hasClaimReceipt: `HasClaimReceipt`,
  hasEra: `HasEra`,
  hasEob: `HasEob`,
}

interface FormSelectOption {
  value: string
  text: string
}

export const Genders: FormSelectOption[] = [
  { value: 'M', text: 'Male' },
  { value: 'F', text: 'Female' },
  { value: 'U', text: 'Unknown' },
]

export const Relationships: FormSelectOption[] = [
  { value: 'SELF', text: 'Self' },
  { value: 'SPOUSE', text: 'Spouse' },
  { value: 'CHILD', text: 'Dependent Child' },
  { value: 'OTHER', text: 'Other' },
]

export const CustomerTypes = {
  Standard: 1,
  PmgRegionMaster: 2,
  PmgMaster: 3,
}

export const PlacesOfTreatment: { text: string; value: string }[] = [
  {
    text: 'Office',
    value: '11',
  },
  {
    text: 'Home',
    value: '12',
  },
  {
    text: 'Inpatient Hospital',
    value: '21',
  },
  {
    text: 'Outpatient Hospital',
    value: '22',
  },
  {
    text: 'Skilled Nursing Facility',
    value: '31',
  },
  {
    text: 'Nursing Facility',
    value: '32',
  },
  {
    text: 'Other',
    value: '99', // TODO: this is just a placeholder, what should value be for Other?
  },
]

export const TaxonomyCodes: SelectOption[] = [
  {
    text: 'Dentist',
    value: '122300000X',
  },
  {
    text: 'General Practice',
    value: '1223G0001X',
  },
  {
    text: 'Dental Public Health',
    value: '1223D0001X',
  },
  {
    text: 'Endodontics',
    value: '1223E0200X',
  },
  {
    text: 'Orthodontics',
    value: '1223X0400X',
  },
  {
    text: 'Pediatric Dentistry',
    value: '1223P0221X',
  },
  {
    text: 'Periodontist',
    value: '1223P0300X',
  },
  {
    text: 'Prosthodontics',
    value: '1223P0700X',
  },
  {
    text: 'Oral & Maxillofacial Pathology',
    value: '1223P0106X',
  },
  {
    text: 'Oral & Maxillofacial Radiology',
    value: '1223D0008X',
  },
  {
    text: 'Oral & Maxillofacial Surgery',
    value: '1223S0112X',
  },
  {
    text: 'Hygienist',
    value: '124Q00000X',
  },
  {
    text: 'Denturist',
    value: '122400000X',
  },
]

interface IEligibilitySettingsConstants {
  MAX_LENGTH: number
  MIN_DAYS: number
  MAX_DAYS: number
  DEFAULT_DAYS: number
  DEFAULT_START_HOUR: number
  DEFAULT_END_HOUR: number
}

export const EligibilitySettingsConstants: IEligibilitySettingsConstants = {
  MAX_LENGTH: 275,
  MIN_DAYS: 1,
  MAX_DAYS: 21,
  DEFAULT_DAYS: 3,
  DEFAULT_START_HOUR: 9,
  DEFAULT_END_HOUR: 19,
}
