import { Col, Row } from 'antd'
import React from 'react'

import { CarrierControls } from '../tabs/patient/carrierControls'
import { CobControls } from '../tabs/patient/cobControls'
import { PatientControls } from '../tabs/patient/patientControls'
import { SubscriberControls } from '../tabs/patient/subscriberControls'

const PatientContent = () => {
  return (
    <Row
      className='row-to-column override-gutter-margin'
      gutter={24}
    >
      <Col
        span={12}
        className='page-section'
      >
        <h3 className='mb-16-px'>Patient</h3>
        <PatientControls />
      </Col>
      <Col
        span={12}
        className='page-section'
      >
        <h3 className='mb-16-px'>Subscriber</h3>
        <SubscriberControls />
      </Col>
      <Col
        span={12}
        className='page-section'
      >
        <h3 className='mb-16-px'>Carrier</h3>
        <CarrierControls displayAttachmentCarrier={false} />
      </Col>
      <Col
        span={12}
        className='page-section'
      >
        <h3 className='mb-16-px'>Additional Coverage</h3>
        <CobControls />
      </Col>
    </Row>
  )
}

export default PatientContent
