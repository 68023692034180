import { Dispatch, SetStateAction } from 'react'

import { validateBirthday, validateCarrier, validateNPI } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'

import {
  EligibilityPatient,
  EligibilityPatients,
  PatientEligibilityForm,
} from '../../../../../api/eligibility/eligibility-client'
import {
  GetPatient,
  VerifyPatient,
} from '../../../../../api/eligibility/eligibilityApi'
import { NotifyText } from '../../../../../constants/notifyText'
import { IAuthenticatedApiModel } from '../../../../../utilities/api'
import { showMessage } from '../../../../../utilities/general'
import { EligibilityResponseProps } from '../../../shared/EligibilityResponse'
import {
  getProviderName,
  transformPatient,
} from '../../../shared/form/utilities/eligibilityFormDataHandling'
import { FilteredOption } from '../../../shared/form/utilities/eligibilityFormTyping'
import {
  ParticipatingCarrier,
  ProviderDetails,
} from '../../../shared/utilities/eligibilityTyping'
import { getEligibilityResponse } from '../../../shared/utilities/getEligibilityResponse'

export interface EligibilityData {
  CarrierId?: string
  CarrierName?: string
  HistoryId?: number
  PatientBirthdate?: string
  PatientFirstName?: string
  PatientId?: string
  PatientLastName?: string
  PatientFormResponse?: string
  Status?: string
}

interface EligibilityPatientWrapper {
  Patient: EligibilityPatient
  FormResponse: string
}

const getPatient = async (
  patient: EligibilityPatient,
  patientId: string,
  practiceDetails: PracticeInfo,
  providers: ProviderDetails[],
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>,
  carrierList: ParticipatingCarrier[],
): Promise<EligibilityPatientWrapper> => {
  const result: EligibilityPatientWrapper = { Patient: {}, FormResponse: '' }

  try {
    const { data } = await GetPatient(patientId)

    const patientInfo = transformPatient(
      patient,
      data,
      carrierList,
      providers,
      practiceDetails,
    )
    setPatient({ ...patientInfo })

    result.FormResponse = data?.VerificationHistory[0]?.Response
    result.Patient = patientInfo
  } catch (e) {
    showMessage(NotifyText.getEligibilityPatientsError, 'error')
  }

  return result
}

export const handleEligibilityResponse = async <
  TableItem extends EligibilityData,
>(
  authentication: IAuthenticatedApiModel,
  formatPatientResponse: (response: string) => void,
  patient: EligibilityPatient,
  patientId: string,
  participatingCarriers: ParticipatingCarrier[],
  practiceDetails: PracticeInfo,
  providers: ProviderDetails[],
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>,
  setShowEligibilityResponseModal: Dispatch<SetStateAction<boolean>>,
  setShowingPatientResponseModal: Dispatch<SetStateAction<boolean>>,
  setVerificationResponse: Dispatch<SetStateAction<EligibilityResponseProps>>,
  tableItem: TableItem,
  setPatientEligibilityResponse: Dispatch<
    SetStateAction<PatientEligibilityForm>
  >,
): Promise<boolean> => {
  let wasSuccessful: boolean = false

  const pat = await getPatient(
    patient,
    patientId,
    practiceDetails,
    providers,
    setPatient,
    participatingCarriers,
  )

  if (tableItem.PatientFormResponse || tableItem.Status == 'Patient Response') {
    const form =
      tableItem?.PatientFormResponse && tableItem.PatientFormResponse !== ''
        ? tableItem.PatientFormResponse
        : pat.FormResponse
    formatPatientResponse(JSON.parse(form))
    setShowingPatientResponseModal(true)
  } else {
    await getEligibilityResponse<TableItem>(authentication, tableItem)
      .then((data) => {
        if (data.patientResponse)
          setPatientEligibilityResponse(data.patientResponse)
        setVerificationResponse(data)
        setShowEligibilityResponseModal(true)
        wasSuccessful = true
      })
      .catch((error) => {
        showMessage(error.message, 'error')
      })
  }

  return wasSuccessful
}

export const handleSendUpdateForm = async (
  authentication: IAuthenticatedApiModel,
  patient: EligibilityPatient,
  patientId: string,
  participatingCarriers: ParticipatingCarrier[],
  practiceDetails: PracticeInfo,
  providers: ProviderDetails[],
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>,
  setShowPatientUpdateFormModal: Dispatch<SetStateAction<boolean>>,
): Promise<boolean> => {
  let wasSuccessful: boolean = false

  const patientInfo = await getPatient(
    patient,
    patientId,
    practiceDetails,
    providers,
    setPatient,
    participatingCarriers,
  )
  if (patientInfo) {
    setShowPatientUpdateFormModal(true)
    wasSuccessful = true
  }

  return wasSuccessful
}

const getPatientValidation = async (
  isOpera: boolean,
  participatingCarriers: ParticipatingCarrier[],
  patientInfo: EligibilityPatient,
): Promise<string> => {
  const patientCopy = {
    ...patientInfo,
    ProviderName: getProviderName(patientInfo),
  }
  let message: string = ''

  const carrierOptions: FilteredOption[] = participatingCarriers.map(
    (carrier: ParticipatingCarrier, index: number) => ({
      key: index,
      value: carrier.CarrierName,
    }),
  )

  const requiredErrors: string[] = []

  if (!patientCopy.PatientFirstName)
    requiredErrors.push(
      'Patient First Name is required. Please fix the error and try again.',
    )
  if (!patientCopy.PatientLastName)
    requiredErrors.push(
      'Patient Last Name is required. Please fix the error and try again.',
    )
  if (!patientCopy.PatientBirthdate)
    requiredErrors.push(
      'Patient Birthdate is required. Please fix the error and try again.',
    )
  if (!patientCopy.PatientGender)
    requiredErrors.push(
      'Patient Gender is required. Please fix the error and try again.',
    )
  if (!patientCopy.SubscriberFirstName)
    requiredErrors.push(
      'Subscriber First Name is required. Please fix the error and try again.',
    )
  if (!patientCopy.SubscriberLastName)
    requiredErrors.push(
      'Subscriber Last Name is required. Please fix the error and try again.',
    )
  if (!patientCopy.SubscriberBirthdate)
    requiredErrors.push(
      'Subscriber Birthdate is required. Please fix the error and try again.',
    )
  if (!patientCopy.SubscriberGender)
    requiredErrors.push(
      'Subscriber Gender is required. Please fix the error and try again.',
    )
  if (!isOpera && !patientCopy.CarrierName)
    requiredErrors.push(
      'Patient Carrier Name is required. Please fix the error and try again.',
    )
  if (!patientCopy.SubscriberId)
    requiredErrors.push(
      'Subscriber ID is required. Please fix the error and try again.',
    )
  if (isOpera && !patientCopy.ProviderName)
    requiredErrors.push(
      'Provider Name is required. Please fix the error and try again.',
    )
  if (!isOpera && !patientCopy.ProviderLastName)
    requiredErrors.push(
      'Patient name is required. Please fix the error and try again.',
    )
  if (!isOpera && !patientCopy.IndividualNpi)
    requiredErrors.push(
      'Patient name is required. Please fix the error and try again.',
    )

  if (requiredErrors.length > 1)
    message =
      'Missing required information. Please fix the errors in Patient Info and try again.'
  else message = requiredErrors[0]

  if (message) return message

  const validationErrors: string[] = []

  if (patientCopy.PatientBirthdate)
    await validateBirthday(patientCopy.PatientBirthdate).catch((error) =>
      validationErrors.push(error),
    )
  if (patientCopy.SubscriberBirthdate)
    await validateBirthday(patientCopy.SubscriberBirthdate).catch((error) =>
      validationErrors.push(error),
    )
  if (!isOpera && patientCopy.CarrierName)
    await validateCarrier(patientCopy.CarrierName, carrierOptions).catch(
      (error) => validationErrors.push(error),
    )
  if (!isOpera && patientCopy.IndividualNpi)
    await validateNPI(patientCopy.IndividualNpi).catch((error) =>
      validationErrors.push(error),
    )

  if (validationErrors.length > 1)
    message =
      'Invalid information. Please fix the errors in Patient Info and try again.'
  else message = validationErrors[0]

  if (message) return message
  else return null
}

export const handleVerify = async (
  authentication: IAuthenticatedApiModel,
  isOpera: boolean,
  patient: EligibilityPatient,
  patientId: string,
  participatingCarriers: ParticipatingCarrier[],
  practiceDetails: PracticeInfo,
  providers: ProviderDetails[],
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>,
  setRequiresTableRefresh: Dispatch<SetStateAction<boolean>>,
  setShowEligibilityResponseModal: Dispatch<SetStateAction<boolean>>,
  setVerificationResponse: Dispatch<SetStateAction<EligibilityResponseProps>>,
): Promise<boolean> => {
  let wasSuccessful: boolean = false

  const { Patient } = await getPatient(
    patient,
    patientId,
    practiceDetails,
    providers,
    setPatient,
    participatingCarriers,
  )
  let validationError: string = ''

  if (Patient)
    validationError = await getPatientValidation(
      isOpera,
      participatingCarriers,
      Patient,
    )
  if (!validationError) {
    //Validate carrierID
    if (!Patient.CarrierId || Patient.CarrierId === '0') {
      const carrierFound = participatingCarriers.filter(
        (carrier: ParticipatingCarrier) =>
          carrier.CarrierName?.toLowerCase() ==
          Patient.CarrierName?.toLowerCase(),
      )
      Patient.CarrierId = carrierFound[0]?.CarrierId
    }

    await VerifyPatient(patientId, Patient).then(
      async ({ data: verifyResponse }) => {
        await getEligibilityResponse<EligibilityPatients>(
          authentication,
          { ...Patient, Status: verifyResponse.Status },
          verifyResponse.HtmlResult,
          verifyResponse.EligibilityId,
        )
          .then((response) => {
            setRequiresTableRefresh(true)
            setVerificationResponse(response)
            setShowEligibilityResponseModal(true)
            wasSuccessful = true
          })
          .catch((error) => showMessage(error.message, 'error'))
      },
    )
  } else showMessage(validationError, 'error')

  return wasSuccessful
}
