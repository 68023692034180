import { VyneButton } from '@vynedental/design-system'
import { FC, ReactNode } from 'react'

import vyneDentalLogo from 'trellis:assets/vyne-dental-logo.png'
import webexIcon from 'trellis:assets/webex.png'

const Footer: FC = (): ReactNode => {
  return (
    <footer className='pre-auth-footer'>
      <img
        className='pre-auth-footer__logo'
        src={vyneDentalLogo}
      />
      <p>
        <span>&copy;</span>
        <span>{new Date().getFullYear()} </span>
        <span>Napa EA/MEDX, LLC. All rights reserved.</span>
      </p>
      <p>
        Having trouble? Contact support at
        <VyneButton
          className='ml-4-px'
          dataTestId='footer-support-link'
          href='tel:(866)712-9584'
          type='link'
        >
          (866) 712-9584
        </VyneButton>
      </p>
      <p>
        <VyneButton
          dataTestId='footer-privacy-link'
          href='https://vynedental.com/privacy/'
          rel='noreferrer'
          target='_blank'
          type='link'
        >
          Privacy
        </VyneButton>
        <span className='mr-4-px ml-4-px'>|</span>
        <VyneButton
          dataTestId='footer-terms-link'
          href='https://vynedental.com/terms/'
          rel='noreferrer'
          target='_blank'
          type='link'
        >
          Terms
        </VyneButton>
      </p>
      <VyneButton
        dataTestId='footer-remote-support-link'
        href='https://vynedental.screenconnect.com/'
        id='webex-root'
        rel='noreferrer'
        target='_blank'
        type='link'
      >
        <img
          className='webex-icon'
          src={webexIcon}
        />
        Remote Support
      </VyneButton>
    </footer>
  )
}

export default Footer
