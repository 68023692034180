import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import { AccountApiFactory, Configuration } from './account-client'

const apiEndpoint: string =
  trellisConfiguration.trellis_apiUrl + 'services/account'

export const GetUser = async (userId: number) => {
  return AccountApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).accountGetUser(
    userId,
    GlobalState?.Auth?.AuthToken?.peek(),
    GlobalState?.Auth?.RegistrationToken?.peek(),
    0,
  )
}
