import { Spin } from 'antd'
import { FC } from 'react'

type LoadingProps = {
  size?: 'large' | 'small' | 'default'
}

export const Loading: FC<LoadingProps> = ({ size = 'large' }) => (
  <div className='loading-container'>
    <Spin
      className='loading-spin'
      size={size}
    />
  </div>
)
