import { CloseCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { FC } from 'react'

import './SubscriptionAlert.scss'

interface SubscriptionAlertProps {
  hasBillingAndSubscription?: boolean
  endDate?: string
}

export function formatSubscriptionEndDate(dateString: string): string {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })
}

export const SubscriptionAlert: FC<SubscriptionAlertProps> = ({
  hasBillingAndSubscription,
  endDate,
}) => {
  if (!hasBillingAndSubscription || !endDate) return <></>

  const formattedDate = formatSubscriptionEndDate(endDate)

  if (!formattedDate) return <></>

  return (
    <Alert
      message={
        <span className='subscription-banner__message'>
          <span data-testid='subscription-banner-message'>
            <b>Subscription Ending. </b>
            You will retain access to your subscription benefits until
            <b> {formattedDate}</b>. After this date, your account will no
            longer be accessible.
          </span>
        </span>
      }
      type='error'
      banner
      className='subscription-banner'
      icon={<CloseCircleOutlined />}
    />
  )
}

export default SubscriptionAlert
