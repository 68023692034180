import { FormInstance } from 'antd'

import { FormError } from 'utils'

import { PracticeInfo } from 'trellis:api/practice/practice-client'
import GlobalState from 'trellis:state/globalState'

import {
  EligibilityPatient,
  GetPatientEligibilityInfoResponse,
} from '../../../../../api/eligibility/eligibility-client/api'
import { CarrierHelpers } from '../../../../../utilities/carrierHelpers'
import { extractTypeProperties } from '../../../../../utilities/general'
import {
  ParticipatingCarrier,
  ProviderDetails,
} from '../../utilities/eligibilityTyping'

export const emptyPatient: EligibilityPatient = {
  PatientId: '',
  CustomerId: GlobalState.UserInfo.globalCustomerID.get(),
  PatientFirstName: '',
  PatientMiddleName: '',
  PatientLastName: '',
  PatientSuffix: '',
  PatientBirthdate: '',
  PatientGender: '',
  PatientIsSub: false,
  SubscriberId: '',
  SubscriberFirstName: '',
  SubscriberMiddleName: '',
  SubscriberLastName: '',
  SubscriberSuffix: '',
  SubscriberBirthdate: '',
  SubscriberGender: '',
  ProviderLastName: '',
  ProviderFirstName: '',
  IndividualNpi: '',
  CarrierName: '',
  GroupNumber: '',
  CarrierId: '',
  SyncId: '',
  TaxonomyCode: '',
}

export const transformPatient = (
  patient: EligibilityPatient,
  patientInfo: GetPatientEligibilityInfoResponse,
  carrierList: ParticipatingCarrier[],
  providers?: ProviderDetails[],
  practiceDetails?: PracticeInfo,
): EligibilityPatient => {
  const patientCopy = extractTypeProperties<
    EligibilityPatient,
    GetPatientEligibilityInfoResponse
  >({ ...patient }, { ...patientInfo })

  if (!patientCopy.IndividualNpi && practiceDetails?.providerNPI) {
    patientCopy.IndividualNpi = practiceDetails.providerNPI

    if (practiceDetails.providerFirstName)
      patientCopy.ProviderFirstName = practiceDetails.providerFirstName
    if (practiceDetails.providerLastName)
      patientCopy.ProviderLastName = practiceDetails.providerLastName
  } else if (patientCopy.IndividualNpi) {
    const value = getProviderName(patientCopy)

    if (value.includes(',')) {
      const values = value.split(',')
      const match = providers.find(
        (provider: ProviderDetails) =>
          provider.ProviderLastName?.toLowerCase()?.trim() ===
            values[0]?.toLowerCase()?.trim() &&
          provider.ProviderFirstName?.toLowerCase()?.trim() ===
            values[1]?.toLowerCase()?.trim() &&
          provider.ProviderNPI === patientCopy?.IndividualNpi,
      )

      if (match) {
        patientCopy.ProviderFirstName = match.ProviderFirstName
        patientCopy.ProviderLastName = match.ProviderLastName
        patientCopy.IndividualNpi = match.ProviderNPI
      }
    } else {
      // If the user has only first or last name
      const match = providers.find(
        (provider: ProviderDetails) =>
          (provider.ProviderLastName?.toLowerCase()?.trim() ===
            value?.toLowerCase()?.trim() ||
            provider.ProviderFirstName?.toLowerCase()?.trim() ===
              value?.toLowerCase()?.trim()) &&
          provider.ProviderNPI === patientCopy?.IndividualNpi,
      )

      if (match) {
        patientCopy.ProviderFirstName = match?.ProviderFirstName
        patientCopy.ProviderLastName = match?.ProviderLastName
        patientCopy.IndividualNpi = match.ProviderNPI
      }
    }
  }

  const patientCarrierMatch = CarrierHelpers.getEligibilityCarrierById(
    carrierList,
    patientCopy.CarrierId,
  )
  if (patientCarrierMatch) {
    patientCopy.CarrierName = patientCarrierMatch.CarrierName
  }

  if (!patientCopy.TaxonomyCode)
    patientCopy.TaxonomyCode = practiceDetails?.taxonomyCode

  return patientCopy
}

export const toggleSubscriber = (
  patient: EligibilityPatient,
  setPatient: (value: EligibilityPatient) => void,
  patientEligibilityForm: FormInstance,
  formErrors?: FormError[],
  setFormErrors?: (value: FormError[]) => void,
) => {
  const patientCopy = { ...patient }
  patientCopy.PatientIsSub = !patientCopy.PatientIsSub

  patientEligibilityForm.setFieldValue('PatientIsSub', patientCopy.PatientIsSub)

  patientEligibilityForm
    .validateFields([
      'PatientBirthdate',
      'PatientFirstName',
      'PatientGender',
      'PatientLastName',
      'SubscriberBirthdate',
      'SubscriberFirstName',
      'SubscriberGender',
      'SubscriberLastName',
    ])
    .then((values) => {
      if (formErrors) {
        const newArr = formErrors.filter(
          (error: FormError) =>
            !Object.keys(values).includes(error.name[0] as string),
        )
        setFormErrors(newArr)
      }
    })
    .catch(({ values, errorFields }) => {
      if (formErrors) {
        let formErrorsCopy = JSON.parse(JSON.stringify(formErrors))
        const formErrorsFieldNames = formErrorsCopy.map(
          (error: FormError) => error.name[0],
        )
        const errorFieldsNames = errorFields.map(
          (error: FormError) => error.name[0],
        )

        errorFields.forEach(
          (error: FormError) =>
            !formErrorsFieldNames.includes(error.name[0]) &&
            formErrorsCopy.push(error),
        )
        formErrorsCopy = formErrorsCopy.filter(
          (error: FormError) =>
            !Object.keys(values).includes(error.name[0] as string) ||
            errorFieldsNames.includes(error.name[0]),
        )

        setFormErrors(formErrorsCopy)
      }
    })

  setPatient({ ...patientCopy })
}

export const getProviderName = (patient: EligibilityPatient) => {
  let providerName: string = ''

  if (patient.ProviderLastName && patient.ProviderFirstName)
    providerName = `${patient.ProviderLastName}, ${patient.ProviderFirstName}`
  else if (patient.ProviderLastName) providerName = patient.ProviderLastName
  else if (patient.ProviderFirstName) providerName = patient.ProviderFirstName

  return providerName
}
