import { CameraOutlined } from '@ant-design/icons'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import React, { FC, useEffect, useRef, useState } from 'react'

import {
  ScreenCaptureGetImage,
  ScreenCaptureStoragePath,
} from '../../../../api/attachment/attachmentApi'
import { ErrorPNotify } from '../../../../utilities/general'
import { PNotifyText, ScreenCaptureInstallUrl } from '../../constants'

import '../../../Attachments/AttachmentView/ScreenCapture.scss'
import '../../attachment.scss'

import { IImageCaptureClaimAttachment } from '../../../attachmentsShared/shared/shared.interface'
import { ImageCaptureMethod } from '../../attachment.interface'

const ScreenCapture: FC<IImageCaptureClaimAttachment> = ({
  updateAttachmentImage,
}) => {
  const [visibleUseScreenCaptureDialog, setVisibleUseScreenCaptureDialog] =
    useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const isPolling = useRef<boolean>(false)
  const isMounted = useRef<boolean>(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
      isPolling.current = false
    }
  }, [])

  const getStoragePath = async () => {
    if (isSending) return
    setIsSending(true)

    await ScreenCaptureStoragePath()
      .then((response) => {
        const storagePathResponse = response.data
        isPolling.current = true
        startFilePolling(
          storagePathResponse.imageName,
          new Date(storagePathResponse.expirationTime),
        )
        window.location.href = storagePathResponse.storagePath
      })
      .catch((error) => {
        ErrorPNotify(PNotifyText.screenCaptureError)
      })
    if (isMounted.current) {
      setIsSending(false)
    }
  }

  const getImage = async (fileName: string) => {
    await ScreenCaptureGetImage(fileName)
      .then((response) => {
        const byteString = atob(response.data)
        let n = byteString.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = byteString.charCodeAt(n)
        }
        isPolling.current = false
        setVisibleUseScreenCaptureDialog(false)

        updateAttachmentImage(
          {
            isNewImage: true,
            imageData: new File([u8arr], fileName + '.jpg', {
              type: 'image/jpeg',
            }),
            imageDateTaken: new Date(),
            imageCaptureMethod: ImageCaptureMethod.ScreenCapture,
          },
          false,
        )
      })
      .catch((error) => {})
  }

  // the user must close the overlay to stop screen capture polling
  // we dont have a way to know if the screen capture app is running
  const startFilePolling = (fileName: string, expireTime: Date) => {
    const currentTime = new Date().getTime(),
      expiredTime = expireTime.getTime(),
      defaultExpireTime = 270000, //set to four and a half minutes
      timeToExpire =
        expiredTime - currentTime > 0
          ? expiredTime - currentTime
          : defaultExpireTime

    var pollingIntervalId = setInterval(() => {
      if (!isPolling.current) {
        clearInterval(pollingIntervalId)
      }
      getImage(fileName)
    }, 1000)
    setTimeout(clearInterval, timeToExpire, pollingIntervalId)
  }

  return (
    <>
      <VyneButton
        dataTestId='screen-capture-button'
        icon={<CameraOutlined />}
        onClick={() => {
          setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
          getStoragePath()
        }}
        type='primary'
      >
        Screen Capture
      </VyneButton>

      <VyneModal
        afterClose={() => {
          isPolling.current = false
        }}
        dataTestId='claim-attachments-screen-capture-modal'
        destroyOnClose={true}
        footer={null}
        onCancel={() =>
          setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
        }
        open={visibleUseScreenCaptureDialog}
        title={<h4>Screen Capture</h4>}
      >
        <h5 className='vyne-text-center'>
          If the capture tool doesn't open,
          <br />
          click&nbsp;
          <a
            onClick={() => {
              isPolling.current = false
              setVisibleUseScreenCaptureDialog(!visibleUseScreenCaptureDialog)
            }}
            href={ScreenCaptureInstallUrl}
          >
            here
          </a>
          &nbsp;to install.
        </h5>
        <div className='sa-screen-capture-modal__acquisition-note'>
          *Keep this window open when using the capture tool.
        </div>
      </VyneModal>
    </>
  )
}

export default ScreenCapture
