import { GetUser } from 'trellis:api/account/accountApi'
import GlobalState from 'trellis:state/globalState'

import { getPathWithQueryString } from './apiHelpers'

export const getEulaReturnPath = (
  eulaPath: string,
  acceptTermsUrl: string,
  rejectTermsUrl: string,
): string => {
  const returnPathQueryData = {
    acceptTermsUrl: acceptTermsUrl,
    rejectTermsUrl: rejectTermsUrl,
  }

  const returnPath = getPathWithQueryString(eulaPath, returnPathQueryData)
  if (!returnPath) {
    throw new Error(
      `Failed to build return path for path: ${eulaPath} acceptTermsUrl: ${acceptTermsUrl} rejectTermsUrl: ${rejectTermsUrl}`,
    )
  }

  return getPathWithQueryString(eulaPath, returnPathQueryData)
}

export const confirmEulaAndReloadUser = async (
  userId: number,
): Promise<boolean> => {
  const userInfo = await GetUser(userId)
  if (userInfo.data.eulaAccepted) {
    GlobalState.UserInfo.set(userInfo.data)
    return true
  }
  return false
}
