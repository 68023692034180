import { useQuery } from '@tanstack/react-query'
import { GetClaimStatusCodes } from 'trellis:api/claim/claimApi'
import { ClaimStatusCodes } from 'trellis:api/claim/claim-client'
import { LDFlags$ } from 'trellis:state/globalState'
import { LogError } from 'utils'

export const useGetClaimStatusCodes = () => {
  const flags = LDFlags$.get();

  return useQuery<ClaimStatusCodes[] | null>({
    queryKey: ['claimStatusCodes'],
    queryFn: async () => {
      try {
        const statusCodesResponse = await GetClaimStatusCodes()
        return statusCodesResponse?.data || null
      } catch (err) {
        LogError(err, 'Failed to get claim validation codes')
        return null
      }
    },
    enabled: !!flags.claimValidation,
    staleTime: Infinity, // Data will never become stale, only refetch on page refresh
    gcTime: Infinity, // Cache will never expire
  })
}
