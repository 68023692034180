import { InfoCircleFilled } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { message, Select } from 'antd'
import { FC, useEffect } from 'react'

import { ImageSystem } from 'trellis:api/pearl/pearl-provision-client'
import { GetAvailableImagingSystems } from 'trellis:api/pearl/pearlProvisionApiService'

import styles from '../../downloads.module.scss'

interface ImageSystemSelectionModalProps {
  isOpen: Observable<boolean>
  onClose?: () => void
  onSelectImageSystem: (value: string) => void
  onContinue?: () => void
}

interface SelectOption {
  value: string
  label: string
}

export const ImageSystemSelectionModal: FC<ImageSystemSelectionModalProps> =
  observer(({ isOpen, onClose, onSelectImageSystem, onContinue }) => {
    const selectedSystem = useObservable<string | undefined>(undefined)
    const imagingSystems = useObservable<SelectOption[]>([])
    const isLoadingSystems = useObservable(false)

    useEffect(() => {
      selectedSystem.set(undefined)

      const fetchImagingSystems = async () => {
        if (!isOpen.get()) {
          return
        }
        isLoadingSystems.set(true)

        try {
          const systems = await GetAvailableImagingSystems()

          const selectOptions = systems.map((system: ImageSystem) => ({
            value: system.imageSystemIdentifier || '',
            label: system.imageSystemName || '',
          }))

          imagingSystems.set(selectOptions)
        } catch (error) {
          await message.error('Failed to load imaging systems')
        } finally {
          isLoadingSystems.set(false)
        }
      }

      void fetchImagingSystems()
    }, [isOpen.get()])

    const handleContinue = async () => {
      const selectedSystemId = selectedSystem.get()
      if (!selectedSystemId) {
        onClose?.()
        return
      }

      onSelectImageSystem(selectedSystemId)
      onContinue?.()
    }

    const handleClose = () => {
      selectedSystem.set(undefined)
      onClose?.()
    }

    return (
      <>
        <VyneModal
          dataTestId='image-system-selection-modal'
          title='Download Image Sync'
          open={isOpen.get()}
          onCancel={handleClose}
          footer={[
            <VyneButton
              dataTestId='cancel-image-sync-download-button'
              key='cancel'
              onClick={handleClose}
            >
              Cancel
            </VyneButton>,
            <VyneButton
              dataTestId='continue-download-image-sync-button'
              disabled={!selectedSystem.get() || isLoadingSystems.get()}
              key='continue'
              loading={isLoadingSystems.get()}
              onClick={() => void handleContinue()}
              type='primary'
            >
              Continue
            </VyneButton>,
          ]}
        >
          <div className='flex-column gap-100'>
            <div>
              <label>Select your current Imaging System:</label>
              <Select
                placeholder='Select'
                style={{ width: '100%', marginTop: '8px' }}
                onChange={(value: string) => selectedSystem.set(value)}
                value={selectedSystem.get()}
                loading={isLoadingSystems.get()}
                options={imagingSystems.get()}
              />
            </div>

            <div className={styles['download-item__note-container']}>
              <InfoCircleFilled
                className={styles['download-item__info-icon']}
                style={{ color: 'var(--primary-lime, #91c73d)' }}
              />
              <p>
                <strong>Note:</strong> Image Sync requires a compatible imaging
                system. Please refer to the list to view supported systems.
              </p>
            </div>
          </div>
        </VyneModal>
      </>
    )
  })
