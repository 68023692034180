import { observer } from '@legendapp/state/react'
import { Col, Row } from 'antd'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { FormAutoComplete, FormInput } from 'ui'
import { TypedSelectOption, validateCarrier } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { EligibilityPatient } from '../../../../../api/eligibility/eligibility-client'
import { useEligibilityContext } from '../../context/EligibilityContext'
import { ParticipatingCarrier } from '../../utilities/eligibilityTyping'

interface InsuranceControlsProps {
  isModal?: boolean
  patient: EligibilityPatient
  setPatient: Dispatch<SetStateAction<EligibilityPatient>>
}

const InsuranceControls: FC<InsuranceControlsProps> = observer(
  ({ isModal = false, patient, setPatient }) => {
    const flags = LDFlags$.get()
    const { participatingCarriers } = useEligibilityContext()

    const options: TypedSelectOption<ParticipatingCarrier>[] =
      participatingCarriers.map(
        (carrier: ParticipatingCarrier, index: number) => ({
          key: index,
          value: carrier.CarrierName,
          text: carrier.CarrierName,
          valueObj: carrier,
        }),
      )
    const [filteredOptions, setFilteredOptions] =
      useState<TypedSelectOption<ParticipatingCarrier>[]>(options)

    let disabled = false
    if (!isModal) disabled = true

    const updateCarrier = (
      value: string,
      selectedOptions?:
        | TypedSelectOption<ParticipatingCarrier>
        | TypedSelectOption<ParticipatingCarrier>[],
    ) => {
      if (!value) return
      const selectedCarrier: ParticipatingCarrier = (
        selectedOptions as TypedSelectOption<ParticipatingCarrier>
      )?.valueObj

      if (selectedCarrier) {
        const patientCopy = { ...patient }
        patientCopy.CarrierName = selectedCarrier.CarrierName
        patientCopy.CarrierId = selectedCarrier.CarrierId
        setPatient({ ...patientCopy })
      } else {
        //no match, don't log as it'll be hit for every letter they type
      }
    }

    const handleOnSearch = (value: string) => {
      if (value) {
        value = value?.toLowerCase()
        const filteredOptions: TypedSelectOption<ParticipatingCarrier>[] =
          options.filter((option: TypedSelectOption<ParticipatingCarrier>) =>
            option.valueObj.CarrierName?.toLowerCase().includes(value),
          )
        setFilteredOptions(filteredOptions)
      } else setFilteredOptions(options)
    }

    let carrierControl
    if (
      (flags.eligibilityUsevynedentalsync ||
        flags.operaVynesyncAutomatedeligibility) &&
      patient.PatientId !== '0' &&
      !isModal
    ) {
      carrierControl = (
        <FormInput
          disabled={disabled}
          label='Carrier'
          name='CarrierName'
        />
      )
    } else {
      carrierControl = (
        <FormAutoComplete
          customHandler={updateCarrier}
          label='Carrier'
          name='CarrierName'
          onSearchHandler={handleOnSearch}
          options={filteredOptions}
          required
          validator={validateCarrier}
        />
      )
    }

    return (
      <Col span={24}>
        <Row>
          <Col span={24}>{carrierControl}</Col>
        </Row>
        <Row gutter={8}>
          <Col
            span={12}
            className='gutter-row'
          >
            <FormInput
              label='Group Number'
              name='GroupNumber'
            />
          </Col>
          <Col
            span={12}
            className='pl-150'
          >
            <FormInput
              label='Subscriber ID'
              name='SubscriberId'
              required
            />
          </Col>
        </Row>
      </Col>
    )
  },
)

export default InsuranceControls
