type EventData = Record<string, string | boolean>

// Can add to these as needed for other events
type EventName = 'CarrierResponseViewed'
type EventCategory = 'Eligibility'

declare const pendo

export const usePendoTrackEvent = (
  eventName: EventName,
  category: EventCategory,
  data: EventData = {},
) => {
  if (pendo) {
    const eventData = {
      category,
      ...data,
    }

    pendo.track(eventName, eventData)
  } else {
    console.warn('Pendo is not initialized. Event not sent.')
  }
}
