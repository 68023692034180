import { Col, Row } from 'antd'
import { FC } from 'react'

import HtmlChunk from '../htmlChunk'

type ClaimReceiptContentProps = { data: string }

const ClaimReceiptContent: FC<ClaimReceiptContentProps> = ({ data }) => (
  <Row justify='center'>
    <Col
      span={16}
      className='form-detail-section'
    >
      <div className='page-section'>
        <HtmlChunk data={data} />
      </div>
    </Col>
  </Row>
)

export default ClaimReceiptContent
