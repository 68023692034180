import {
  Divider,
  Dropdown,
  Input,
  InputNumber,
  InputRef,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Spin,
} from 'antd'
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import './EligibilitySettingsModal.scss'

import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import {
  VyneButton,
  VyneModal,
  VyneRadio,
  VyneSwitch,
} from '@vynedental/design-system'

import { LogError } from 'utils'

import GlobalState from 'trellis:state/globalState'

import {
  GetSettings,
  SaveSettings,
} from '../../../../api/eligibility/eligibilityApi'
import errorOutline from '../../../../assets/error_outline.png'
import { EligibilitySettingsConstants } from '../../../../constants/general'
import { showMessage } from '../../../../utilities/general'

const { Group } = Radio
const { TextArea } = Input
const {
  MAX_LENGTH,
  MIN_DAYS,
  MAX_DAYS,
  DEFAULT_DAYS,
  DEFAULT_START_HOUR,
  DEFAULT_END_HOUR,
} = EligibilitySettingsConstants

interface EligibilitySettingsModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

type DeliveryMethod = 'TEXT_AND_EMAIL' | 'TEXT' | 'EMAIL'

type EligibilitySettingsFormProps = {
  automationTermDays: number
  sendUpdateForm: boolean
  startHour: number
  endHour: number
  customMessageText: string
  messageType: 'CUSTOM' | 'DEFAULT'
  deliveryMethod: DeliveryMethod
  sendReminder: boolean
  reminderTermHours: number
}

const initialEligibilitySettings: EligibilitySettingsFormProps = {
  automationTermDays: DEFAULT_DAYS,
  sendUpdateForm: false,
  startHour: DEFAULT_START_HOUR,
  endHour: DEFAULT_END_HOUR,
  customMessageText: '',
  messageType: 'DEFAULT',
  deliveryMethod: null,
  sendReminder: false,
  reminderTermHours: 0,
}

const EligibilitySettingsModal: FC<EligibilitySettingsModalProps> = observer(
  ({ isOpen, setIsOpen }): ReactElement => {
    const [saving, isSaving] = useState<boolean>(false)
    const [loading, setIsLoading] = useState<boolean>(false)
    const [settings, setSettings] = useState<EligibilitySettingsFormProps>(
      initialEligibilitySettings,
    )
    const [startHourLabel, setStartHourLabel] = useState<string>('9 AM')
    const [endHourLabel, setEndHourLabel] = useState<string>('7 PM')

    const legalBusinessStatus = GlobalState.LegalBusinessStatus.get()

    const customMessageRef = useRef<InputRef>(null)

    const defaultMessageText =
      'Hi, [[Patient Name]]! This is [[Office Name]]. While preparing for your visit, we discovered that your insurance information is incomplete or inaccurate. Please provide your updated insurance information at: '
    const lbsStatus = legalBusinessStatus?.verificationStatus

    useEffect(() => {
      if (isOpen) {
        getSettings()
      }
    }, [isOpen])

    const handleClose = () => {
      setIsOpen(false)
    }

    const handleSave = () => {
      isSaving(true)
      saveSettings()
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    /**
     * Returns the offset difference in hours between the local time zone and a target time zone.
     * The difference is computed as: (local time – target time), so if local is behind target, the result is negative.
     */
    function getTimezoneOffsetDifference(): number {
      const now = new Date()
      // Render the current date as a string in local time.
      const localString = now.toLocaleString('en-US')
      // Render the same date in the target time zone.
      const targetString = now.toLocaleString('en-US', {
        timeZone: 'America/New_York',
      })
      // Create Date objects from those strings.
      const localDate = new Date(localString)
      const targetDate = new Date(targetString)
      // The difference in milliseconds, then convert to hours.
      const diffMs = localDate.getTime() - targetDate.getTime()
      return diffMs / (1000 * 60 * 60)
    }

    const easternToLocal = (easternHour: number): number => {
      // "America/New_York" is Eastern Time.
      const diff = getTimezoneOffsetDifference()
      // Adjust the hour by the difference.
      let localHour = easternHour + diff
      // Normalize into 0–23.
      localHour = ((localHour % 24) + 24) % 24
      // Replace 0 with 24.
      if (localHour === 0) localHour = 24
      return localHour
    }

    const localToEastern = (localHour: number): number => {
      const diff = getTimezoneOffsetDifference()
      let easternHour = localHour - diff
      easternHour = ((easternHour % 24) + 24) % 24
      // Replace 0 with 24.
      if (easternHour === 0) easternHour = 24
      return easternHour
    }

    const getSettings = async () => {
      setIsLoading(true)

      try {
        const { data } = await GetSettings()
        const {
          SendUpdateForm,
          AutomationTermDays,
          StartHour,
          EndHour,
          SendText,
          SendEmail,
          MessageText,
          MessageIsCustom,
        } = data

        let delivery: DeliveryMethod
        if (lbsStatus !== 2) delivery = 'EMAIL'
        else if (SendText && SendEmail) delivery = 'TEXT_AND_EMAIL'
        else if (SendText) delivery = 'TEXT'
        else if (SendEmail) delivery = 'EMAIL'
        // Defaults to the first option
        else delivery = 'TEXT_AND_EMAIL'

        let start: number
        let end: number
        if (!SendUpdateForm && StartHour === 0 && EndHour === 0) {
          start = DEFAULT_START_HOUR
          end = DEFAULT_END_HOUR
        } else {
          start = easternToLocal(StartHour)
          end = easternToLocal(EndHour)
        }

        setSettings({
          ...settings,
          automationTermDays:
            AutomationTermDays > 0 ? AutomationTermDays : DEFAULT_DAYS,
          sendUpdateForm: SendUpdateForm,
          startHour: start,
          endHour: end,
          customMessageText: MessageIsCustom ? MessageText : '',
          messageType: MessageIsCustom ? 'CUSTOM' : 'DEFAULT',
          deliveryMethod: delivery,
          sendReminder: false,
          reminderTermHours: null,
        })
      } catch (e) {
        LogError(e, 'Error while retrieving eligibility settings')
      } finally {
        setIsLoading(false)
      }
    }

    const saveSettings = async () => {
      isSaving(true)

      if (getCurrentLength(settings.customMessageText) > MAX_LENGTH) {
        showMessage(
          'Please shorten custom message to allowed length before saving.',
        )
        isSaving(false)
        return
      }

      if (
        !settings.automationTermDays ||
        settings.automationTermDays < MIN_DAYS ||
        settings.automationTermDays > MAX_DAYS
      ) {
        showMessage('Please enter a number of days between 1 and 21')
        isSaving(false)
        return
      }

      const req = transformRequest()
      try {
        await SaveSettings(req)
        showMessage('Successfully saved Eligibility Settings.', 'success')
      } catch (e) {
        LogError(e)
        showMessage('Unable to save Eligibility Settings.')
      } finally {
        isSaving(false)
        setIsOpen(false)
      }
    }

    const transformRequest = () => {
      return {
        SendUpdateForm: settings.sendUpdateForm,
        AutomationTermDays: settings.automationTermDays,
        StartHour: localToEastern(settings.startHour),
        EndHour: localToEastern(settings.endHour),
        SendText: settings.deliveryMethod?.includes('TEXT'),
        SendEmail: settings.deliveryMethod?.includes('EMAIL'),
        MessageText:
          settings.messageType === 'CUSTOM'
            ? settings.customMessageText
            : defaultMessageText,
        MessageIsCustom: settings.messageType === 'CUSTOM' ? true : false,
      }
    }

    const handleUpdateDeliveryMethod = (e: RadioChangeEvent) => {
      const updatedMethod: DeliveryMethod = e.target.value as DeliveryMethod
      setSettings({ ...settings, deliveryMethod: updatedMethod })
    }

    const date = new Date().toString().slice(25).replace(/[()]/g, '')
    const dateStr =
      '(' +
      date.substring(0, date.indexOf(' ')) +
      ')' +
      date.substring(date.indexOf(' '))
    const frankenDate = dateStr + ' (' + timeZone + ')'

    const initialMenuItems: { key: number; label: string }[] = [
      { key: 1, label: '1 AM' },
      { key: 2, label: '2 AM' },
      { key: 3, label: '3 AM' },
      { key: 4, label: '4 AM' },
      { key: 5, label: '5 AM' },
      { key: 6, label: '6 AM' },
      { key: 7, label: '7 AM' },
      { key: 8, label: '8 AM' },
      { key: 9, label: '9 AM' },
      { key: 10, label: '10 AM' },
      { key: 11, label: '11 AM' },
      { key: 12, label: '12 PM' },
      { key: 13, label: '1 PM' },
      { key: 14, label: '2 PM' },
      { key: 15, label: '3 PM' },
      { key: 16, label: '4 PM' },
      { key: 17, label: '5 PM' },
      { key: 18, label: '6 PM' },
      { key: 19, label: '7 PM' },
      { key: 20, label: '8 PM' },
      { key: 21, label: '9 PM' },
      { key: 22, label: '10 PM' },
      { key: 23, label: '11 PM' },
      { key: 24, label: '12 AM' },
    ]

    const filterMenuItemsByTimeZone = (): { key: number; label: string }[] => {
      return initialMenuItems.filter(({ key }) => {
        const diff = getTimezoneOffsetDifference()
        const adjustedHour = key - diff

        // Ensure valid Eastern Time range (1 AM - 12 AM)
        return adjustedHour >= 1 && adjustedHour <= 24
      })
    }

    const menuItems: { key: number; label: string }[] =
      filterMenuItemsByTimeZone()

    const onHourStartClick: MenuProps['onClick'] = ({ key }) => {
      setStartHourLabel(
        menuItems.find((item) => item.key === parseInt(key)).label,
      )
      setSettings({ ...settings, startHour: parseInt(key) })
    }

    const onHourEndClick: MenuProps['onClick'] = ({ key }) => {
      setEndHourLabel(
        menuItems.find((item) => item.key === parseInt(key)).label,
      )
      setSettings({ ...settings, endHour: parseInt(key) })
    }

    const getHourLabel = (hour: number, end: boolean) => {
      const itemFound = menuItems.find((item) => item.key === hour)

      return itemFound?.label != null
        ? itemFound.label
        : end
          ? endHourLabel
          : startHourLabel
    }

    const handleAddPatientName = () => {
      const updatedMessage = `${settings.customMessageText}${'[[Patient Name]]'}`
      setSettings({ ...settings, customMessageText: updatedMessage })
      customMessageRef.current.focus({ cursor: 'end' })
    }

    const handleUpdateCustomMessage = (
      e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      // Note: accounting for the fact that the placeholder is 16 characters in length but we want to allow only 15 characters for the patient name here
      setSettings({
        ...settings,
        customMessageText: e.target.value,
      })
    }

    const getCurrentLength = (message: string) =>
      message?.includes('[[Patient Name]]')
        ? message.length - 1
        : message?.length || 0

    const getRemainingCharacters = (message: string) => {
      const currentLength = getCurrentLength(message)
      if (currentLength >= MAX_LENGTH) return 0
      else return MAX_LENGTH - currentLength
    }

    const handleTermDaysChange = (value: number) => {
      if (value < MIN_DAYS || value > MAX_DAYS) {
        showMessage('Please enter a number of days between 1 and 21')
      }
      setSettings({ ...settings, automationTermDays: value })
    }

    return (
      <VyneModal
        className='eligibility-settings-modal'
        dataTestId='eligibility-settings-modal'
        destroyOnClose
        footer={[
          <VyneButton
            dataTestId='close-settings-button'
            key='close-settings'
            onClick={handleClose}
          >
            Close
          </VyneButton>,
          <VyneButton
            dataTestId='save-settings-button'
            loading={saving}
            key='save-settings'
            onClick={handleSave}
            type='primary'
          >
            Save
          </VyneButton>,
        ]}
        onCancel={handleClose}
        open={isOpen}
        style={{ top: 20 }}
        styles={{
          body: {
            overflowY: 'visible',
            backgroundColor: 'var(--grey-100, #fafcff)',
            padding: '0.5em',
          },
        }}
        title='Eligibility Settings'
        width='fit-content'
      >
        <Spin spinning={loading}>
          <section
            className={`eligibility-settings-modal__form eligibility-settings-modal__form--auto-settings`}
          >
            <h3 className='mb-16-px'>Automated Settings</h3>
            <p>
              Verify eligibility
              <InputNumber
                className='ml-8-px mr-8-px'
                type='number'
                min={MIN_DAYS}
                max={MAX_DAYS}
                title='Please enter a number between 1 and 21'
                required
                value={settings.automationTermDays}
                onChange={handleTermDaysChange}
              />
              days before a patient&apos;s scheduled appointment
            </p>
          </section>
          <section
            className={`eligibility-settings-modal__form eligibility-settings-modal__form--${
              settings.sendUpdateForm ? 'enabled' : 'disabled'
            }`}
          >
            <h3 className='mb-16-px'>Patient Update Form</h3>
            <div className='flex mb-16-px'>
              <VyneSwitch
                dataTestId='send-update-form-setting-switch'
                checked={settings.sendUpdateForm}
                checkedChildren='ON'
                unCheckedChildren='OFF'
                onChange={(checked: boolean) =>
                  setSettings({ ...settings, sendUpdateForm: checked })
                }
              />
              <p className='ml-8-px'>
                When a &ldquo;Not Eligible&rdquo; status is detected,
                automatically send a patient the Insurance Update form.
              </p>
            </div>
            <h4 className='mb-8-px'>Text/Email Settings</h4>
            <p>
              Please choose the times of day to send Text Messages and Emails on
              your behalf.
            </p>
            <div className='flex gap-8-px mt-8-px mb-8-px'>
              <div className='flex gap-4-px'>
                <p
                  style={{
                    color: 'var(--vyne-color-text-description, #868686)',
                  }}
                >
                  From
                </p>
                <Dropdown
                  className={`eligibility-settings-modal__form-settings-dropdown--${
                    settings.sendUpdateForm ? 'enabled' : 'disabled'
                  }`}
                  data-testid='start-hour-dropdown'
                  disabled={!settings.sendUpdateForm}
                  menu={{
                    className: 'eligibility-settings-time-dropdown',
                    items: menuItems.filter(
                      (item) => item.key < settings.endHour,
                    ),
                    onClick: onHourStartClick,
                    role: 'menu',
                  }}
                  placement='bottom'
                  trigger={['click']}
                >
                  <VyneButton
                    dataTestId='start-hour-button'
                    size='small'
                    icon={
                      <DownOutlined
                        style={{ fontSize: '0.65rem', paddingLeft: '0.75em' }}
                      />
                    }
                  >
                    {getHourLabel(settings.startHour, false)}
                  </VyneButton>
                </Dropdown>
              </div>
              <div className='flex gap-4-px'>
                <p
                  style={{
                    color: 'var(--vyne-color-text-description, #868686)',
                  }}
                >
                  To
                </p>
                <Dropdown
                  className={`eligibility-settings-modal__form-settings-dropdown--${
                    settings.sendUpdateForm ? 'enabled' : 'disabled'
                  }`}
                  data-testid='end-hour-dropdown'
                  disabled={!settings.sendUpdateForm}
                  menu={{
                    className: 'eligibility-settings-time-dropdown',
                    items: menuItems.filter(
                      (item) => item.key > settings.startHour,
                    ),
                    onClick: onHourEndClick,
                    role: 'menu',
                  }}
                  placement='bottom'
                  trigger={['click']}
                >
                  <VyneButton
                    dataTestId='end-hour-button'
                    icon={
                      <DownOutlined
                        style={{ fontSize: '0.65rem', paddingLeft: '0.75em' }}
                      />
                    }
                    size='small'
                    type='default'
                  >
                    {getHourLabel(settings.endHour, true)}
                  </VyneButton>
                </Dropdown>
              </div>
            </div>
            <p
              className='vyne-sm-text'
              style={{ color: 'var(--vyne-color-text-description, #868686)' }}
            >
              Time zone {frankenDate}.
            </p>
            <Divider />
            <div>
              <h4 className='mb-8-px'>Patient Message</h4>
              <Group
                className='flex-column gap-8-px'
                onChange={(e) =>
                  setSettings({ ...settings, messageType: e.target.value })
                }
                value={settings.messageType}
                disabled={!settings.sendUpdateForm}
              >
                <VyneRadio
                  className='mt-050 mb-050'
                  dataTestId='default-message-radio-button'
                  value='DEFAULT'
                >
                  Default
                </VyneRadio>
                <TextArea
                  rows={2}
                  value={defaultMessageText}
                  disabled
                />
                <VyneRadio
                  className='mt-050 mb-050'
                  dataTestId='custom-message-radio-button'
                  value='CUSTOM'
                >
                  Custom Message
                </VyneRadio>
                <TextArea
                  rows={2}
                  value={settings.customMessageText}
                  disabled={
                    !settings.sendUpdateForm ||
                    settings.messageType !== 'CUSTOM'
                  }
                  onChange={(e) => handleUpdateCustomMessage(e)}
                  ref={customMessageRef}
                  status={
                    getCurrentLength(settings.customMessageText) > MAX_LENGTH &&
                    'error'
                  }
                />
              </Group>
              <p
                className='vyne-sm-text mt-4-px'
                style={{
                  color:
                    getCurrentLength(settings.customMessageText) > MAX_LENGTH
                      ? 'var(--vyne-color-text-error, #ff0000)'
                      : 'transparent',
                }}
              >
                Messages are limited to {MAX_LENGTH} characters in length.
              </p>
              <div className='flex-row items-center justify-between'>
                <VyneButton
                  dataTestId='add-patient-name-button'
                  disabled={
                    !settings.sendUpdateForm ||
                    settings.messageType !== 'CUSTOM'
                  }
                  icon={<PlusOutlined />}
                  onClick={handleAddPatientName}
                >
                  Patient Name
                </VyneButton>
                <p
                  className='vyne-sm-text'
                  style={{
                    color: 'var(--vyne-color-text-description, #868686)',
                  }}
                >{`${getRemainingCharacters(
                  settings.customMessageText,
                )} characters remaining`}</p>
              </div>
            </div>
            <Divider />
            <div>
              <h4 className='mb-8-px'>Delivery Method</h4>
              <p className='mb-8-px'>
                Select the default delivery method when sending patient update
                forms.
              </p>
              <Group
                className='flex-column gap-8-px'
                onChange={handleUpdateDeliveryMethod}
                value={settings.deliveryMethod}
                disabled={!settings.sendUpdateForm}
              >
                <VyneRadio
                  dataTestId='text-email-delivery-radio-button'
                  value='TEXT_AND_EMAIL'
                  disabled={lbsStatus !== 2}
                >
                  Send both text message & email
                </VyneRadio>
                <VyneRadio
                  dataTestId='text-only-delivery-radio-button'
                  value='TEXT'
                  disabled={lbsStatus !== 2}
                >
                  Send text message only
                </VyneRadio>
                <VyneRadio
                  dataTestId='email-only-delivery-radio-button'
                  value='EMAIL'
                >
                  Send email message only
                </VyneRadio>
              </Group>
            </div>
          </section>
          {lbsStatus !== 2 && (
            <div className='eligibility-settings-modal--lbs'>
              <img
                alt='SMS Error'
                src={errorOutline}
                className='eligibility-settings-modal--lbs__icon'
              />
              <div>
                <p
                  className='vyne-bold'
                  style={{
                    color: 'var(--vyne-color-error-text-active, #b21c1a)',
                  }}
                >
                  SMS Unavailable
                </p>
                <p className='mt-4-px mb-8-px'>
                  Due to SMS regulations, you must successfully verify your
                  office as a legal business entity by supplying your
                  information in the My Practice Section.
                </p>
                <VyneButton
                  dataTestId='take-me-there-button'
                  href='/Practice/PracticeInfo'
                  rel='noreferrer'
                  target='_blank'
                >
                  Take me there!
                </VyneButton>
              </div>
            </div>
          )}
        </Spin>
      </VyneModal>
    )
  },
)

export default EligibilitySettingsModal
