import { ObservableObject } from '@legendapp/state'
import { VyneRadio } from '@vynedental/design-system'
import { Radio, RadioChangeEvent } from 'antd'
import { FC } from 'react'

import {
  EligibilityResponseStateType,
  StructuredBenefit,
} from '../utilities/eligibilityTyping'

import './networkToggle.scss'

import { observer } from '@legendapp/state/react'

export const IN_NETWORK = 'In Network'
const OUT_OF_NETWORK = 'Out of Network'
const PREMIER = 'Premier'

type NetworkToggleProps = {
  state: ObservableObject<EligibilityResponseStateType>
}

const NetworkToggle: FC<NetworkToggleProps> = observer(({ state }) => {
  const structuredView = state?.structuredView?.get()

  const handleNetworkToggle = (e: RadioChangeEvent) => {
    let benefits: StructuredBenefit

    switch (e.target.value) {
      case IN_NETWORK:
        benefits = structuredView?.benefits.find(
          (benefit) =>
            benefit?.network === 'in_network' &&
            (!benefit?.payer_specific_description ||
              benefit?.payer_specific_description === 'PPO'),
        )
        break
      case OUT_OF_NETWORK:
        benefits = structuredView?.benefits.find(
          (benefit) => benefit?.network === 'out_of_network',
        )
        break
      case PREMIER:
        benefits = structuredView?.benefits.find(
          (benefit) =>
            benefit?.network === 'in_network' &&
            benefit?.payer_specific_description === 'PREMIER',
        )
        break
      default:
        break
    }

    state.currentBenefits.set(benefits)
    state.currentNetwork.set(e.target.value)
  }

  return (
    <Radio.Group
      buttonStyle='solid'
      className='structured-view-network-toggle'
      defaultValue={IN_NETWORK}
      onChange={handleNetworkToggle}
      size='middle'
    >
      <VyneRadio
        dataTestId='in-network-radio-button'
        isButton={true}
        value={IN_NETWORK}
      >
        In Network
      </VyneRadio>
      <VyneRadio
        dataTestId='out-network-radio-button'
        isButton={true}
        value={OUT_OF_NETWORK}
      >
        Out of Network
      </VyneRadio>
      {structuredView?.benefits?.length > 2 && (
        <VyneRadio
          dataTestId='premier-network-radio-button'
          isButton={true}
          value={PREMIER}
        >
          Premier
        </VyneRadio>
      )}
    </Radio.Group>
  )
})

export default NetworkToggle
