import { EditOutlined, SendOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { isValid } from 'date-fns'
import { FC } from 'react'

import { formatDateFromString } from '../../../utilities/general'
import { PatientInfo } from '../AttachmentsTypes'

import './AttachmentView.scss'

import { VyneButton } from '@vynedental/design-system'

interface AttachmentViewHeaderProps {
  loading: boolean
  patientInfo: PatientInfo
  carrier: { carrierReferenceNumber: string; carrierName: string }
  handleOpenPatientModal: () => void
  handleSendConfirm: () => void
  handleCancelConfirm: () => void
  handleSaveAndClose: (isHold: boolean) => Promise<void>
}

const AttachmentViewHeader: FC<AttachmentViewHeaderProps> = ({
  loading,
  patientInfo,
  carrier,
  handleOpenPatientModal,
  handleSendConfirm,
  handleCancelConfirm,
  handleSaveAndClose,
}) => {
  return (
    <>
      <Row
        className='mb-100'
        justify={'space-between'}
      >
        <h1>New Attachment</h1>
        <div className='sa-edit__header-actions'>
          <VyneButton
            dataTestId='cancel-button'
            disabled={loading}
            onClick={handleCancelConfirm}
          >
            Cancel
          </VyneButton>
          <VyneButton
            dataTestId='hold-button'
            disabled={loading}
            onClick={() => handleSaveAndClose(true)}
          >
            Hold
          </VyneButton>
          <VyneButton
            dataTestId='save-and-exit-button'
            disabled={loading}
            onClick={() => handleSaveAndClose(false)}
          >
            Save & Exit
          </VyneButton>
          <VyneButton
            dataTestId=''
            disabled={loading}
            icon={<SendOutlined />}
            type='primary'
            onClick={handleSendConfirm}
          >
            Send
          </VyneButton>
        </div>
      </Row>
      <Row className='sa-edit__patient-info'>
        <Col span={6}>
          <h4>
            {patientInfo?.patientFirstName && `${patientInfo.patientFirstName}`}{' '}
            {patientInfo?.patientLastName && ` ${patientInfo.patientLastName}`}
          </h4>
        </Col>
        <Col span={6}>
          <p className='vyne-sm-text'>
            {patientInfo ? patientInfo.carrierName?.toUpperCase() : ''}
          </p>
        </Col>
      </Row>
      <Row className='sa-edit__patient-info'>
        <Col span={6}>
          <p className='vyne-sm-text'>
            {patientInfo && isValid(new Date(patientInfo?.patientDateOfBirth))
              ? formatDateFromString(
                  patientInfo.patientDateOfBirth,
                  'MM/dd/yyy',
                )
              : ''}
          </p>
        </Col>
        <Col span={6}>
          <p className='vyne-sm-text'>
            <b className='mr-16-px'>
              {patientInfo ? `Carrier ID: ${patientInfo.carrierId}` : ''}
            </b>
            <VyneButton
              className='vyne-sm-text'
              dataTestId='edit-patient-button'
              disabled={loading}
              icon={<EditOutlined />}
              onClick={handleOpenPatientModal}
              type='link'
            >
              Edit
            </VyneButton>
          </p>
        </Col>
      </Row>
    </>
  )
}

export default AttachmentViewHeader
