import { LogError } from 'utils'

import { CarrierDto } from 'trellis:api/carrier/carrier-client'
import { CarrierApi } from 'trellis:api/carrier/carrierApi'
import GlobalState from 'trellis:state/globalState'

import { Carrier as FaCarrier } from '../api/attachment-standalone/attachment-standalone-client'
import { ParticipatingCarrier } from '../components/Eligibility/shared/utilities/eligibilityTyping'

export namespace CarrierHelpers {
  export const getFaCarrierById = (
    carrierList: FaCarrier[],
    masterId?: number,
    payerId?: string,
  ): FaCarrier => {
    let matchedCarrier: FaCarrier = null
    if (masterId) {
      matchedCarrier = carrierList?.find(
        (carrier) => carrier.neaMasterId === masterId,
      )
    }

    if (payerId && !matchedCarrier) {
      matchedCarrier = carrierList?.find(
        (carrier) => carrier.payerTxtId === payerId,
      )
    }
    return matchedCarrier
  }

  export const getEligibilityCarrierById = (
    carrierList: ParticipatingCarrier[],
    payerId: string,
  ) => {
    try {
      return carrierList?.find((carrier) => carrier.CarrierId === payerId)
    } catch (error) {
      LogError(error, 'Failed to map patient carrier')
    }
    return null
  }

  export const getOnderfulCarrierList: () => Promise<
    CarrierDto[] | undefined
  > = async () => {
    if (GlobalState.OnderfulCarrierList.peek()) {
      return GlobalState.OnderfulCarrierList.peek()
    }

    try {
      const response = await CarrierApi.GetCarrierList()
      const carrierList = response.data

      // Create a hash map for O(1) lookups
      const carrierMap = new Map<string, CarrierDto>()
      carrierList.forEach((carrier: CarrierDto) => {
        carrierMap.set(carrier.Id, carrier)
      })

      // Store both the array and map in GlobalState
      GlobalState.OnderfulCarrierList.set(carrierList)
      GlobalState.OnderfulCarrierMap.set(carrierMap)

      return carrierList
    } catch (error) {
      LogError(error, 'Failed to get onderful carrier list')
      return undefined
    }
  }

  export const getOnderfulCarrierById = (
    carrierId: string,
  ): CarrierDto | undefined => {
    const carrierMap = GlobalState.OnderfulCarrierMap.peek()
    return carrierMap?.get(carrierId)
  }
}
