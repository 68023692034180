import { observer, useObservable } from '@legendapp/state/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import { LoadingSpinner } from 'ui'

import {
  getLoginPageUrl,
  getPostLoginQueryString,
  isTrellisAuthExpired,
  logoutUser,
  refreshTrellisAuth,
} from 'trellis:features/authentication/Login/utils/login-helpers'
import { GlobalState, LDFlags$ } from 'trellis:state/globalState'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'
import { when } from '@legendapp/state'
import { updateLdContext } from 'trellis:utilities/ldHelper'

const PrivateRoutes = observer(() => {
  // package hooks
  const ldClient = useLDClient()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  // global state
  const billingDetails = GlobalState.BillingDetails.get()
  const userInfo = GlobalState.UserInfo.get()
  const authenticated = GlobalState?.IsAuthenticated?.get()
  const authLoading = GlobalState?.AuthLoading.get()
  const paymentOnFile = GlobalState?.PaymentOnFile.get()
  const [ldFlagsInitialized, setLdFlagsInitialized] = useState(false)

  const getLoadingState = () => isTrellisAuthExpired()

  const loadingObservable = useObservable(getLoadingState())
  const isLoading = loadingObservable.get()

  useEffect(() => {
    if (GlobalState.IsAuthenticated.peek() && isTrellisAuthExpired()) {
      loadingObservable.set(true)
      refreshTrellisAuth(GlobalState.Auth.peek()).then(() => {
        loadingObservable.set(false)
      })
    }
  }, [location])

  when(
    () => authenticated && ldClient,
    () => {
      updateLdContext(
        ldClient,
        GlobalState.HasPmsData.peek(),
        GlobalState.ActiveServicesString.peek(),
        GlobalState.UserInfo.globalCustomerID.peek(),
      ).then(() => {
        setLdFlagsInitialized(true)
      })
    }
  )

  if (!authenticated || authLoading) {
    const logoutUrl = getLoginPageUrl(searchParams, location)
    logoutUser(ldClient)

    // go back to login if they're not authed
    return (
      <Navigate
        to={logoutUrl}
        replace
      />
    )
  }

  /******************************************************************** POST AUTH LOADING STATE ********************************************************************/

  const queryString = getPostLoginQueryString(searchParams)
  const redirectUrl = location.pathname + location.hash + queryString
  const flags = LDFlags$.get()
  const eulaPath = '/Account/AcceptEulaExternal'

  // Show loading state while flags are being initialized
  if (isLoading || !ldFlagsInitialized) {
    return <LoadingSpinner />
  }

  // force the user to accept the EULA and setup payment before allowing them in
  return !userInfo.eulaAccepted ? (
    location.pathname != eulaPath ? (
      <Navigate
        to={eulaPath}
        replace
        state={{ redirectURL: redirectUrl }}
      />
    ) : (
      <Outlet />
    )
  ) : userInfo.isSso &&
    (RoleHelper$.isTrellis.get() || !RoleHelper$.isDentalIntel.get()) &&
    flags.billingAndSubscription &&
    !paymentOnFile ? (
    location.pathname != '/Account/LoginPaymentInfoExternal' ? (
      <Navigate
        to={'/Account/LoginPaymentInfoExternal'}
        replace
        state={{ redirectURL: redirectUrl }}
      />
    ) : (
      <Outlet />
    )
  ) : !billingDetails.paymentType &&
    RoleHelper$.isTrellis.get() &&
    !flags.billingAndSubscription ? (
    location.pathname != '/Account/LoginPaymentInfo' ? (
      <Navigate
        to={'/Account/LoginPaymentInfo'}
        replace
        state={{ redirectURL: redirectUrl }}
      />
    ) : (
      <Outlet />
    )
  ) : (
    <Outlet />
  )
})

export default PrivateRoutes
