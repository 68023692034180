import { ObservableBoolean } from '@legendapp/state'
import { useQuery } from '@tanstack/react-query'

import { GetArchiveClaims } from 'trellis:api/claim/claimApi'
import { DateRange } from 'trellis:utilities/datePickerUtilities'

import { ClaimArchiveGridState } from '../context/ClaimArchiveContext'

export const useGetArchiveClaims = (
  dateRange: DateRange,
  state: ClaimArchiveGridState,
  showArchiveSearchModal$: ObservableBoolean,
) => {
  return useQuery({
    queryKey: ['archive-claims', { dateRange, state }],
    queryFn: () => {
      return GetArchiveClaims(
        dateRange.dates[0].toISOString(),
        dateRange.dates[1].toISOString(),
        state.Search,
      )
    },
    staleTime: Infinity,
    enabled: showArchiveSearchModal$.get(),  
  })
}
