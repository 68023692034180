import { ObservableObject } from '@legendapp/state'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC, useEffect, useState } from 'react'

import { SearchBar, SearchStates } from 'ui'

import { useFuse } from 'trellis:utilities/fuzzySearch/useFuse'
import { capitalize } from 'trellis:utilities/general'

import {
  EligibilityResponseStateType,
  ProcedureCodeFilters,
  StructuredCoinsurance,
  StructuredLimitation,
  StructuredProcedureCode,
} from '../utilities/eligibilityTyping'

import './structuredViewBenefitsGrid.scss'

import { observer } from '@legendapp/state/react'

type StructuredViewBenefitsGridProps = {
  state: ObservableObject<EligibilityResponseStateType>
}

const StructuredViewBenefitsGrid: FC<StructuredViewBenefitsGridProps> =
  observer(({ state }) => {
    const currentBenefits = state?.currentBenefits?.get()

    const [searchText, setSearchText] = useState<SearchStates>('')
    const [filters, setFilters] = useState<ProcedureCodeFilters>({
      currentPage: 1,
      pageSize: 10,
    })

    const { hitItems, hits, onSearch } = useFuse<StructuredProcedureCode>({
      data: currentBenefits?.procedure_codes,
      matchAllOnEmptyQuery: true,
      options: { keys: ['procedure_code', 'category'] },
    })

    useEffect(() => {
      onSearch(searchText as string)
    }, [searchText])

    const updatePaging = (currentPage: number, pageSize: number) => {
      const filtersCopy = { ...filters }

      filtersCopy.currentPage = currentPage
      filtersCopy.pageSize = pageSize

      setFilters({ ...filtersCopy })
    }

    const pagination = {
      showSizeChanger: true,
      current: filters.currentPage,
      pageSize: filters.pageSize,
      total: hitItems?.length,
      showTotal: (total: number, range: [number, number]) =>
        `${range[0]}-${range[1]} of ${total} items`,
      onChange: (page: number, pageSize: number) =>
        updatePaging(page, pageSize),
    }

    const columns: ColumnsType<StructuredProcedureCode> = [
      {
        dataIndex: 'procedure_code',
        title: 'ADA Code',
      },
      {
        dataIndex: 'category',
        title: 'Category',
        render: (value: string) => value && capitalize(value),
      },
      {
        dataIndex: 'synonyms',
        title: 'Synonyms',
        render: (value: string[]) =>
          value?.map((v) => capitalize(v)).join(', '),
      },
      {
        dataIndex: 'coinsurance',
        title: 'Coinsurance',
        render: (
          value: StructuredCoinsurance,
          record: StructuredProcedureCode,
        ) =>
          record?.is_not_covered ? 'Not Covered' : value && `${value.percent}%`,
      },
      {
        dataIndex: 'limitation',
        title: 'Frequency',
        render: (value: StructuredLimitation) => value?.frequency,
      },
      {
        dataIndex: 'limitation',
        title: 'Age Limit',
        render: (value: StructuredLimitation) => {
          if (value?.age_high_value) return `High: ${value.age_high_value}`
          else if (value?.age_low_value) return `Low: ${value.age_low_value}`
        },
      },
    ]

    return (
      <div className='structured-benefits-grid'>
        <div className='structured-benefits-grid__search'>
          <SearchBar
            state={searchText}
            setState={setSearchText}
            placeholder='Search by procedure code, or category...'
          />
        </div>
        <Table
          size='middle'
          columns={columns}
          dataSource={hitItems}
          rowClassName={(record: StructuredProcedureCode, index: number) => {
            if (record?.is_not_covered)
              return 'structured-benefits-grid__row--not-covered'
            else if (index % 2 !== 0)
              return 'structured-benefits-grid__row--gray'
          }}
          rowKey={(record: StructuredProcedureCode) => record?.procedure_code}
          pagination={pagination}
        />
      </div>
    )
  })

export default StructuredViewBenefitsGrid
