import { observer } from '@legendapp/state/react'
import { Col, Form, Row, Spin } from 'antd'
import { InternalNamePath } from 'antd/lib/form/interface'
import { compareDesc } from 'date-fns'
import { FC, useEffect } from 'react'

import { FormSwitch } from 'ui'
import { LogError } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import {
  EligibilityPatient,
  EligibilityPatients,
  VerifyEligibilityResponse,
} from '../../../../api/eligibility/eligibility-client'
import {
  GetPatient,
  VerifyPatient,
} from '../../../../api/eligibility/eligibilityApi'
import { Errors } from '../../../../constants/errors'
import { NotifyText } from '../../../../constants/notifyText'
import { useGlobalContext } from '../../../../context/GlobalContextProvider'
import {
  extractTypeProperties,
  showMessage,
} from '../../../../utilities/general'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import InsuranceControls from '../../shared/form/controls/InsuranceControls'
import PatientControls from '../../shared/form/controls/PatientControls'
import ProviderControls from '../../shared/form/controls/ProviderControls'
import {
  getProviderName,
  toggleSubscriber,
  transformPatient,
} from '../../shared/form/utilities/eligibilityFormDataHandling'
import { PatientEligibilityFormFields } from '../../shared/form/utilities/eligibilityFormTyping'
import { ParticipatingCarrier } from '../../shared/utilities/eligibilityTyping'
import { getEligibilityResponse } from '../../shared/utilities/getEligibilityResponse'

const PatientEligibilityForm: FC = observer(() => {
  const { eligibilityUsevynedentalsync, operaVynesyncAutomatedeligibility } =
    LDFlags$.get()

  const { authentication, practiceDetails } = useGlobalContext()
  const {
    providers,
    setShowEligibilityResponseModal,
    setVerificationResponse,
    participatingCarriers,
  } = useEligibilityContext()
  const {
    formErrors,
    loading,
    isPatientReadyToVerify,
    patient,
    setActiveTab,
    setEligibilityHistory,
    setFormErrors,
    setIsPatientReadyToVerify,
    setLoading,
    setPatient,
    setPatientDisplayName,
    setPatientStatus,
    setShowPatientDetail,
    setShowingPatientResponseModal,
    setVerifying,
  } = usePatientEligibilityDetailContext()

  const [patientEligibilityForm] = Form.useForm()

  useEffect(() => {
    if (patient?.PatientId !== '0') {
      getPatient(patient.PatientId)
        .then(async () => {
          await patientEligibilityForm
            .validateFields()
            .catch(({ errorFields }) => {
              setFormErrors(errorFields)
            })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      patientEligibilityForm.setFieldsValue({ ...patient })
    }
  }, [])

  useEffect(() => {
    if (isPatientReadyToVerify) handleVerifyPatient()
  }, [isPatientReadyToVerify])

  const getPatient = async (patientId: string) => {
    await GetPatient(patientId)
      .then(({ data }) => {
        const patientCopy = transformPatient(
          patient,
          data,
          participatingCarriers,
          providers,
          practiceDetails,
        )
        const patientProviderName = getProviderName(patientCopy)

        patientEligibilityForm.setFieldsValue({
          ...patientCopy,
          ProviderName: patientProviderName,
        })

        setPatient({ ...patientCopy })
        setShowPatientDetail(true)
        setEligibilityHistory(
          data.VerificationHistory.sort((a, b) =>
            compareDesc(new Date(a.RequestDate), new Date(b.RequestDate)),
          ),
        )
        setLoading(false)
      })
      .catch((error) => {
        showMessage(Errors.patientInfoError)
        LogError(error, 'Failed to get patient')
        throw error
      })
  }

  const handleVerifyPatient = () => {
    setVerifying(true)

    const patientCopy = { ...patient }
    if (patient.PatientIsSub) {
      patientCopy.SubscriberFirstName = patientCopy.PatientFirstName
      patientCopy.SubscriberMiddleName = patientCopy.PatientMiddleName
      patientCopy.SubscriberLastName = patientCopy.PatientLastName
      patientCopy.SubscriberSuffix = patientCopy.PatientSuffix
      patientCopy.SubscriberBirthdate = patientCopy.PatientBirthdate
      patientCopy.SubscriberGender = patientCopy.PatientGender
    }

    const hasMissingInfoPMS = validateisMissingInfoPMS(patientCopy)

    //Validate carrierID
    if (!patientCopy.CarrierId || patientCopy.CarrierId === '0') {
      const carrierFound = participatingCarriers.filter(
        (carrier: ParticipatingCarrier) =>
          carrier.CarrierName?.toLowerCase() ==
          patientCopy.CarrierName?.toLowerCase(),
      )
      patientCopy.CarrierId = carrierFound[0]?.CarrierId
    }

    if (!hasMissingInfoPMS) {
      patientEligibilityForm
        .validateFields()
        .then(() => {
          verifyPatient(patientCopy)
        })
        .catch(({ errorFields }) => {
          setFormErrors(errorFields)
          setIsPatientReadyToVerify(false)
          setVerifying(false)
          showMessage(Errors.formValidationErrors)
        })
    } else {
      setIsPatientReadyToVerify(false)
      setVerifying(false)
      showMessage(NotifyText.practiceManagementSystemInfoError)
    }
  }

  const validateisMissingInfoPMS = (patientCopy: EligibilityPatient) => {
    let hasMissingInfoPMS: boolean = false

    if (
      !patientCopy.CarrierName ||
      !patientCopy.PatientBirthdate ||
      !patientCopy.PatientFirstName ||
      !patientCopy.PatientGender ||
      !patientCopy.PatientLastName ||
      !patientCopy.SubscriberBirthdate ||
      !patientCopy.SubscriberFirstName ||
      !patientCopy.SubscriberGender ||
      !patientCopy.SubscriberLastName
    )
      hasMissingInfoPMS = true

    return hasMissingInfoPMS
  }

  const verifyPatient = async (patientValues: EligibilityPatient) => {
    await VerifyPatient(patientValues.PatientId, patientValues)
      .then(({ data: response }) => {
        handleEligibilityResponse(
          {
            ...response,
            HtmlResult: response.HtmlResult,
            PatientId: response.PatientId,
          },
          patientValues,
        )
        setPatientDisplayName(
          `${patientValues.PatientLastName}, ${patientValues.PatientFirstName}`,
        )
      })
      .catch((error) => {
        if (error instanceof ReferenceError)
          showMessage(error.message, 'warning')
        else showMessage(Errors.verifyPatientError, 'error')
        setIsPatientReadyToVerify(false)
        setVerifying(false)
      })
      .finally(() => {
        setIsPatientReadyToVerify(false)
        setVerifying(false)
      })
  }

  const handleEligibilityResponse = async (
    verifyResponse: VerifyEligibilityResponse,
    patientCopy: EligibilityPatient,
  ) => {
    await getEligibilityResponse<EligibilityPatients>(
      authentication,
      { ...patientCopy, Status: verifyResponse.Status },
      verifyResponse.HtmlResult,
      verifyResponse.EligibilityId,
    )
      .then((response) => setVerificationResponse(response))
      .catch(() => {
        throw new ReferenceError(NotifyText.noEligibilityResponseFound)
      })

    setActiveTab('Response History')
    setPatientDisplayName(
      `${patientCopy.PatientLastName}, ${patientCopy.PatientFirstName}`,
    )
    setPatientStatus({
      status: verifyResponse.Status,
      toolTip: verifyResponse.StatusDescription,
    })

    if (verifyResponse.PatientId !== 0)
      getPatient(verifyResponse.PatientId.toString())

    setShowingPatientResponseModal(false)
    setShowEligibilityResponseModal(true)
  }

  const handleValuesChange = (
    changedValues: { [key in keyof PatientEligibilityFormFields]: string },
    allValues: PatientEligibilityFormFields,
  ) => {
    const patientCopy = extractTypeProperties<
      EligibilityPatient,
      PatientEligibilityFormFields
    >({ ...patient }, { ...allValues })
    setPatient({ ...patientCopy })
    patientEligibilityForm.setFieldsValue({ ...patient, ...allValues })

    patientEligibilityForm
      .validateFields(Object.keys(changedValues))
      .catch(({ errorFields }) => {
        const formErrorsCopy = JSON.parse(JSON.stringify(formErrors))

        if (errorFields.length) {
          errorFields.forEach(
            (fieldError: {
              errors: string[]
              name: string[]
              warning: string[]
            }) => {
              if (formErrorsCopy.length) {
                const match = formErrorsCopy.find(
                  (formError: {
                    errors: string[]
                    name: string[]
                    warning: string[]
                  }) => formError.name[0] === fieldError.name[0],
                )
                if (!match) formErrorsCopy.push(fieldError)
              } else formErrorsCopy.push(fieldError)
            },
          )
        } else {
          formErrorsCopy.forEach(
            (
              error: { errors: string[]; name: string[]; warning: string[] },
              index: number,
            ) => {
              if (error.name[0] === Object.keys(changedValues)[0])
                formErrorsCopy.splice(index, 1)
            },
          )
        }

        setFormErrors(formErrorsCopy)
      })
  }

  const onFinish = () => {
    setIsPatientReadyToVerify(true)
  }

  const onFinishFailed = (
    errorFields: { name: InternalNamePath; errors: string[] }[],
  ) => {
    showMessage(Errors.formValidationErrors)
    setFormErrors(errorFields)
  }

  return (
    <Spin spinning={loading}>
      <Form
        colon={false}
        form={patientEligibilityForm}
        name='patientEligibilityForm'
        onFinish={onFinish}
        onFinishFailed={(info) => onFinishFailed(info.errorFields)}
        onValuesChange={(changedValues, allValues) =>
          handleValuesChange(changedValues, allValues)
        }
        requiredMark={false}
      >
        <Row className='mt-200 form-detail-section gutter'>
          <Col
            span={24}
            lg={12}
          >
            <div className='eligibility-section-margin'>
              <h3 className='mb-14-px'>Patient</h3>
              <PatientControls patient={patient} />
              <Row
                align='middle'
                className='mb-200'
              >
                <p>Patient is a Subscriber</p>
                <FormSwitch
                  checked={patient.PatientIsSub}
                  className='patient-is-subscriber ml-100'
                  dataTestId='pat-sub-eligibility-form-switch'
                  disabled={
                    (eligibilityUsevynedentalsync ||
                      operaVynesyncAutomatedeligibility) &&
                    patient.PatientId !== '0'
                  }
                  name='PatientIsSub'
                  onChangeHandler={() =>
                    toggleSubscriber(
                      patient,
                      setPatient,
                      patientEligibilityForm,
                      formErrors,
                      setFormErrors,
                    )
                  }
                />
              </Row>
            </div>
          </Col>

          <Col
            span={24}
            lg={10}
          >
            <div>
              <h3 className='mb-14-px'>Subscriber</h3>
              <PatientControls
                patient={patient}
                prefix='Subscriber'
              />
            </div>
          </Col>

          <Col
            span={24}
            lg={12}
          >
            <div className='eligibility-section-margin'>
              <h3 className='mb-14-px'>Insurance</h3>
              <InsuranceControls
                patient={patient}
                setPatient={setPatient}
              />
            </div>
          </Col>

          <Col
            span={24}
            lg={10}
          >
            <div>
              <h3 className='mb-14-px'>Provider</h3>
              <ProviderControls
                patient={patient}
                setPatient={setPatient}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
})

export default PatientEligibilityForm
