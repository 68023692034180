import {
  DownloadOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InsertRowRightOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { Col, Drawer, Input, Row, Tooltip } from 'antd'
import { FC, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import { Labels } from 'trellis:constants/labels'

import { useClaimArchiveContext } from '../context/ClaimArchiveContext'
import { ClaimArchiveColumnSelector } from './ClaimArchiveColumnSelector'

import 'trellis:components/claims/controls/toolbar/toolbar.scss'

import { RangePicker } from '@vynedental/design-system'
import { endOfDay, intervalToDuration, startOfDay } from 'date-fns'
import dayjs from 'dayjs'

import { ArchiveClaim } from 'trellis:api/claim/claim-client'
import {
  archiveRangePresets,
  DateRange,
  rangeThemeConfig,
} from 'trellis:utilities/datePickerUtilities'

const { Search } = Input

type ClaimArchiveToolbarProps = {
  data: ArchiveClaim[]
}

export const ClaimArchiveToolbar: FC<ClaimArchiveToolbarProps> = ({ data }) => {
  const {
    state,
    setState,
    dateRange,
    setDateRange,
    toggleIgnore,
    print,
    getDownloadRows,
    searchTable,
  } = useClaimArchiveContext()

  const [search, setSearch] = useState('')
  const [initialLoad, setInitialLoad] = useState(true)
  const [showingSettings, setShowingSettings] = useState(false)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialLoad(false)
    setSearch(e.target.value || '')
  }

  const viewIgnored = state.Ignored

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    if (state) {
      const stateCopy = { ...state }
      stateCopy.Filters.CurrentPage = 1
      setState(stateCopy)
    }
  }

  const handleDateRangeSelect = (dates: DateRange['dates']) =>
    setDateFilter(dates)

  return (
    <Row
      className='toolbar-bottom-border'
      justify='end'
      align='middle'
      style={{ paddingBottom: '0.5em' }}
    >
      <Col
        style={{ flex: 'auto' }}
        className='claim-history-date-range'
      >
        <RangePicker
          allowClear={false}
          name='archive-claim-grid-range-picker'
          onChange={(dates, _dateStrings) => {
            handleDateRangeSelect([dates[0].toDate(), dates[1].toDate()])
          }}
          presets={archiveRangePresets}
          theme={rangeThemeConfig}
          value={
            dateRange?.dates && [
              dayjs(dateRange.dates[0]),
              dayjs(dateRange.dates[1]),
            ]
          }
        />
      </Col>
      <Col className='claim-history-search'>
        <Search
          placeholder='Search Patient, Subscriber, Carrier, etc.'
          onChange={onSearchChange}
          value={search}
          allowClear
        />
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title={Labels.viewHidden}
          mouseEnterDelay={0.5}
          className='mt-025'
        >
          {viewIgnored && (
            <EyeInvisibleOutlined
              onClick={toggleIgnore}
              className='grid-control-icon'
            />
          )}
          {!viewIgnored && (
            <EyeOutlined
              onClick={toggleIgnore}
              className='grid-control-icon'
            />
          )}
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Print'
          mouseEnterDelay={0.5}
        >
          <PrinterOutlined
            className='grid-control-icon'
            onClick={() => print(data)}
          />
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Download'
          mouseEnterDelay={0.5}
        >
          <CSVLink
            filename='Claims.csv'
            data={getDownloadRows(data)}
          >
            <DownloadOutlined className='grid-control-icon' />
          </CSVLink>
        </Tooltip>
      </Col>
      <Col className='grid-control-icon-container'>
        <Tooltip
          title='Columns'
          mouseEnterDelay={0.5}
        >
          <InsertRowRightOutlined
            onClick={() => setShowingSettings(true)}
            className='grid-control-icon'
          />
        </Tooltip>
        <Drawer
          title='Select | Reorder Columns'
          placement='right'
          width={300}
          onClose={() => setShowingSettings(false)}
          open={showingSettings}
        >
          <ClaimArchiveColumnSelector
            setShowingSelection={setShowingSettings}
          />
        </Drawer>
      </Col>
    </Row>
  )
}
