import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { LogBreadcrumb, LogError, setTag } from 'utils'

import {
  ClaimActivityLogFieldUpdate,
  ClaimDetailsModel,
  ClaimSaveSubmitProblemDetails,
  ClaimStatusCodes,
  ClaimValidationCode,
  FullClaimDetails,
} from 'trellis:api/claim/claim-client'
import {
  GetClaim,
  PostClaim,
  PrintClaimAdaForm,
  UpdateClaim,
} from 'trellis:api/claim/claimApi'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import { Errors } from 'trellis:constants/errors'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import {
  AttachmentCarrier,
  AttachmentRequirement,
  AttachmentResponse,
  ImageType,
  RefCarrierRequest,
} from '../../../api/attachment/attachment-client/api'
import {
  GetAttachmentRequirements,
  GetAttachmentRequirementsIgnoredState,
  GetCarrierMatch,
  GetClaimAttachment,
  GetImageTypes,
  GetSentAttachments,
  IgnoreAttachmentRequirements,
} from '../../../api/attachment/attachmentApi'
import {
  ClearAttachmentRequirementsApi,
  GetClaimAttachmentImageBytes,
  GetClaimAttachmentSentImageBytes,
  SaveClaimAttachment,
} from '../../../api/attachment/attachmentApiService'
import { ClaimsApiFactory } from '../../../api/claim/claimsApiFactory'
import { DetailFilterNames } from '../../../constants/general'
import { NotifyText } from '../../../constants/notifyText'
import api, { ClaimCarrierData } from '../../../utilities/api'
import {
  ErrorPNotify,
  formatInputDate,
  removeCommasInNumber,
  showMessage,
} from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { validateBirthday } from '../../../utilities/validators/birthdayValidators'
import { validateCob } from '../../../utilities/validators/cobValidators'
import { validateNpi } from '../../../utilities/validators/npiValidators'
import { validateProcedureCode } from '../../../utilities/validators/procedureCodeValidator'
import { validateTin } from '../../../utilities/validators/tinValidator'
import { validateZip } from '../../../utilities/validators/zipValidator'
import {
  cleanupAttachments,
  IAttachment,
  IAttachmentImage,
  IAttachmentRequirements,
  ProcedureCodeItem,
  ProcedureListObj,
} from '../../attachment/attachment.interface'
import { PNotifyText, XrayGenericImageTypes } from '../../attachment/constants'
import { ClaimErrorsType } from '../util/claimTypes'
import { initialClaim } from '../util/initialClaim'
import {
  ClaimStatusCodesExtended,
  mapClaimFieldErrors,
  mapStatusCodeDetails,
} from '../util/status-code-helpers'
import { useClaimActionContext } from './claimActionContext'
import { useClaimGridContext } from './claimGridContext'

interface SecondaryCoverageFields {
  CarrierId: string
  PaymentDate: string
  PatientLiability: string
  AdjustmentGroup: string
  AdjustmentReason: string
}
interface SecondaryCoverageRequirements {
  requiresAdjAmount: boolean
  requiresAdjGroupCode: boolean
  requiresAdjReasonCode: boolean
  requiresPatientLiability: boolean
  requiresPrimaryPaymentAmount: boolean
  requiresPrimaryPaymentDate: boolean
}

/** Claim details object from the api with some extra properties related to the details form */
export interface ClaimDetailsModelExtended extends ClaimDetailsModel {
  /** Local property, not part of the submitted claim xml */
  ClaimId?: number
  /** Local property, not part of the submitted claim xml */
  errors?: ClaimErrorsType[]
  /** Local property, not part of the submitted claim xml */
  ClearAttachmentRequirements?: boolean
  /** Local property, not part of the submitted claim xml */
  MappedClaimStatusCodes?: ClaimStatusCodes[]
}

//TODO: Update all the any to the correct type
type ClaimDetailContextType = {
  isCreatedFromSentAttachment: any
  isLegacyGlobal: boolean
  allowsPayorRefGlobal: any
  fullScreen: any
  setFullScreen: any
  claimCarrierData$: Observable<ClaimCarrierData>
  claim: ClaimDetailsModelExtended
  setClaim: Dispatch<SetStateAction<ClaimDetailsModelExtended>>
  getEmptyClaim: () => Promise<void>
  loading: boolean
  printingClaim: boolean
  printingEob: boolean
  printingEra: boolean
  printingClaimReceipt: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  secondaryCoverageRequirements: any
  setSecondaryCoverageRequirements: any
  attachment: any
  setAttachment: Dispatch<SetStateAction<IAttachment>>
  sentAttachments: any
  attachmentRequirements: any
  submitType: any
  setSubmitType: any
  supportsAttachment: any
  matchId: any
  attachmentCarrier: AttachmentCarrier
  setAttachmentCarrier: Dispatch<SetStateAction<AttachmentCarrier>>
  attachmentCarriers: AttachmentCarrier[]
  attachmentErrors: any
  isAttachmentRequirementsIgnored: any
  feeTotal: any
  setFeeTotal: any
  initialClaimState: ClaimDetailsModelExtended
  setInitialClaimState: Dispatch<SetStateAction<ClaimDetailsModelExtended>>
  payorRefId: any
  sending: any
  globalPayorRef: any
  globalNarrativeRef: any
  globalAttachmentImages: any
  claimDetailStatusCodes$: Observable<ClaimStatusCodes[]>
  attachmentNarrativeStatusCodeError$: Observable<{
    code: string
    fieldName: string
    message: string
  }>
  // refs
  refImageTypes: any
  // functions
  updateAttachmentCarrier: (
    carrierName: string,
    copy: ClaimDetailsModelExtended,
  ) => Promise<void>
  submitClaim: any
  getClaim: any
  updateClaim: any
  showClaim: any
  hideClaim: any
  printClaim: () => void
  printHtml: (html: string, type: string) => void
  updateCarrier: (
    carrierName: string,
    copy: ClaimDetailsModelExtended,
  ) => Promise<void>
  returnToClaims: any
  setAttachmentErrors: any
  validate: any
  validateRequirements: any
  ignoreAttachmentRequirements: any
  setAttachmentRequirementsIgnored: any
  setIsCreatedFromSentAttachment: any
  submitSolicitedAttachment: any
  setPayorRefId: any
  setGlobalPayorRef: any
  setGlobalNarrativeRef: any
  setGlobalAttachmentImages: any
}

const ClaimDetailContext = createContext<ClaimDetailContextType>(null)
const initialState = { carrier: '', legacy: false, matchId: '' }

export const ClaimDetailContextProvider = observer(({ children }: any) => {
  const auth = GlobalState.Auth.get()
  const userInfo = GlobalState.UserInfo.get()
  const facilityID = GlobalState.PracticeInfo.facilityID.get()

  const claimDetailStatusCodes$ = useObservable<ClaimStatusCodesExtended[]>()

  const [fullScreen, setFullScreen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [printingClaim, setPrintingClaim] = useState(false)
  const [printingEob, setPrintingEob] = useState(false)
  const [printingEra, setPrintingEra] = useState(false)
  const [printingClaimReceipt, setPrintingClaimReceipt] = useState(false)
  const [sending, setSending] = useState(false)
  const [submitType, setSubmitType] = useState<string>('')
  const [globalPayorRef, setGlobalPayorRef] = useState<string>('')
  const [globalNarrativeRef, setGlobalNarrativeRef] = useState<string>('')
  const [globalAttachmentImages, setGlobalAttachmentImages] =
    useState<string>(null)
  const [isCreatedFromSentAttachment, setIsCreatedFromSentAttachment] =
    useState(false)
  const [feeTotal, setFeeTotal] = useState<number>(0.0)
  const [claimCarrierId, setClaimCarrierId] = useState<string>(null)
  const [secondaryCoverageRequirements, setSecondaryCoverageRequirements] =
    useState<SecondaryCoverageRequirements>(null)
  const [initialSecondaryCoverageValues, setInitialSecondaryCoverageValues] =
    useState<SecondaryCoverageFields>({
      CarrierId: null,
      PaymentDate: null,
      PatientLiability: null,
      AdjustmentGroup: null,
      AdjustmentReason: null,
    })

  const [attachment, setAttachment] = useState<IAttachment>(null)
  const [payorRefId, setPayorRefId] = useState<IAttachment>(null)
  const [sentAttachments, setSentAttachments] = useState<IAttachment[]>([])
  const [attachmentRequirements, setAttachmentRequirements] =
    useState<IAttachmentRequirements>(null)
  const [attachmentErrors, setAttachmentErrors] = useState<number>(0)
  const [matchId, setMatchId] = useState<string>(null)
  const [allowsPayorRefGlobal, setAllowsPayorRefGlobal] = useState(false)
  const isLegacy = useRef<boolean>(false)
  const [isLegacyGlobal, setIsLegacyGlobal] = useState(false)
  const [supportsAttachment, setSupportsAttachment] = useState<boolean>(false)
  const [attachmentCarrier, setAttachmentCarrier] =
    useState<AttachmentCarrier>(null)
  const [isAttachmentRequirementsIgnored, setAttachmentRequirementsIgnored] =
    useState<boolean>()
  const [initialClaimState, setInitialClaimState] =
    useState<ClaimDetailsModelExtended>()
  const refImageTypes = useRef<ImageType[]>([])

  const claimCarrierData$ = useObservable<ClaimCarrierData>()
  const claimCarrierData: ClaimCarrierData = claimCarrierData$.get()

  const attachmentNarrativeStatusCodeError$ = useObservable<{
    code: string
    fieldName: string
    message: string
  }>()

  const flags = LDFlags$.get()

  const setIsLegacy = (val: boolean) => {
    isLegacy.current = val
    setIsLegacyGlobal(val)
  }

  const { unselectAll } = useClaimActionContext()
  const {
    ClaimState$,
    claim,
    claimId,
    carriers,
    claimRequest,
    setClaim,
    setDetailsTab,
    setClaimId,
    backToAllClaims,
    setIgnoreClaim,
    attachmentCarriers,
    getAttachmentCarrierList,
    statusCodes,
  } = useClaimGridContext()

  useEffect(() => {
    ;(async () => {
      if (matchId !== null) {
        await loadAttachmentRequirements()
        await loadAttachmentRequirementsIgnoredState()
      }
    })()
  }, [matchId, JSON.stringify(claim?.LineItems)])

  useEffect(() => {
    if (attachmentRequirements !== null && attachment !== null) {
      validateRequirements()
    }
  }, [attachment, attachmentRequirements])

  useEffect(() => {
    setTag('ClaimId', claimId)
    ;(async () => {
      if (claimId && claim?.CUSTOMER_ID) {
        await loadAttachmentRequirementsIgnoredState()
      }
    })()
  }, [claimId])

  useEffect(() => {
    if (claimCarrierId || claimCarrierData) handleCarrierChange()
  }, [claimCarrierId, claimCarrierData])

  const handleCarrierChange = () => {
    const carrierData = claimCarrierData
    const copy = { ...claim }

    if (carrierData) {
      copy.Carrier = carrierData.Carrier
      copy.CarrierAddress = carrierData.CarrierAddress
      copy.CarrierCity = carrierData.CarrierCity
      copy.CarrierId = carrierData.CarrierId
      copy.CarrierState = carrierData.CarrierState
      copy.CarrierZip = carrierData.CarrierZip
    }

    validateProperty(null, copy, 'CarrierAddress', null, true, 'patient')
    validateProperty(null, copy, 'CarrierCity', null, true, 'patient')
    validateProperty(null, copy, 'CarrierState', null, true, 'patient')
    validateProperty(validateZip, copy, 'CarrierZip', null, false, 'patient')

    if (
      flags.globalSecondaryClaimRequirements ||
      claimCarrierId === initialSecondaryCoverageValues.CarrierId
    ) {
      copy.PaymentDate = initialSecondaryCoverageValues?.PaymentDate
      copy.PatientLiability = initialSecondaryCoverageValues?.PatientLiability
      copy.AdjustmentGroup = initialSecondaryCoverageValues?.AdjustmentGroup
      copy.AdjustmentReason = initialSecondaryCoverageValues?.AdjustmentReason
    } else {
      copy.PaymentDate = null
      copy.PatientLiability = null
      copy.AdjustmentGroup = null
      copy.AdjustmentReason = null
    }

    if (!flags.globalSecondaryClaimRequirements) {
      getSecondaryCoverageRequirements(claimCarrierId)
    }

    setClaim({ ...claim, ...copy })
  }

  const getEmptyClaim = async () => {
    setLoading(true)

    const copy = { ...initialClaim }
    copy.LineItems = []
    copy.CUSTOMER_ID = userInfo.globalCustomerID
    copy.USER_ID = userInfo.customerUserID
    copy.NEA_FACILITY_ID = facilityID
    copy.SIGNATURE = 'SIGNATURE ON FILE'
    copy.ASSIGNMENT_SIGNATURE = 'SIGNATURE ON FILE'

    setInitialClaimState(copy)
    setClaim(copy)

    setDetailsTab('Patient')

    await getAttachmentCarrierList()

    await handleLoadAttachments(copy).finally(() => {
      setLoading(false)
    })
  }

  const updateClaimDetailStatus = (claimDetail: ClaimDetailsModelExtended) => {
    const match = getStatusOverride(claimDetail?.STATUS)
    if (match) {
      claimDetail.STATUS = match.Status
    }
  }

  const getClaim = async () => {
    setLoading(true)

    await GetClaim(claimId).then(async ({ data: responseData }) => {
      const data: ClaimDetailsModelExtended = responseData
      updateClaimDetailCarrierState(data)
      updateClaimDetailStatus(data)
      updateInitialClaimDetailState(data)
      initialClaimDetailValidation(data)
      setInitialClaimState(_.cloneDeep({ ...claim, ...data }))

      await handleLoadAttachments(data).finally(() => {
        setLoading(false)
      })

      initialState.carrier = data.Carrier
      initialState.legacy = isLegacy.current
      initialState.matchId = matchId
    })
  }

  const updateClaimDetailCarrierState = (data: ClaimDetailsModelExtended) => {
    if (data.CarrierId) setClaimCarrierId(data.CarrierId?.trim())
    setInitialSecondaryCoverageValues({
      CarrierId: data.CarrierId?.trim(),
      PaymentDate: data.PaymentDate,
      PatientLiability: data.PatientLiability,
      AdjustmentGroup: data.AdjustmentGroup,
      AdjustmentReason: data.AdjustmentReason,
    })
  }

  const updateInitialClaimDetailState = (data: ClaimDetailsModelExtended) => {
    // NOTE: needed until backend logic is updated to support CHILD instead of changing to DEP (GitLab claims issue 549)
    if (data.RELATIONSHIP === 'DEP') data.RELATIONSHIP = 'CHILD'
    if (data.COB_RELATIONSHIP === 'DEP') data.COB_RELATIONSHIP = 'CHILD'

    data.ClaimId = claimId
    data.Username = userInfo.userName
    data.PatientBirthDate = formatInputDate(data.PatientBirthDate)
    data.SubscriberBirthDate = formatInputDate(data.SubscriberBirthDate)
    data.CobBirthDate = formatInputDate(data.CobBirthDate)
    data.Resend = !data.Queued
    data.LineItems.map((item: any) => {
      item.ProcedureDate = formatInputDate(item.ProcedureDate)
    })
    claimCarrierData$.set(null)
    const parsedFeeTotal = parseFloat(data.FeeTotal)
    setFeeTotal(parsedFeeTotal ? parsedFeeTotal : 0)
  }

  const initialClaimDetailValidation = (data: ClaimDetailsModelExtended) => {
    data.errors = []
    //clear attachment reqs unless procedure codes are changed
    data.ClearAttachmentRequirements = true

    //Map the status codes attached to the claim to related details
    const validationResults =
      data?.VALIDATION_RESULTS &&
      (JSON.parse(data.VALIDATION_RESULTS) as ClaimValidationCode[])

    const mappedStatusCodes = flags?.claimValidation
      ? mapStatusCodeDetails(validationResults, statusCodes)
      : null
    claimDetailStatusCodes$.set(mappedStatusCodes)

    // Existing validation using validateProperty
    validate(data)

    // Validation using status codes from Tesia
    mappedStatusCodes &&
      mapClaimFieldErrors(
        data,
        claim,
        setClaim,
        mappedStatusCodes,
        attachmentNarrativeStatusCodeError$,
      )
  }

  const getSecondaryCoverageRequirements = async (carrierId: string) => {
    await api
      .getSecondaryCoverageRequirements(carrierId)
      .then(async ({ data }) => {
        setSecondaryCoverageRequirements(data.secondaryClaimReqs)
      })
  }

  const handleLoadAttachments = async (data: any) => {
    await getCarrier(data)
    await getAttachmentCarrierList()
    await loadImageTypes()
    await allSettled([loadAttachments(data), loadSentAttachments(data)])
  }

  //TODO: move this to the claimGrid context
  async function loadImageTypes() {
    if (!refImageTypes?.current || refImageTypes.current.length == 0) {
      await GetImageTypes()
        .then((response) => {
          refImageTypes.current = response.data
        })
        .catch((error) => {
          ErrorPNotify(PNotifyText.imageTypesError)
        })
    }
  }

  const allSettled = (promises: Promise<void>[]) =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value) => ({ state: 'fulfilled', value }))
          .catch((reason) => ({ state: 'rejected', reason })),
      ),
    )

  const loadAttachments = async (data: any) => {
    const id = parseInt(claimId)
    let getClaimAttachmentResult: AxiosResponse<AttachmentResponse> = null

    try {
      getClaimAttachmentResult = await GetClaimAttachment(
        id,
        isLegacy.current,
        data.CUSTOMER_ID,
      )
    } catch (error) {
      LogError(error)
    }

    if (getClaimAttachmentResult) {
      const attachmentImagePromises = getClaimAttachmentResult.data.images.map(
        (image) => {
          return GetClaimAttachmentImageBytes(
            getClaimAttachmentResult.data.attachmentId,
            image,
            data.CUSTOMER_ID,
          )
        },
      )
      const attachmentImageResults = await Promise.all(attachmentImagePromises)
      const attachment: IAttachment = {
        isSentAttachment: false,
        attachmentId: getClaimAttachmentResult.data.attachmentId,
        narrative: getClaimAttachmentResult.data.narrative,
        attachmentImages: attachmentImageResults,
      }

      setAttachment(attachment)
    } else {
      const newAttachment: IAttachment = {
        attachmentImages: new Array<IAttachmentImage>(),
        isSentAttachment: false,
        attachmentNumber: null,
        attachmentRecordID: null,
        attachmentId: null,
        narrative: null,
        payorRefId: null,
      }

      setAttachment(newAttachment)
    }
  }

  const loadSentAttachments = async (data: any) => {
    const id = parseInt(claimId)
    let getClaimAttachmentResult = null
    try {
      getClaimAttachmentResult = await GetSentAttachments(id, data.CUSTOMER_ID)
    } catch (error) {
      LogError(error)
    }
    const sentAttachList: IAttachment[] = []

    for (const item of getClaimAttachmentResult.data.sentAttachmentList) {
      let results: any[]
      const promises = item.images.map((image) => {
        return GetClaimAttachmentSentImageBytes(
          item.attachmentNumber,
          image,
          image.isLegacy,
          data.CUSTOMER_ID,
        )
      })
      results = await Promise.all(promises)

      const attachment: IAttachment = {
        isSentAttachment: true,
        sentDate: item.sentDate,
        isNeaNumber: item.isNeaNumber,
        attachmentNumber: item.attachmentNumber,
        narrative: item.narrative,
        payorRefId: item.payorRefId,
        attachmentImages: results,
      }
      sentAttachList.push(attachment)
    }
    setSentAttachments(sentAttachList)
  }

  const getCarrierByRefCarrierRequest = async (
    refCarrierRequest: RefCarrierRequest,
  ) => {
    let attMatchId: string = null
    let attSupported: boolean = false
    let attIsLegacy: boolean = false
    let allowsPayorRef: boolean = false

    try {
      await GetCarrierMatch(claimId, refCarrierRequest)
        .then((response) => {
          if (
            response != null &&
            response.data != null &&
            response.data.neaMasterId != null
          ) {
            attIsLegacy = false
            attMatchId = response.data.neaMasterId
            attSupported = true
            allowsPayorRef = response.data.allowsPayorRef
          } else if (response.data.carrierId != null) {
            attIsLegacy = true
            attMatchId = response.data.carrierId
            attSupported = true
          }
        })
        .catch((error) => {
          LogError(error)
        })
    } catch (error) {
      LogError(error)
    }

    setIsLegacy(attIsLegacy)
    setMatchId(attMatchId)
    setSupportsAttachment(attSupported)
    setAllowsPayorRefGlobal(allowsPayorRef)
  }

  const getCarrier = async (data: any) => {
    const refCarrierRequest: RefCarrierRequest = {
      carrierName: data.Carrier,
      carrierAddress: data.CarrierAddress,
      carrierCity: data.CarrierCity,
      carrierState: data.CarrierState,
      carrierZip: data.CarrierZip,
    }

    await getCarrierByRefCarrierRequest(refCarrierRequest)
  }

  const loadAttachmentRequirements = async () => {
    const id = claimId ? parseInt(claimId) : 0

    let procedureList: ProcedureCodeItem[] = []
    if (claim?.LineItems?.length > 0) {
      procedureList = claim.LineItems.map((e: any) => {
        return {
          procedureCode: e.ProcedureCode,
          toothNumber: e.ToothNumber,
        }
      })
    }
    const procedureListReq: ProcedureListObj = {
      procedureCodeList: procedureList,
    }

    try {
      if (matchId) {
        const attachmentRequirementsResponse = await GetAttachmentRequirements(
          id,
          procedureListReq,
          matchId,
          isLegacy.current,
        )
        const tmpAttachmentRequirements: IAttachmentRequirements = {
          attachmentRequirements:
            attachmentRequirementsResponse.data.attachmentRequirements,
          attachmentImageTypeRequirements:
            attachmentRequirementsResponse.data.imageTypesRequired,
        }
        setAttachmentRequirements(tmpAttachmentRequirements)
        return tmpAttachmentRequirements
      }
    } catch (error) {
      LogError(error)
    }
    return null
  }

  const updateClaim = (field: string, value: any) => {
    setClaim({ ...claim, [field]: value })
  }

  const showClaim = () => {
    toggleClaim(true)
  }

  const hideClaim = () => {
    toggleClaim(false)
  }

  const toggleClaim = (visible: boolean) => {
    setLoading(true)
    const hidden = !visible
    const req = claimRequest()
    req.ClaimList = [claimId]
    req['MarkAsVisible'] = visible
    req['Hidden'] = hidden
    ClaimsApiFactory()
      .IgnoreClaims(req)
      .then(() => {
        setIgnoreClaim(hidden)
        const message = hidden
          ? NotifyText.hideClaimSuccess
          : NotifyText.showClaimSuccess
        showMessage(message, 'success')
      })
      .catch(() => {
        const message = hidden
          ? NotifyText.markAsHiddenError
          : NotifyText.markAsVisibleError
        showMessage(message)
      })
  }

  const printClaim = () => {
    setPrintingClaim(true)

    PrintClaimAdaForm(claimId)
      .then(({ data }) => {
        const w = window.open('')
        if (w) {
          w.document.write(data.ImageSrc)
          w.document.close()
          w.focus()
          setTimeout(function () {
            w.print()
          }, 100)
        }
      })
      .catch((e) => {
        LogError(e)
        showMessage(Errors.claimPrintError)
      })
      .finally(() => {
        setPrintingClaim(false)
      })
  }

  const printHtml = (html: string, type: string) => {
    const handler =
      type === DetailFilterNames.eob
        ? setPrintingEob
        : DetailFilterNames.claimReceipt
          ? setPrintingClaimReceipt
          : setPrintingEra
    handler(true)
    const w = window.open('', '', 'height=500, width=500')
    w.document.write(html)
    w.document.close()
    w.print()
    handler(false)
  }

  const updateCarrier = async (
    value: string,
    copy: ClaimDetailsModelExtended,
  ) => {
    if (!value) return

    if (value.includes('|')) value = value.split('|')[0].trim()

    const match = carriers.filter(
      (c: any) => c.Carrier.toLowerCase().trim() === value.toLowerCase().trim(),
    )[0]

    if (match) {
      if (match.CarrierId) {
        setClaimCarrierId(match.CarrierId.trim())
        copy.CarrierId = match.CarrierId.trim()
      }

      copy.CarrierAddress = match.CarrierAddress
      copy.CarrierCity = match.CarrierCity
      copy.CarrierState = match.CarrierState
      copy.CarrierZip = match.CarrierZip
      validateProperty(null, copy, 'CarrierAddress', null, true, 'patient')
      validateProperty(null, copy, 'CarrierCity', null, true, 'patient')
      validateProperty(null, copy, 'CarrierState', null, true, 'patient')
      validateProperty(validateZip, copy, 'CarrierZip', null, false, 'patient')

      const refCarrierRequest: RefCarrierRequest = {
        carrierName: match.Carrier,
        carrierAddress: match.CarrierAddress,
        carrierCity: match.CarrierCity,
        carrierState: match.CarrierState,
        carrierZip: match.CarrierZip,
      }

      await getCarrierByRefCarrierRequest(refCarrierRequest)
      if (
        initialState.carrier != refCarrierRequest.carrierName &&
        initialState.legacy != isLegacy.current &&
        attachment?.attachmentId
      ) {
        showMessage(NotifyText.carrierUpdateAttachmentError)
        setAttachment(null)
      }
    } else {
      setSupportsAttachment(false)
      setAttachmentErrors(0)
    }
  }

  function carrierDisplay(c: AttachmentCarrier): string {
    return c?.carrierName?.trim() + ' - ' + c?.carrierId?.trim()
  }

  const updateAttachmentCarrier = async (
    value: string,
    copy: ClaimDetailsModelExtended,
  ) => {
    if (!value) return

    const match = attachmentCarriers.filter(
      (c: AttachmentCarrier) => carrierDisplay(c) === value.trim(),
    )[0]

    if (match) {
      //we don't need to run the getCarrierByRefCarrierRequest because we already have that info
      if (match.neaMasterId != null) {
        setIsLegacy(false)
        setMatchId(match.neaMasterId)
      } else if (match.carrierId != null) {
        setIsLegacy(true)
        setMatchId(match.carrierId)
      }
      setSupportsAttachment(true)

      //set the carrier data so it triggers an update of the patient tab's data

      const newCarrierData: ClaimCarrierData = {
        Carrier: match.carrierName,
        CarrierAddress: match.carrierAddress,
        CarrierCity: match.carrierCity,
        CarrierState: match.carrierState,
        CarrierZip: match.carrierZip,
        CarrierId: match.carrierId,
      }
      claimCarrierData$.set(newCarrierData)
    } else {
      setSupportsAttachment(false)
    }
  }

  const returnToClaims = async () => {
    unselectAll(false)
    cleanupAttachments()
    setIsCreatedFromSentAttachment(false)
    setInitialClaimState(undefined)
    setSecondaryCoverageRequirements(null)
    setClaimCarrierId(null)
    cleanupAttachmentState()
    setSupportsAttachment(false)
    claimDetailStatusCodes$.set([])
    backToAllClaims()
  }

  const cleanupAttachmentState = () => {
    setMatchId(null)
    setIsLegacy(false)
    setAttachment(null)
    setSentAttachments(null)
    setAttachmentErrors(0)
    setAttachmentRequirements(null)
    setAttachmentRequirementsIgnored(null)
    setGlobalAttachmentImages(null)
    setGlobalPayorRef(null)
    setGlobalNarrativeRef(null)
  }

  const compareObjects = <T,>(obj1: T, obj2: T): string[] => {
    if (_.isEqual(obj1, obj2)) return []

    const differentProperties: string[] = []

    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (obj1[key] !== obj2[key]) {
          differentProperties.push(key)
        }
      }
    }

    return differentProperties
  }

  const handleClaimFieldUpdates = (
    claimStart: ClaimDetailsModelExtended,
    claimEnd: ClaimDetailsModelExtended,
  ): ClaimActivityLogFieldUpdate[] => {
    const fieldUpdates: ClaimActivityLogFieldUpdate[] =
      compareObjects<ClaimDetailsModelExtended>(claimStart, claimEnd)
        .filter((field) => {
          if (
            field !== 'errors' &&
            field !== 'StatusHistory' &&
            field !== 'ActivityLog' &&
            field !== 'LineItems'
          )
            return field
        })
        .map((field) => {
          return {
            FieldName: field,
          }
        })

    if (claimStart.LineItems.length && claimEnd.LineItems.length) {
      claimStart.LineItems.map((lineItem, index) => {
        if (claimEnd.LineItems[index]) {
          compareObjects<FullClaimDetails>(
            lineItem,
            claimEnd.LineItems[index],
          ).map((field) => {
            if (field !== 'SERVICE_DATE_String') {
              fieldUpdates.push({
                FieldName: field,
                LineItemIndex: index + 1,
              })
            }
          })
        }
      })
    }

    if (claimStart.LineItems.length > claimEnd.LineItems.length) {
      fieldUpdates.push({ FieldName: 'LineItems', UpdateType: 'Delete' })
    } else if (claimStart.LineItems.length < claimEnd.LineItems.length) {
      fieldUpdates.push({ FieldName: 'LineItems', UpdateType: 'Add' })
    }

    return fieldUpdates
  }

  const submitClaim = async (
    submission: string,
    holdClaim: boolean = false,
  ) => {
    setSubmitType(submission)
    const copy = { ...claim }
    setTag('SubmissionType', submission)

    // track field changes for activity log
    const changedFields = handleClaimFieldUpdates(initialClaimState, copy)
    copy.FieldUpdates = changedFields

    // VALIDATION
    // Validation for image grid for claim attachments
    const validAttachmentImageDates =
      attachment?.attachmentImages.length > 0
        ? validateAttachmentImageDates(attachment)
        : null
    const validAttachmentImageTypes =
      attachment?.attachmentImages.length > 0
        ? validateAttachmentImageTypes(attachment)
        : null

    if (
      attachment?.attachmentImages?.length > 0 &&
      (!validAttachmentImageDates || !validAttachmentImageTypes)
    ) {
      !validAttachmentImageDates &&
        showMessage('Date taken is required for each attachment image.')
      !validAttachmentImageTypes &&
        showMessage('Image type is required for each attachment image.')
      setSubmitType('')
      return
    }

    const valid: boolean = validate(claim)
    if ((submission === 'sending' || ClaimState$.isNewClaim.peek()) && !valid) {
      setSubmitType('')
      showMessage(NotifyText.claimValidationError)
      return
    }

    if (claim.LineItems?.length < 1) {
      setSubmitType('')
      showMessage(NotifyText.procedureCodeRequired)
      return
    }

    // May be handled already by claim.LineItems useEffect
    if (matchId !== null) {
      const attachmentReqObj = await loadAttachmentRequirements()
      if (
        attachmentReqObj?.attachmentRequirements !== null &&
        attachmentReqObj?.attachmentRequirements?.length > 0 &&
        attachment !== null
      ) {
        const invalidRequirementCount =
          validateRequirementsWithParams(attachmentReqObj)

        if (invalidRequirementCount > 0) {
          setSubmitType('')
          showMessage(NotifyText.attachmentRequirementsError)
          return
        }
      }
    }
    // END VALIDATION

    // Generate a new claim ID if we are coming from create claim view
    const newClaimId = !claimId ? await getNewClaimId(copy) : null
    if (newClaimId) {
      copy.CLAIM_ID = newClaimId
      copy.ClearAttachmentRequirements = true
      claim.ClearAttachmentRequirements = true
    }

    let result = await saveAttachment(newClaimId)
    if (!result) {
      setSubmitType('')
      showMessage(NotifyText.saveAttachmentError)
      return
    }

    result = await clearAttachmentReqs(newClaimId)

    if (!result) {
      setSubmitType('')
      showMessage(NotifyText.clearAttachmentReqsError)
      return
    }

    if (isAttachmentRequirementsIgnored) {
      const claimIdParsed = claimId ? parseInt(claimId) : newClaimId
      const res = await IgnoreAttachmentRequirements(
        claimIdParsed,
        claim.CUSTOMER_ID,
      )

      if (!res) {
        setSubmitType('')
        ErrorPNotify(PNotifyText.ignoreAttachmentRequirementsError)
        return
      }
    }

    copy.Process = submission === 'sending'
    copy.COB_AMOUNT = copy.COB_AMOUNT
      ? parseFloat(copy.COB_AMOUNT).toFixed(2)
      : null
    copy.PatientLiability = copy.PatientLiability
      ? parseFloat(copy.PatientLiability).toFixed(2)
      : '0.00'
    copy.AdjustmentAmount = setAdjustmentAmount(copy)
    copy.FeeTotal = parseFloat(
      removeCommasInNumber(feeTotal?.toString()) ?? '0',
    ).toFixed(2)
    copy.HoldClaim = holdClaim

    // NOTE: COB_RELATIONSHIP will not save unless CobFlag is Y
    if (copy.COB_RELATIONSHIP) copy.CobFlag = 'Y'

    // If creating a new claim
    if (newClaimId) result = await processClaim(copy, newClaimId)
    else result = await processClaim(copy)

    let message: string

    if (result) {
      message =
        submission === 'sending'
          ? NotifyText.processClaimSuccess
          : NotifyText.saveClaimSuccess
      showMessage(message, 'success')
      unselectAll(false)
      cleanupAttachments()
      cleanupAttachmentState()
      setIsCreatedFromSentAttachment(false)
      setInitialClaimState(undefined)
      backToAllClaims()
    } else {
      message =
        submission === 'sending'
          ? NotifyText.claimValidationError
          : NotifyText.saveClaimError
      showMessage(message)
    }

    setSubmitType('')
  }

  const submitSolicitedAttachment = async () => {
    setSending(true)
    // May be handled already by claim.LineItems useEffect
    if (matchId !== null) {
      const attachmentReqObj = await loadAttachmentRequirements()
      if (
        attachmentReqObj?.attachmentRequirements !== null &&
        attachmentReqObj?.attachmentRequirements?.length > 0 &&
        attachment !== null
      ) {
        const invalidRequirementCount =
          validateRequirementsWithParams(attachmentReqObj)

        if (invalidRequirementCount > 0) {
          setSending(false)
          showMessage(NotifyText.attachmentRequirementsError)
          return
        }
      }
    }
    let result = await saveAttachment()
    if (!result) {
      setSending(false)
      showMessage(NotifyText.saveAttachmentError)
      return
    }
    result = await submitSolicitedClaimAttachment()
    let message
    if (result) {
      message = NotifyText.sendSolicitedAttachmentSuccess
      showMessage(message, 'success')
      unselectAll(false)
      cleanupAttachments()
      cleanupAttachmentState()
      setIsCreatedFromSentAttachment(false)
      setInitialClaimState(undefined)
      backToAllClaims()
    } else {
      message = NotifyText.sendSolicitedAttachmentError
      showMessage(message)
    }
    setSending(false)
  }

  const setAdjustmentAmount = (copy: any) => {
    let adjustmentAmount: string = '0.00'
    if (feeTotal && copy.COB_AMOUNT && feeTotal > parseFloat(copy.COB_AMOUNT)) {
      const cobAmount: number = parseFloat(copy.COB_AMOUNT)
      adjustmentAmount = (feeTotal - cobAmount).toFixed(2).toString()
    }

    return adjustmentAmount
  }

  const saveAttachment = async (newClaimId?: number) => {
    return await SaveClaimAttachment(
      newClaimId?.toString() || claimId,
      attachment,
      auth,
      isLegacy.current,
      claim.CUSTOMER_ID,
    )
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const submitSolicitedClaimAttachment = async () => {
    return await ClaimsApiFactory(false, true)
      .SubmitSolicitedClaimAttachment(claimId, claim.CUSTOMER_ID)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const clearAttachmentReqs = async (newClaimId?: number) => {
    if (claim.ClearAttachmentRequirements === true) {
      return await ClearAttachmentRequirementsApi(
        newClaimId?.toString() || claimId,
        claim.CUSTOMER_ID,
      )
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    }

    return true
  }

  const getNewClaimId = async (request: any) => {
    let newClaimId: number

    try {
      newClaimId = await PostClaim(request).then(({ data }) => {
        return data
      })
    } catch (error) {
      LogError(error, 'Error retrieving claim id for new claim')
    }

    return newClaimId
  }

  const processClaim = async (
    request: ClaimDetailsModelExtended,
    newClaimId?: number,
  ) => {
    const response = await UpdateClaim(
      newClaimId ? newClaimId : claimId,
      request,
    )
      .then(() => {
        return true
      })
      .catch((error) => {
        const problemDetails = error?.response
          ?.data as ClaimSaveSubmitProblemDetails

        if (problemDetails) {
          const status = problemDetails.status
          const claimResponse = problemDetails.ClaimResponse

          if (
            status === 400 &&
            claimResponse &&
            claimResponse.ClaimValidationResult?.ValidationStatus === 'Failed'
          ) {
            const newValidationCodes = mapStatusCodeDetails(
              claimResponse.ClaimValidationResult?.ValidationCodes,
              statusCodes,
            )
            LogBreadcrumb(
              'Claim validation failed',
              'sendClaim',
              newValidationCodes,
              'debug',
            )

            claimDetailStatusCodes$.set(newValidationCodes)

            const newClaimId = claimResponse.ClaimId

            const lineItemsCopy = claim.LineItems?.map((lineItem) => {
              return { ...lineItem, CLAIM_ID: newClaimId }
            })

            const claimCopy = {
              ...claim,
              claimId: newClaimId,
              CLAIM_ID: newClaimId,
              lineitems: lineItemsCopy,
            }

            //reset the attachment to unsent so we don't end up with multiple attachment records per claim
            if (attachment) {
              const attachmentCopy: IAttachment = _.cloneDeep(attachment)
              attachmentCopy.isSentAttachment = false

              setAttachment(attachmentCopy)
            }

            setClaimId(newClaimId)
            mapClaimFieldErrors(
              claimCopy, //pass claimCopy for both it doesn't override the id/line items
              claimCopy,
              setClaim,
              newValidationCodes,
              attachmentNarrativeStatusCodeError$,
            )
          }
        }

        return false
      })
    return response
  }

  const validateCarrierMatch = (
    _carrier: string,
    data: ClaimDetailsModelExtended,
    _property: string,
  ) => {
    if (flags.claimValidation && !data.CarrierId) {
      return 'Please select a valid carrier.'
    }

    return
  }

  const validate = (data: ClaimDetailsModelExtended) => {
    // Patient Tab
    validateProperty(null, data, 'PatientName', null, true, 'patient')
    validateProperty(
      validateBirthday,
      data,
      'PatientBirthDate',
      null,
      true,
      'patient',
    )
    validateProperty(null, data, 'PatientAddress', null, true, 'patient')
    validateProperty(null, data, 'PatientCity', null, true, 'patient')
    validateProperty(null, data, 'PatientState', null, true, 'patient')
    validateProperty(validateZip, data, 'PatientZip', null, true, 'patient')
    validateProperty(null, data, 'SubscriberName', null, true, 'patient')
    validateProperty(
      validateBirthday,
      data,
      'SubscriberBirthDate',
      null,
      true,
      'patient',
    )
    validateProperty(null, data, 'SubscriberAddress', null, true, 'patient')
    validateProperty(null, data, 'SubscriberCity', null, true, 'patient')
    validateProperty(null, data, 'SubscriberState', null, true, 'patient')
    validateProperty(validateZip, data, 'SubscriberZip', null, false, 'patient')
    validateProperty(null, data, 'SubscriberSsn', null, true, 'patient')
    validateProperty(
      validateCarrierMatch,
      data,
      'Carrier',
      null,
      true,
      'patient',
    )
    validateProperty(null, data, 'CarrierAddress', null, true, 'patient')
    validateProperty(null, data, 'CarrierCity', null, true, 'patient')
    validateProperty(null, data, 'CarrierState', null, true, 'patient')
    validateProperty(validateZip, data, 'CarrierZip', null, false, 'patient')
    validateCob(null, data, null)

    // Procedures Tab
    validateLineItems(data)

    // Provider Tab
    validateProperty(null, data, 'BillingName', null, true, 'provider')
    validateProperty(null, data, 'BillingAddress', null, true, 'provider')
    validateProperty(null, data, 'BillingCity', null, true, 'provider')
    validateProperty(null, data, 'BillingState', null, true, 'provider')
    validateProperty(validateZip, data, 'BillingZip', null, false, 'provider')
    validateProperty(validateNpi, data, 'NpiGroup', null, false, 'provider')
    validateProperty(validateTin, data, 'Tin', null, true, 'provider')
    validateProperty(null, data, 'TreatingName', null, true, 'provider')
    validateProperty(null, data, 'TreatmentAddress', null, true, 'provider')
    validateProperty(null, data, 'TreatmentCity', null, true, 'provider')
    validateProperty(null, data, 'TreatmentState', null, true, 'provider')
    validateProperty(validateZip, data, 'TreatmentZip', null, false, 'provider')
    validateProperty(null, data, 'TreatingLicense', null, true, 'provider')
    validateProperty(
      validateNpi,
      data,
      'IndividualNpi',
      null,
      false,
      'provider',
    )

    setClaim({ ...claim, ...data })
    return !data.errors.length
  }

  const validateAttachmentImageDates = (attachment: IAttachment) => {
    let isValid = true
    const MissingDateError = {}

    try {
      attachment.attachmentImages?.forEach((image) => {
        if (image.isDeleted) {
          return
        }

        if (
          !image.imageDateTaken &&
          isAttachmentTypeXray(image.imageTypeId?.toString())
        ) {
          isValid = false
        }

        if (!isValid) throw MissingDateError
      })
    } catch (err) {
      if (err !== MissingDateError) throw err
    }

    return isValid
  }

  const isAttachmentTypeXray = (attachmentTypeId: string): boolean => {
    const types = refImageTypes.current
    const typeMatch = types?.find(
      (t) => t.imageTypeId.toString() === attachmentTypeId,
    )

    if (typeMatch && typeMatch.isXray) {
      return true
    }

    return false
  }

  const validateAttachmentImageTypes = (attachment: IAttachment) => {
    let isValid = true
    const MissingTypeError = {}

    try {
      attachment.attachmentImages?.forEach((image) => {
        if (image.isDeleted) {
          return
        }

        if (!image.imageTypeId || !image.imageTypeName) {
          isValid = false
        }

        if (!isValid) throw MissingTypeError
      })
    } catch (err) {
      if (err !== MissingTypeError) throw err
    }

    return isValid
  }

  const validateLineItems = (data: any) => {
    const lineItems = data['LineItems']
    lineItems.length &&
      lineItems.forEach((item: any, index: number) => {
        validateProperty(
          validateBirthday,
          data,
          [index, 'ProcedureDate'],
          'LineItems',
          false,
          'procedures',
        )
        validateProperty(
          validateProcedureCode,
          data,
          [index, 'ProcedureCode'],
          'LineItems',
          true,
          'procedures',
        )
      })
  }

  const validateRequirements = () => {
    validateRequirementsWithParams(attachmentRequirements)
  }

  const validateRequirementsWithParams = (attachmentRequirements?: {
    attachmentRequirements?: AttachmentRequirement[]
    attachmentImageTypeRequirements?: ImageType[]
  }) => {
    if (claim == null) {
      return 0
    }
    if (isAttachmentRequirementsIgnored) {
      setAttachmentErrors(0)
      return 0
    }
    // check attachment requirements when validation error and attachment is unsent
    // TODO - add check for claim field attachment errors
    else if (!attachment.isSentAttachment) {
      // if we dont have requirements we cant validate and we will just clear validation on change - maybe this needs to be moved
      if (
        !attachmentRequirements?.attachmentRequirements ||
        attachmentRequirements.attachmentRequirements.length == 0
      ) {
        setAttachmentErrors(0)
        return 0
      }

      let requirementsUnsatisfied = 0
      attachmentRequirements?.attachmentImageTypeRequirements?.forEach(
        (requirement) => {
          if (
            requirement.alias === 'NARRATIVE' &&
            !attachment.narrative &&
            !attachment.attachmentImages?.some(
              (x) => x.imageTypeName == 'Narrative' && !x.isDeleted,
            )
          ) {
            requirementsUnsatisfied++
            return
          }

          // generic image type check xray
          if (requirement.alias === 'XRAY') {
            const imageGenericRequirementMet =
              attachment.attachmentImages?.some(
                (x) =>
                  XrayGenericImageTypes.some((k) => k === x.imageTypeId) &&
                  !x.isDeleted,
              )
            if (!imageGenericRequirementMet) {
              requirementsUnsatisfied++
              return
            }
          }

          if (
            requirement.alias !== 'NARRATIVE' &&
            requirement.alias !== 'XRAY'
          ) {
            const imageRequirementMet = attachment.attachmentImages?.some(
              (x) => x.imageTypeId == requirement.imageTypeId && !x.isDeleted,
            )
            if (!imageRequirementMet) {
              requirementsUnsatisfied++
              return
            }
          }
        },
      )

      if (requirementsUnsatisfied === 0) {
        if (
          attachment.attachmentImages?.filter((x) => !x.isDeleted).length ===
            0 &&
          !attachment.narrative
        ) {
          requirementsUnsatisfied++
        }
      }

      if (
        requirementsUnsatisfied === 0 &&
        attachmentNarrativeStatusCodeError$.peek()
      )
        requirementsUnsatisfied++

      setAttachmentErrors(requirementsUnsatisfied)
      return requirementsUnsatisfied
    }
    return 0
  }

  const ignoreAttachmentRequirements = async () => {
    setAttachmentRequirementsIgnored(true)
    setAttachmentErrors(0)
    updateClaim('IgnoreAttachmentRequirements', true)
  }

  const loadAttachmentRequirementsIgnoredState = async () => {
    let ignore: boolean = false
    const claimIdParsed = parseInt(claimId)
    if (claimIdParsed) {
      await GetAttachmentRequirementsIgnoredState(
        claimIdParsed,
        claim.CUSTOMER_ID,
      )
        .then((response) => {
          ignore = response.data.ignoreAttachmentRequirements
        })
        .catch((error) => {
          LogError(error)
        })
    }

    setAttachmentRequirementsIgnored(ignore)
    if (ignore) {
      setAttachmentErrors(0)
    }
  }

  return (
    <ClaimDetailContext.Provider
      value={{
        // state,
        isCreatedFromSentAttachment,
        isLegacyGlobal,
        allowsPayorRefGlobal,
        fullScreen,
        setFullScreen,
        claimCarrierData$,
        claim,
        setClaim,
        loading,
        printingClaim,
        printingEob,
        printingEra,
        printingClaimReceipt,
        setLoading,
        secondaryCoverageRequirements,
        setSecondaryCoverageRequirements,
        attachment,
        setAttachment,
        sentAttachments,
        attachmentRequirements,
        submitType,
        setSubmitType,
        supportsAttachment,
        matchId,
        attachmentCarrier,
        setAttachmentCarrier,
        attachmentCarriers,
        attachmentErrors,
        isAttachmentRequirementsIgnored,
        feeTotal,
        setFeeTotal,
        initialClaimState,
        setInitialClaimState,
        payorRefId,
        sending,
        globalPayorRef,
        globalNarrativeRef,
        globalAttachmentImages,
        claimDetailStatusCodes$,
        attachmentNarrativeStatusCodeError$,
        // refs
        refImageTypes,
        // functions
        updateAttachmentCarrier,
        submitClaim,
        getClaim,
        getEmptyClaim,
        updateClaim,
        showClaim,
        hideClaim,
        printClaim,
        printHtml,
        updateCarrier,
        returnToClaims,
        setAttachmentErrors,
        validate,
        validateRequirements,
        ignoreAttachmentRequirements,
        setAttachmentRequirementsIgnored,
        setIsCreatedFromSentAttachment,
        submitSolicitedAttachment,
        setPayorRefId,
        setGlobalPayorRef,
        setGlobalNarrativeRef,
        setGlobalAttachmentImages,
      }}
    >
      {children}
    </ClaimDetailContext.Provider>
  )
})

export const useClaimDetailContext = () => {
  const context = useContext(ClaimDetailContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
