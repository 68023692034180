import { InfoCircleOutlined } from '@ant-design/icons'
import { Checkbox, Col, Form, Row, Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

import {
  GetClaimSettings,
  UpdateClaimSettings,
  UpdateSerialOption,
} from 'trellis:api/claim/claimApi'
import { showMessage } from 'trellis:utilities/general'

import { FormInput } from '../_siteWide/form/formInput'

import './settings.scss'

import { VyneButton, VyneSwitch } from '@vynedental/design-system'

import { LogError } from 'utils'

import {
  ClaimSettingsResponse,
  SerialOption,
  UpdateClaimSettingsRequest,
} from 'trellis:api/claim/claim-client'
import { NotifyText } from 'trellis:constants/notifyText'
import { GlobalState } from 'trellis:state/globalState'

const store = window.localStorage

const { TabPane } = Tabs

export const Settings = () => {
  const [data, setData] = useState<ClaimSettingsResponse>()

  useEffect(() => {
    GetClaimSettings()
      .then((response) => {
        GlobalState.ClaimSettings.set(response.data)
        setData(response.data)
      })
      .catch((ex) => {
        LogError(ex, 'Failed to get claim settings')
        showMessage(NotifyText.getInstallSettingsError)
      })
  }, [])

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title'>Settings</h1>
      <Tabs className='pt-100'>
        <TabPane
          key='Settings'
          tab='Filter Configuration'
        >
          <SettingsContent
            data={data}
            setData={setData}
          />
        </TabPane>
      </Tabs>
    </article>
  )
}

const SettingsContent = ({ data, setData }: any) => {
  if (!data) return null
  const dateSetting = store.getItem('historySetting')
  const [onlyComputer, setOnlyComputer] = useState(data.Isolate)
  const [date, setDate] = useState(dateSetting || '15')
  const [submitting, setSubmitting] = useState(false)

  const [form] = Form.useForm()
  form.setFieldsValue({ ...data })

  const submitInstallNames = () => {
    if (data.errors && data.errors.length) {
      showMessage('Please fix all install name errors.', 'error')
      return
    }

    setSubmitting(true)
    data.Serials.map(async (s: any) => {
      const req: SerialOption = {
        Serial_ID: s.Serial_ID,
        Serial_Nickname: s.Serial_Nickname,
      }
      await UpdateSerialOption(req).catch(() => {
        showMessage(
          'There was an error updating Install Names. Please try again later.',
          'error',
        )
        setSubmitting(false)
        return false
      })
    })
    showMessage('Successfully updated Install Names', 'success')
    setSubmitting(false)
  }

  const onFail = () =>
    showMessage('Please fix all install name errors.', 'error')

  const onIsolateChange = () => {
    const isolate = !onlyComputer
    setOnlyComputer(isolate)
    const request: UpdateClaimSettingsRequest = {
      Isolate: isolate,
    }

    UpdateClaimSettings(request)
      .then((response) => {
        GlobalState.ClaimSettings.set(response.data)
        showMessage(
          `Filter claims by install has been turned ${isolate ? 'on' : 'off'}`,
          'success',
        )
      })
      .catch(() =>
        showMessage(
          'There was an error updating the filter claims by install setting',
          'error',
        ),
      )
  }

  const getDateDisplay = (days: string) =>
    days === '0' ? 'Today' : days === '7' ? 'Last 7 Days' : 'Last 15 Days'

  const onDateChange = (days: string) => {
    setDate(days)
    store.setItem('historySetting', days)
    showMessage(
      `Default date filter has been set to ${getDateDisplay(days)}`,
      'success',
    )
  }

  return (
    <Row
      justify='center'
      className='mt-200'
    >
      <Col
        span={13}
        className='form-detail-section'
      >
        <Form
          form={form}
          onFinish={submitInstallNames}
          onFinishFailed={onFail}
        >
          <Row>
            <Col
              span={24}
              className='intall-names-table'
            >
              <div className='page-section'>
                <h3>Install Names</h3>
                <Row className='install-table-header-row'>
                  <Col span={8}>
                    <b>Serial</b>
                  </Col>
                  <Col span={8}>
                    <b>Computer Name</b>
                  </Col>
                  <Col span={8}>
                    <b>Install Name</b>
                  </Col>
                </Row>
                <Form.List name='Serials'>
                  {(fields: any) => (
                    <>
                      {fields.map((field: any, index: number) => (
                        <Row
                          key={index}
                          className={`form-row ${
                            index % 2 !== 0 ? 'row-gray' : ''
                          }`}
                        >
                          <Col
                            span={8}
                            className='pt-075'
                          >
                            {data.Serials[index]?.Serial_ID}
                          </Col>
                          <Col
                            span={8}
                            className='pl-075 pt-075'
                          >
                            {data.Serials[index]?.PC_Name}
                          </Col>
                          <Col
                            span={8}
                            className='pl-100'
                          >
                            <FormInput
                              name={[index, 'Serial_Nickname']}
                              noFloat
                              parentProperty='Serials'
                              label='Nickname'
                              element={data}
                              setElement={setData}
                              initialValue={
                                data.Serials[index]?.Serial_Nickname
                              }
                              width='99%'
                              required
                            />
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </div>
            </Col>
          </Row>
          <Row justify='end'>
            <VyneButton
              dataTestId='save-settings-button'
              htmlType='submit'
              loading={submitting}
              size='large'
              type='primary'
            >
              Save Changes
            </VyneButton>
          </Row>
        </Form>
        <Row className='mt-100'>
          <Col span={12}>
            <div className='page-section'>
              <h3>Filter Claims by Install</h3>
              <Row className='install-filter-options'>
                <Col
                  span={24}
                  onClick={() => onIsolateChange()}
                >
                  Only view claims from this computer
                  <VyneSwitch
                    dataTestId='settings-view-local-claims-switch'
                    className='ml-100'
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    defaultChecked={onlyComputer}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className='page-section'>
              <h3>
                Default Date Filter
                <Tooltip title={dateFilterInfo}>
                  <InfoCircleOutlined className='ml-050 fs-075' />
                </Tooltip>
              </h3>
              <Row className='install-filter-options'>
                <Col span={8}>
                  <Checkbox
                    checked={date === '0'}
                    onChange={() => onDateChange('0')}
                  >
                    Today
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={date === '7'}
                    onChange={() => onDateChange('7')}
                  >
                    Last 7 Days
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={date === '15'}
                    onChange={() => onDateChange('15')}
                  >
                    Last 15 Days
                  </Checkbox>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const dateFilterInfo = (
  <p>
    This setting determines the default date range that will be loaded into
    Claim History when you first view the page. Note: Once on the page, a user
    can set the date filter to view up to 90 days of claims.
  </p>
)

const content = <Settings />
