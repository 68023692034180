import { FormInstance } from 'antd/lib/form'
import { Dispatch, SetStateAction } from 'react'

import { FormError } from '../constants'

export const handleValuesChange = <FormFields>(
  allValues: FormFields,
  changedValues: { [key in keyof FormFields]: string },
  formErrors: FormError[],
  formInstance: FormInstance,
  setFormErrors: Dispatch<SetStateAction<FormError[]>>,
  setStateData: Dispatch<SetStateAction<FormFields>>,
  stateData: FormFields,
) => {
  const stateDataCopy = { ...stateData, ...allValues }
  setStateData({ ...stateDataCopy })
  formInstance.setFieldsValue({ ...stateData, ...allValues })

  formInstance
    .validateFields(Object.keys(changedValues))
    .catch(({ errorFields }: { errorFields?: [] }) => {
      const formErrorsCopy: FormError[] = [...formErrors]

      if (errorFields?.length) {
        errorFields.forEach(
          (fieldError: {
            errors: string[]
            name: string[]
            warning: string[]
          }) => {
            if (formErrorsCopy.length) {
              const match = formErrorsCopy.find(
                (formError: FormError) =>
                  formError.name[0] === fieldError.name[0],
              )
              if (!match) formErrorsCopy.push(fieldError)
            } else formErrorsCopy.push(fieldError)
          },
        )
      } else {
        formErrorsCopy.forEach((error, index) => {
          if (error.name[0] === Object.keys(changedValues)[0])
            formErrorsCopy.splice(index, 1)
        })
      }

      setFormErrors(formErrorsCopy)
    })
}

export const isEmptyValue = (value: unknown): boolean => {
  const type = typeof value
  if (type == 'string' && !(value as string)?.trim()) return true

  return !value
}
