import { Tooltip } from 'antd'

import { ClaimGridState } from '../claimTypes'
import { ClaimModelExtended } from '../context/claimGridContext'

import InfoIcon from 'trellis:assets/info-blue.svg?react'
import textFormattingStyles from 'trellis:styles/text-formatting.module.scss'

import '../claims.scss'

import { OfflineBoltOutlined } from '@mui/icons-material'

import { TrellisConstants } from 'trellis:utilities/trellisConstants'

export namespace ClaimColumnHelpers {
  const getStatusDescription = (
    descriptionOverrides: string[],
    row: ClaimModelExtended,
    alteredValue: string,
  ) => {
    const descriptionText = descriptionOverrides?.length
      ? descriptionOverrides.join(', ')
      : alteredValue

    const widthValue = `${
      descriptionOverrides?.join().length > 45 || alteredValue?.length > 45
        ? '90%'
        : 'fit-content'
    }`

    return (
      <div
        className={textFormattingStyles['truncate-text']}
        data-testid={`claim-${row.ClaimID}-status-description`}
        style={{ width: widthValue }}
      >
        {descriptionText}
      </div>
    )
  }

  // NOTE: length check is to only show tooltip if description is truncated in the grid
  // 45 is hard coded because there isn't a great way to check whether text is truncated
  const getTooltip = (
    descriptionOverrides: string[],
    row: ClaimModelExtended,
    alteredValue: string,
  ) => {
    const tooltip =
      descriptionOverrides?.join().length > 45 ||
      alteredValue?.length > 45 ||
      row?.StatusDescriptionTooltip ? (
        <div data-testid={`claim-${row.ClaimID}-status-tooltip`}>
          {descriptionOverrides?.length ? (
            descriptionOverrides.map((description, index) => (
              <div
                className='mb-050'
                key={`${row.ClaimID}-status-code-${index}`}
              >
                {description}
              </div>
            ))
          ) : (
            <>
              {alteredValue?.length > 45 && (
                <div className='mb-100'>{alteredValue}</div>
              )}
            </>
          )}
          {row.StatusDescriptionTooltip && (
            <div>{row.StatusDescriptionTooltip}</div>
          )}
        </div>
      ) : null

    return tooltip
  }

  export const getStatusDescriptionTooltip = (
    value: string,
    row: ClaimModelExtended,
    state: ClaimGridState,
  ) => {
    let alteredValue = ''
    if (value) {
      alteredValue = value
        .replace('NEA Attachment', 'NEA')
        .replace('please add', '')
        .replace(' for this claim', '')
        .replace(' for Claim.', '')
        .replace('Please verify information and try again.', '')
    }

    const descriptionOverrides: string[] = row.MappedClaimStatusCodes?.map(
      (code) => code.DescriptionOverride,
    )

    const description = getStatusDescription(
      descriptionOverrides,
      row,
      alteredValue,
    )

    const tooltip = getTooltip(descriptionOverrides, row, alteredValue)

    if (!description) return null

    if (!tooltip) return description

    if (state.ActiveTab === 'Unsent') {
      return (
        <Tooltip
          mouseEnterDelay={1}
          title={tooltip}
        >
          {description}
        </Tooltip>
      )
    }

    if (state.ActiveTab === 'Sent') {
      return (
        <div className='claim-columns__status-description'>
          {description}
          <Tooltip title={tooltip}>
            <InfoIcon />
          </Tooltip>
        </div>
      )
    }
  }

  export const renderCarrierName = (
    claim: ClaimModelExtended,
    realtimeLayoutUpdatesFlag: boolean | undefined,
  ) => {
    if (
      realtimeLayoutUpdatesFlag &&
      ((claim.Queued && claim.IsRealtime) || claim.HasEob)
    ) {
      return (
        <div
          className='claim-columns__carrier-name'
          data-testid={TrellisConstants.TestIds.Grid.carrierName}
        >
          <div className='claim-columns__carrier-name-icon claim-columns__carrier-name-icon-rt'>
            <OfflineBoltOutlined
              data-testid={TrellisConstants.TestIds.Grid.carrierRtIcon}
            />
          </div>
          {claim.Carrier}
        </div>
      )
    }
    return (
      <div data-testid={TrellisConstants.TestIds.Grid.carrierName}>
        {claim.Carrier}
      </div>
    )
  }
}
