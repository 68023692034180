import { observer } from '@legendapp/state/react'
import { Form } from 'antd'
import React from 'react'
import { PatternFormat } from 'react-number-format'

import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { spacesToProperty } from '../../../utilities/general'
import { validateProperty } from '../../../utilities/validators/baseValidator'
import { FormFloatLabel } from './formFloatLabel'
import { getError, getRules, handleFormChange } from './util'

export const FormMaskInput = observer(
  ({
    disabled,
    element,
    format,
    initialValue,
    label = null,
    mask = '_',
    name,
    noFloat = false,
    parentProperty = null,
    required = false,
    section = null,
    setElement,
    validator,
    width = '100%',
  }: any) => {
    const error = element && getError(name, element)

    const onChange = (e: any) => {
      const value = e.target.value
      const updatedElement = handleFormChange(
        name,
        parentProperty,
        value,
        element,
      )
      validateProperty(
        validator,
        updatedElement,
        name,
        parentProperty,
        required,
        section,
      )
      setElement(updatedElement)
    }

    const maskedInputContent = (
      <PatternFormat
        // Dynamic flag needed to style correctly for different themes
        // Should not be needed in SPA
        className={`${
          RoleHelper$.isTrellis.get()
            ? 'trellis-theme--input'
            : 'default-theme--input'
        } ant-input form-mask-input-content ${noFloat ? 'no-float' : ''}`}
        disabled={disabled}
        format={format}
        mask={mask}
        onChange={onChange}
        value={initialValue}
        width={width}
      />
    )

    let maskedInput

    if (noFloat) {
      maskedInput = maskedInputContent
    } else {
      maskedInput = (
        <FormFloatLabel
          label={label || spacesToProperty(name)}
          name={name}
          inputValue={initialValue}
        >
          {maskedInputContent}
        </FormFloatLabel>
      )
    }

    if (error) {
      return (
        <div className='form-item-validation-container'>
          <Form.Item
            name={name}
            validateStatus='error'
            help={error.message}
          >
            {maskedInput}
          </Form.Item>
        </div>
      )
    }

    return (
      <div className='form-item-validation-container'>
        <Form.Item
          name={name}
          rules={[!disabled && getRules(name, required)]}
          help={disabled && ''}
        >
          {maskedInput}
        </Form.Item>
      </div>
    )
  },
)
