import { observer } from '@legendapp/state/react'
import { ColumnsType } from 'antd/lib/table'
import { SortOrder } from 'antd/lib/table/interface'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import GlobalState from 'trellis:state/globalState'

import {
  AttachmentListItem,
  Carrier,
  SentAttachmentListItem,
} from '../../../api/attachment-standalone/attachment-standalone-client'
import {
  GetRecipientList,
  GetToken,
} from '../../../api/attachment-standalone/attachmentStandaloneApi'
import { GetPatientEligibilityInfoResponse } from '../../../api/patients/patients-client'
import { NotifyText } from '../../../constants/notifyText'
import { useGlobalContext } from '../../../context/GlobalContextProvider'
import { IAuthenticatedApiModel } from '../../../utilities/api'
import { showMessage } from '../../../utilities/general'
import {
  getSortedSentAttachments,
  getSortedUnsentAttachments,
  SentTableColumns,
  UnsentTableColumns,
} from '../AttachmentsGrid/_attachmentsGridUtilities'
import { PatientInfo } from '../AttachmentsTypes'

type AttachmentsContextType = {
  authentication: IAuthenticatedApiModel
  setAuthentication: Dispatch<SetStateAction<IAuthenticatedApiModel>>
  facilityId: string
  setFacilityId: Dispatch<SetStateAction<string>>
  vdsPatientResponse: GetPatientEligibilityInfoResponse
  setVdsPatientResponse: Dispatch<
    SetStateAction<GetPatientEligibilityInfoResponse>
  >
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
  patientInfo: PatientInfo
  setPatientInfo: Dispatch<SetStateAction<PatientInfo>>
  showingSettings: boolean
  setShowingSettings: Dispatch<SetStateAction<boolean>>
  sentAttachmentId: string
  setSentAttachmentId: Dispatch<SetStateAction<string>>
  printingAttachment: boolean
  setPrintingAttachment: Dispatch<SetStateAction<boolean>>
  getGridColumns: (
    isPrinting: boolean,
    sentDates: [Date, Date],
  ) => ColumnsType<AttachmentListItem> | ColumnsType<SentAttachmentListItem>
  getFullColumnList: () =>
    | ColumnsType<AttachmentListItem>
    | ColumnsType<SentAttachmentListItem>
  getDefaultColumns: () => string[]
  getSortedAttachments: (
    gridType: 'unsent' | 'sent',
    data: AttachmentListItem[] | SentAttachmentListItem[],
  ) => AttachmentListItem[] | SentAttachmentListItem[]
  visibleColumns: string[]
  setVisibleColumns: Dispatch<SetStateAction<string[]>>
  getStorageOrDefaultColumns: () => string[]
  attachmentId: number
  setAttachmentId: Dispatch<SetStateAction<number>>
  attachments: AttachmentListItem[]
  setAttachments: Dispatch<SetStateAction<AttachmentListItem[]>>
  sentAttachments: SentAttachmentListItem[]
  setSentAttachments: Dispatch<SetStateAction<SentAttachmentListItem[]>>
  gridLoading: boolean
  setGridLoading: Dispatch<SetStateAction<boolean>>
  carriers: Carrier[]
  unsentSortColumn: string
  setUnsentSortColumn: Dispatch<SetStateAction<string>>
  unsentSortOrder: SortOrder
  setUnsentSortOrder: Dispatch<SetStateAction<SortOrder>>
  sentSortColumn: string
  setSentSortColumn: Dispatch<SetStateAction<string>>
  sentSortOrder: SortOrder
  setSentSortOrder: Dispatch<SetStateAction<SortOrder>>
}

const store = window.localStorage

const AttachmentsContext = createContext<AttachmentsContextType>(null)

const AttachmentsContextProvider: FC<{ children: JSX.Element }> = observer(
  ({ children }) => {
    const { getTrellisProviderDetails: getTrellisProviderDetails } =
      useGlobalContext()

    const trellisAuthentication = GlobalState.Auth.get()

    const [authentication, setAuthentication] =
      useState<IAuthenticatedApiModel>()
    const [facilityId, setFacilityId] = useState<string>('')
    const [vdsPatientResponse, setVdsPatientResponse] =
      useState<GetPatientEligibilityInfoResponse>(null)
    const [activeTab, setActiveTab] = useState<string>('Unsent')
    const [patientInfo, setPatientInfo] = useState<PatientInfo>()
    const [visibleColumns, setVisibleColumns] = useState<any>(null)
    const [attachmentId, setAttachmentId] = useState<number>(null)
    const [sentAttachmentId, setSentAttachmentId] = useState<string>(null)
    const [attachments, setAttachments] = useState<AttachmentListItem[]>([])
    const [sentAttachments, setSentAttachments] = useState<
      SentAttachmentListItem[]
    >([])
    const [showingSettings, setShowingSettings] = useState(false)
    const [gridLoading, setGridLoading] = useState<boolean>(true)
    const [printingAttachment, setPrintingAttachment] = useState<boolean>(false)
    const [carriers, setCarriers] = useState<Carrier[]>([])
    const [unsentSortColumn, setUnsentSortColumn] = useState<string>('')
    const [unsentSortOrder, setUnsentSortOrder] = useState<SortOrder>(null)
    const [sentSortColumn, setSentSortColumn] = useState<string>('')
    const [sentSortOrder, setSentSortOrder] = useState<SortOrder>(null)

    const defaultUnsentColumns = [
      'createDate',
      'patientLastName',
      'datesOfService',
      'recipientName',
      'attachmentStatus',
    ]
    const defaultSentColumns = [
      'sentDate',
      'patientName',
      'serviceDateDisplay',
      'recipientName',
      'status',
      'hmfId',
    ]

    const loadContextValues = () => {
      if (trellisAuthentication?.AccessToken) {
        getTrellisProviderDetails()

        GetToken()
          .then(async ({ data }) => {
            setFacilityId(data.customerFacilityId)
            const faAuth = {
              ...trellisAuthentication,
              AccessToken: data.accessToken,
            }
            setAuthentication({
              ...trellisAuthentication,
              AccessToken: data.accessToken,
            })
            GlobalState.AttachmentWrapperAccessToken.set(data.accessToken)
          })
          .catch(() => {
            showMessage('Authentication failed.')
          })
      }
    }

    useEffect(() => {
      loadContextValues()
    }, [trellisAuthentication?.AccessToken])

    useEffect(() => {
      if (authentication?.AccessToken && facilityId) {
        GetRecipientList(facilityId, true)
          .then(({ data }) => {
            setCarriers(data)
          })
          .catch(() => showMessage(NotifyText.getCarriersError, 'error'))
      }
    }, [authentication?.AccessToken, facilityId])

    const getFullColumnList = () => {
      return activeTab === 'Unsent'
        ? UnsentTableColumns(true, null, setAttachmentId)
        : SentTableColumns(true, setSentAttachmentId)
    }
    const getDefaultColumns = () => {
      return activeTab === 'Unsent' ? defaultUnsentColumns : defaultSentColumns
    }

    const getStorageColumns = () => {
      const columnString = store.getItem(
        `attachment-${activeTab}-default-columns`,
      )
      return columnString ? JSON.parse(columnString) : null
    }

    const getStorageOrDefaultColumns = () => {
      const storeColumns: string[] = getStorageColumns()
      return storeColumns ? storeColumns : getDefaultColumns()
    }

    const getGridColumns = (isPrinting: boolean, sentDates: [Date, Date]) => {
      const displayColumns: string[] = visibleColumns
        ? visibleColumns
        : getStorageOrDefaultColumns()

      if (
        !isPrinting &&
        activeTab == 'Unsent' &&
        !displayColumns.includes('actions')
      ) {
        displayColumns.push('actions')
      }

      const allGridColumns: any =
        activeTab == 'Unsent'
          ? UnsentTableColumns(isPrinting, sentDates, setAttachmentId)
          : SentTableColumns(isPrinting, setSentAttachmentId)

      return displayColumns.reduce((acc, col) => {
        const match = allGridColumns.find(
          (gc: { dataIndex: string }) => gc.dataIndex === col,
        )
        return match ? [...acc, match] : acc
      }, []) // Reduce the displayColumns array to find matches in allGridColumns and return the resulting array in order
    }

    const getSortedAttachments = (
      gridType: 'unsent' | 'sent',
      data: AttachmentListItem[] | SentAttachmentListItem[],
    ) => {
      let sortedAttachments

      if (gridType === 'unsent') {
        !(unsentSortColumn || unsentSortOrder)
          ? (sortedAttachments = getSortedUnsentAttachments(
              'createDate',
              'descend',
              data as AttachmentListItem[],
            ))
          : (sortedAttachments = getSortedUnsentAttachments(
              unsentSortColumn,
              unsentSortOrder,
              data as AttachmentListItem[],
            ))
      } else {
        !(sentSortColumn || sentSortOrder)
          ? (sortedAttachments = getSortedSentAttachments(
              'sentDate',
              'descend',
              data as SentAttachmentListItem[],
            ))
          : (sortedAttachments = getSortedSentAttachments(
              sentSortColumn,
              sentSortOrder,
              data as SentAttachmentListItem[],
            ))
      }

      return sortedAttachments
    }

    return (
      <AttachmentsContext.Provider
        value={{
          authentication,
          setAuthentication,
          facilityId,
          setFacilityId,
          vdsPatientResponse,
          setVdsPatientResponse,
          activeTab,
          setActiveTab,
          patientInfo,
          setPatientInfo,
          showingSettings,
          setShowingSettings,
          sentAttachmentId,
          setSentAttachmentId,
          printingAttachment,
          setPrintingAttachment,
          getGridColumns,
          getFullColumnList,
          getDefaultColumns,
          getSortedAttachments,
          visibleColumns,
          setVisibleColumns,
          getStorageOrDefaultColumns,
          attachmentId,
          setAttachmentId,
          attachments,
          setAttachments,
          sentAttachments,
          setSentAttachments,
          gridLoading,
          setGridLoading,
          carriers,
          unsentSortColumn,
          setUnsentSortColumn,
          unsentSortOrder,
          setUnsentSortOrder,
          sentSortColumn,
          setSentSortColumn,
          sentSortOrder,
          setSentSortOrder,
        }}
      >
        {children}
      </AttachmentsContext.Provider>
    )
  },
)

export const useAttachmentsContext = () => {
  const context = useContext(AttachmentsContext)
  if (context === undefined)
    throw new Error('Context must be used within a Provider')

  return context
}

export default AttachmentsContextProvider
