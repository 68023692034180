import { useQuery } from '@tanstack/react-query'

import { GetLetterOfTimelyFiling } from 'trellis:api/claim/claimApi'

export const useGetLetterOfTimelyFiling = (claimId: number) => {
  return useQuery({
    queryKey: ['letter-of-timely-filing', { claimId }],
    queryFn: () => GetLetterOfTimelyFiling(claimId),
    staleTime: Infinity,
    enabled: claimId !== null,
    refetchOnWindowFocus: false,
  })
}
