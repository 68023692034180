export const NotifyText = {
  addProviderError:
    'The was a problem adding the provider. Please try again later.',
  addProviderSuccess: 'The provider was successfully added.',
  attachmentRequirementsError:
    'Attachment requirements for this claim are not met, please fix errors.',
  billingUpdateSuccess: 'Successfully updated billing information',
  carrierAddedAsFavorite: 'The carrier has been added as a favorite.',
  carrierPreferenceFail:
    'There was a problem adding the carrier as a favorite. Please try again later.',
  carrierRemovedAsFavorite: 'The carrier has been removed as a favorite.',
  carrierUpdateAttachmentError:
    'The attachment has been removed. Use the Attachments tab to add attachment info for the selected carrier.',
  claimValidationError: 'To Send or Save, please fix all claim errors.',
  clearAttachmentReqsError:
    'There was a problem clearing your attachment requirements. Please try again later.',
  deleteAllClaimsError:
    'There was a problem deleting these claims. Please try again later.',
  deleteClaimsError:
    'There was a problem deleting {0} claim(s). Please try again later.',
  deleteClaimsSuccess: 'Successfully deleted {0} claim(s).',
  deleteProviderError:
    'There was a problem deleting the provider. Please try again later.',
  deleteProviderSuccess: 'The provider was successfully deleted.',
  getAppointmentsError:
    'There was a problem getting appointments data. Please try again later.',
  getBillingInfoError:
    'There was a problem getting billing details. Please try again later.',
  getCarriersError:
    'There was a problem getting carrier information. Please try again later.',
  getClaimDetailsError:
    'There was a problem getting claim details. Please try again later.',
  getClaimsError:
    'There was a problem getting claim data. Please try again later.',
  getEligibilityPatientsError:
    'There was a problem getting patient information. Please try again later.',
  getEligibilityResponseError:
    'There was a problem getting the eligibility response. Please try again later.',
  getInstallSettingsError:
    'There was a problem getting claim data for your install. Please try again later.',
  getPatientEligibilityInfoError:
    'There was a problem getting patient eligibility information. Please try again later.',
  getPracticeInfoError:
    'There was a problem getting the practice information. Please try again later.',
  getPracticeManagementSystmesError:
    'There was a problem getting the Practice Management Systems information. Please try again later.',
  getProvidersError:
    'There was a problem retrieving your providers. Please try again later.',
  getStatementSettingsError:
    'There was a problem retrieving the statement settings. Please try again later.',
  hideClaimSuccess: 'Claim is now hidden.',
  ignoreAttachmentRequirementsError:
    'There was a problem ignoring attachment requirements. Please try again later.',
  installSettingsError:
    'Error retrieving this computers claim settings. Please try again later.',
  legalInfoUpdateSuccess: 'Successfully updated legal business information.',
  legalInfoNotRequired:
    'Unable to update Business Entity Info: no valid business entity to update',
  logoutError: 'There was an error logging out. Please try again later.',
  markAsHiddenError:
    'There was a problem hiding these claims. Please try again later.',
  markAsVisibleError:
    'There was a problem unhiding these claims. Please try again later.',
  noChanges: 'There are no changes to be updated.',
  noClaimsToDelete: 'There were no claims selected to delete',
  noEligibilityResponseFound: 'No patient verification response was found.',
  oneTimeHistoryError:
    'There was a problem getting One-time History data. Please try again later.',
  paymentError: 'Submitting payment failed, please fix errors.',
  practiceInfoUpdateSuccess: 'Successfully updated practice information.',
  practiceInfoUpdateError:
    'Submitting practice info failed, please fix errors.',
  practiceManagementSystemInfoError:
    ' Please update the missing information in your Practice Management System.',
  printClaimsError:
    'The requested amount of claims to be printed as a PDF is too large. Alternatively, click the Download icon for an immediate listing of claims that can be saved or printed.',
  procedureCodeRequired:
    'At least one procedure code is required to save or submit a claim.',
  processClaimSuccess: 'Successfully processed claim.',
  processClaimsError:
    'There was a problem processing your claims. Please try again later.',
  processedClaimsSuccess: 'claim(s) were processed.',
  processedClaimsSuccessQueue: 'Sending Claims...',
  processedClaimsFailure:
    'claim(s) will remain unsent until validation is passed or the hold is released.',
  processNoClaims: 'There are no claims to send.',
  saveClaimError:
    'There was a problem saving your claim. Please try again later.',
  saveAttachmentError:
    'There was a problem saving your attachments. Please try again later.',
  sendSolicitedAttachmentError:
    'There was a problem sending your attachment. Please try again later.',
  sendSolicitedAttachmentSuccess: 'Attachment has been sent successfully.',
  saveClaimSuccess: 'Claim has been saved successfully.',
  showClaimSuccess: 'Claim is now visible.',
  updateLegalInfoError:
    'There was a problem saving your legal business information. Please try again later.',
  updateStatementSettingsError:
    'There was a problem saving the statement settings. Please try again later.',
  updatePracticeInfoError:
    'There was a problem saving your practice information. Please try again later.',
  verifyClaimError: 'Save claim failed, please fix errors.',
  verifyPatientError: 'Verify patient failed, please fix errors.',
}
