import { FC } from 'react'

import ReadOnlyInput from './readOnlyInput'

import './structuredViewPlanAndPlanDetails.scss'

import { StructuredPlanDetails } from '../utilities/eligibilityTyping'

type StructuredViewPlanDetailsProps = {
  type: 'Plan Details'
  details: StructuredPlanDetails
}

const StructuredViewPlanDetails: FC<StructuredViewPlanDetailsProps> = ({
  type,
  details,
}) => {
  return (
    <section className='structured-plan-details'>
      <header className='structured-plan-details__header'>{type}</header>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Pays on, seat or prep.'
          text={details.pays_on || '--'}
          style={{ width: '100%' }}
        />
        <ReadOnlyInput
          label='Deductible Applies Preventative'
          text={
            details.preventative_deductible === undefined
              ? '--'
              : details.preventative_deductible
                ? 'Yes'
                : 'No'
          }
          style={{ width: '100%' }}
        />
      </article>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Downgrade Applies Diagnostic'
          text={
            details.diagnosis_downgrades === undefined
              ? '--'
              : details.preventative_deductible
                ? 'Yes'
                : 'No'
          }
          style={{ width: '100%' }}
        />
        <ReadOnlyInput
          label='Downgrades on Crowns'
          text={
            details.crown_downgrades === undefined
              ? '--'
              : details.preventative_deductible
                ? 'Yes'
                : 'No'
          }
          style={{ width: '100%' }}
        />
      </article>
      <article className='structured-plan-details__row'>
        <ReadOnlyInput
          label='Downgrades on Fillings'
          text={
            details.fillings_downgrades === undefined
              ? '--'
              : details.preventative_deductible
                ? 'Yes'
                : 'No'
          }
          style={{ width: '50%' }}
        />
      </article>
    </section>
  )
}

export default StructuredViewPlanDetails
