import { observer, Show, useObservable } from '@legendapp/state/react'
import { Tabs, TabsProps, VyneButton } from '@vynedental/design-system'
import { Col, Drawer, Dropdown, Input, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SelectInstance } from 'react-select'

import { Filter, StatusDisplay } from 'ui'

import { QuickFilterButton } from 'trellis:components/_siteWide/QuickFilterButton/QuickFilterButton'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import {
  sentClaimFilterOptions,
  sentClaimFilters,
  setQuickFilterClasses,
  unsentClaimFilterOptions,
} from 'trellis:constants/statusFilters'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { useClaimControlContext } from '../../context/claimControlContext'
import { useClaimGridContext } from '../../context/claimGridContext'
import { ClaimSettingsModal } from '../ClaimSettings/ClaimSettingsModal'
import { ColumnSelection } from '../columnSelection/columnSelection'

import './toolbar.scss'

import { observable } from '@legendapp/state'
import { MoreHorizOutlined } from '@mui/icons-material'

import { ClaimArchiveSearchModal } from '../ClaimArchiveSearch/ClaimArchiveSearchModal'
import useGridToolbarActions from './useGridToolbarActions'

const { Search } = Input

export const GridToolbar = observer(() => {
  const { state, setState, loading, isPmg } = useClaimGridContext()
  const { setActiveTab, searchTable } = useClaimControlContext()
  const [search, setSearch] = useState(state?.Filters?.Search)
  const [initialLoad, setInitialLoad] = useState(true)

  const showClaimColumnSelector$ = useObservable<boolean>(false)
  const showClaimSettingsModal$ = useObservable<boolean>(false)
  const showArchiveSearchModal$ = observable<boolean>(false)

  const quickFilterOptions = ['Accepted', 'Rejected']

  const ref = useRef<SelectInstance>(null)

  const viewIgnored = state.Filters?.Ignored

  const [individualItems, dropdownItems] = useGridToolbarActions(
    state.ActiveTab,
    isPmg,
    viewIgnored,
    {
      showClaimColumnSelector$,
      showClaimSettingsModal$,
      showArchiveSearchModal$,
    },
  )

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (!initialLoad) searchTable(search)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search])

  const onSearchChange = (e: any) => {
    setInitialLoad(false)
    setSearch(e.target.value || '')
  }

  const handleFilterSelect = (value: string) => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    if (!value) resetStatusFilter()
    else if (value === copy.Filters.Config['Status']) return
    else {
      copy.Filters.Config['Status'] = value
      setState(copy)
    }
  }

  const resetStatusFilter = () => {
    const copy = { ...state }
    copy.Key = ++copy.Key
    copy.Filters.CurrentPage = 1
    delete copy.Filters.Config['Status']
    setState(copy)
  }

  const getCurrentFilterOption = (currentFilter: string) => {
    const filterOptions =
      state.ActiveTab === 'Sent'
        ? sentClaimFilterOptions
        : unsentClaimFilterOptions

    const selectedFilter = filterOptions.find(
      (option) => option.value === currentFilter,
    )

    if (selectedFilter) {
      return {
        value: selectedFilter.value,
        title: selectedFilter.title,
        label: (
          <StatusDisplay
            status={selectedFilter.title as string}
            type='icon'
            getStatusOverride={getStatusOverride}
          />
        ),
      }
    }
  }

  const tabItems: TabsProps['items'] = [
    { label: 'Unsent', key: 'Unsent', disabled: loading },
    { label: 'Sent', key: 'Sent', disabled: loading },
  ]

  const hideGridTabs = RoleHelper$.Claims.HideGridTabs.get()

  return (
    <>
      <Row
        className={`claims-toolbar ${!hideGridTabs && 'toolbar-bottom-border'}`}
        justify='end'
        align='middle'
      >
        <Show if={!hideGridTabs}>
          {() => (
            <Col style={{ width: '105px' }}>
              <Tabs
                activeKey={state.ActiveTab}
                className='claim-tabs'
                items={tabItems}
                name='claim-grid-tabs'
                onChange={(key: string) => {
                  ref.current.clearValue()
                  setActiveTab(key)
                }}
              />
            </Col>
          )}
        </Show>
        <div
          style={{
            marginLeft: '2.5em',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Filter
            currentFilter={state?.Filters?.Config['Status']}
            filterByOptions={
              state.ActiveTab === 'Sent'
                ? sentClaimFilterOptions
                : unsentClaimFilterOptions
            }
            getCurrentFilterOption={getCurrentFilterOption}
            getStatusOverride={getStatusOverride}
            handleFilterSelect={handleFilterSelect}
            ref={ref}
          />
          {state.ActiveTab === 'Sent' &&
            quickFilterOptions.map((option) => (
              <QuickFilterButton
                key={`quick-filter-${option}`}
                currentStatusFilter={state?.Filters?.Config['Status']}
                filterOptions={sentClaimFilters}
                loading={loading}
                onStatusFilterClick={handleFilterSelect}
                quickFilter={option}
                setQuickFilterClasses={setQuickFilterClasses}
              />
            ))}
        </div>
        <Col className='claim-history-search'>
          <Search
            placeholder='Search'
            onChange={onSearchChange}
            value={search}
            allowClear
          />
        </Col>
        {individualItems.map((item, index) => (
          <Col
            key={index}
            className='grid-control-icon-container'
          >
            {item.button}
          </Col>
        ))}
        {dropdownItems.length > 0 && (
          <Col className='grid-control-icon-container'>
            <Dropdown
              menu={{
                items: dropdownItems,
                onClick: ({ key }) =>
                  dropdownItems.find((item) => item.key === key)?.onClick,
              }}
              placement='bottomRight'
            >
              <VyneButton
                className='grid-control-button'
                dataTestId='grid-toolbar-more-icon'
                icon={<MoreHorizOutlined />}
              />
            </Dropdown>
          </Col>
        )}
        <Drawer
          title='Select | Reorder Columns'
          placement='right'
          width={300}
          onClose={() => showClaimColumnSelector$.set(false)}
          open={showClaimColumnSelector$.get()}
        >
          <ColumnSelection
            showClaimColumnSelector$={showClaimColumnSelector$}
          />
        </Drawer>
      </Row>
      <ClaimSettingsModal showClaimSettingsModal$={showClaimSettingsModal$} />
      <ClaimArchiveSearchModal
        showArchiveSearchModal$={showArchiveSearchModal$}
      />
    </>
  )
})

//
