import React, { forwardRef } from 'react'
import Select, { SelectInstance } from 'react-select'

import { StatusDisplay } from '..'
import { FilterOptionProps, FilterProps } from './utils/filter-types'

export const Filter = forwardRef<SelectInstance, FilterProps>(
  (
    {
      currentFilter,
      filterByOptions,
      getCurrentFilterOption,
      getStatusOverride,
      handleFilterSelect,
    },
    ref,
  ) => {
    const getOptions = () => {
      const filteredOptions = filterByOptions.filter((option) => !option.hidden)

      const options = filteredOptions.map((option) => ({
        value: option.value,
        title: option.title,
        label: (
          <StatusDisplay
            status={option.title as string}
            type='icon'
            getStatusOverride={getStatusOverride}
          />
        ),
      }))
      return options
    }

    const getMaxOptionLength = () => {
      return Math.max(
        ...filterByOptions.map((option) => String(option.title)?.length),
      )
    }

    const calcWidth = () => {
      const maxLength = getMaxOptionLength()
      // Estimate width based on character count (considering an average width of a character)
      return `${maxLength}em`
    }

    return (
      <Select
        ref={ref}
        isClearable
        isSearchable
        name='vyne-status-filter'
        options={getOptions()}
        onChange={(newValue: unknown) =>
          handleFilterSelect((newValue as FilterOptionProps)?.value)
        }
        placeholder='Filter By'
        {...(getCurrentFilterOption && {
          value: getCurrentFilterOption(currentFilter) || null,
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: '32px',
            height: '32px',
            minWidth: '140px',
            width: calcWidth(),
            maxWidth: '300px',
            borderRadius: '4px',
            border: state.isFocused
              ? '1px solid var(--color-primary)'
              : baseStyles.border,
            '&:hover': {
              border: '1px solid var(--color-primary)',
              boxShadow: '0 0 0 1px var(--color-primary-hover)',
            },
            boxShadow:
              state.isFocused || state.menuIsOpen
                ? '0 0 0 1px var(--color-primary-hover)'
                : baseStyles.boxShadow,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: '32px',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: '32px',
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
          }),
          option: (styles, { isFocused, isSelected }) => {
            const color = 'var(--color-primary-hover)'
            return {
              ...styles,
              backgroundColor: isSelected
                ? color
                : isFocused
                  ? color
                  : undefined,
              color: 'black',
              ':active': {
                ...styles[':active'],
                backgroundColor: color,
              },
            }
          },
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 999,
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            display: state.hasValue ? 'none' : 'flex',
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: state.hasValue ? 'none' : 'flex',
          }),
        }}
      />
    )
  },
)

Filter.displayName = 'Filter'
