import { BellFilled } from '@ant-design/icons'
import { useObservable } from '@legendapp/state/react'
import { RangePicker } from '@vynedental/design-system'
import { Tooltip } from 'antd'
import { endOfDay, format, intervalToDuration, startOfDay } from 'date-fns'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'

import {
  getEligibilityStatusInfo,
  QueuedStatusSummary,
  QueuedStatusSummaryItem,
  StatusDisplay,
  SummaryCardsContainer,
  SummaryCardsContainerItem,
} from 'ui'

import {
  getAppointmentSummaryCardItems,
  handleActiveSummaryCard,
} from 'trellis:components/Eligibility/shared/utilities/eligibility-status-summary-helpers'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState from 'trellis:state/globalState'
import {
  appointmentRangePresets,
  DateRange,
  rangeThemeConfig,
} from 'trellis:utilities/datePickerUtilities'

import { SmartTable } from '../../../_siteWide/table/SmartTable'
import { ColumnType } from '../../../_siteWide/table/SmartTableTypes'
import {
  EligibilityPatients,
  EligibilityPatientsRequest,
} from '../../../../api/eligibility/eligibility-client/api'
import { GetAppointments } from '../../../../api/eligibility/eligibilityApi'
import { NotifyText } from '../../../../constants/notifyText'
import { showMessage } from '../../../../utilities/general'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../../shared/context/PatientEligibilityDetailContext'
import ActionColumnCell from '../shared/EligibilityActionCell/GenericActionCell'
import EligibilityRowActionsMenu from '../shared/EligibilityRowActionsMenu/EligibilityRowActionsMenu'

const defaultSearch = { PatientName: '' }

const AppointmentsGrid: FC<{ toolbar: JSX.Element }> = ({ toolbar }) => {
  const {
    activeTab,
    appointmentDateRange,
    appointmentState,
    loading,
    requiresTableRefresh,
    setRequiresTableRefresh,
    setAppointmentDateRange,
    setAppointmentState,
    setLoading,
    getStoredEligSummaryPreference,
    showAppointmentsSummary$,
  } = useEligibilityContext()
  const { handleGetPatient } = usePatientEligibilityDetailContext()

  // For Summary Cards \\
  const queuedStatusSummary$ = useObservable<QueuedStatusSummary>()
  const queuedStatusSummary = queuedStatusSummary$.get()
  const state$ = useObservable(appointmentState)
  const showAppointmentsSummary = showAppointmentsSummary$.get()
  const summaryCardItems = useObservable<SummaryCardsContainerItem[]>()
  const activeSummaryCard = useObservable<string | number>('default')

  // Break point to hide the summary cards below 1024px
  const handleSummaryCardVisibility = (event: MediaQueryListEvent) => {
    if (event.matches) showAppointmentsSummary$.set(getStoredEligSummaryPreference())
    else showAppointmentsSummary$.set(false)
  }

  useEffect(() => {
    const query = matchMedia('(min-width: 1024px)')
    query.addEventListener('change', handleSummaryCardVisibility)

    state$.onChange((newState) => {
      setAppointmentState(newState.value)
    })

    return () =>
      query.removeEventListener('change', handleSummaryCardVisibility)
  }, [])

  useEffect(() => {
    state$.set(appointmentState)
  }, [appointmentState.Key])

  useEffect(() => {
    handleActiveSummaryCard(appointmentState, activeSummaryCard)
  }, [appointmentState?.Filters.Config.Status])

  useEffect(() => {
    if (queuedStatusSummary?.summary) {
      handleStatusSummary()
    }
  }, [queuedStatusSummary])

  const handleStatusSummary = async () => {
    summaryCardItems.set(
      await getAppointmentSummaryCardItems(
        queuedStatusSummary,
        activeSummaryCard,
        state$,
      ),
    )
  }
  // End Summary Cards \\

  useEffect(() => {
    if (requiresTableRefresh) getAppointments()
  }, [requiresTableRefresh])

  const getAppointments = async () => {
    setLoading(true)

    const startDate = `${format(
      startOfDay(new Date(appointmentDateRange.dates[0])),
      'yyyy-MM-dd',
    )}`
    const endDate = `${format(
      endOfDay(new Date(appointmentDateRange.dates[1])),
      'yyyy-MM-dd',
    )}`

    const filters = {
      appointments: `${startDate}, ${endDate}`,
      Name:
        appointmentState.Filters.Config.PatientName ||
        appointmentState.Filters.Name ||
        undefined,
      Status:
        appointmentState.Filters.Config.Status ||
        appointmentState.Filters.Status ||
        undefined,
    }

    const appointmentsRequest: EligibilityPatientsRequest = {
      CurrentPage: appointmentState.Filters.CurrentPage,
      Filters: filters,
      PageSize: appointmentState.Filters.PageSize,
      SortColumn: {
        Column: appointmentState.Filters.SortColumn,
        Sort: appointmentState.Filters.SortDirection,
      },
    }

    await GetAppointments(appointmentsRequest)
      .then(({ data: response }) => {
        const copy = { ...appointmentState }
        copy.Data = response.Data
        copy.Filters.Options.Carriers = response.Carriers?.map(
          (carrier: string, index: number) => ({ key: index, value: carrier }),
        )
        copy.Filters.Options.Statuses = response.Statuses?.map(
          (status: string, index: number) => ({ key: index, value: status }),
        )
        copy.Summary = response?.Summary
          ? {
              Appointments: response.Summary.Appointments || 0,
              Eligible: response.Summary.Eligible || 0,
              NeedsAttention: response.Summary.NeedsAttention || 0,
              PendingPatientResponse:
                response.Summary.PendingPatientResponse || 0,
            }
          : undefined
        copy.Total = response.TotalCount

        const summaryItems: QueuedStatusSummaryItem[] = [
          { Status: 'Appointments', Count: response.Summary.Appointments },
          { Status: 'Eligible', Count: response.Summary.Eligible },
          { Status: 'NeedsAttention', Count: response.Summary.NeedsAttention },
          {
            Status: 'PendingPatientResponse',
            Count: response.Summary.PendingPatientResponse,
          },
        ]

        queuedStatusSummary$.set({
          summary: summaryItems,
          total: response.TotalCount,
        })

        setAppointmentState(copy)
      })
      .catch(() => showMessage(NotifyText.getAppointmentsError))
      .finally(() => {
        setRequiresTableRefresh(false)
        setLoading(false)
      })
  }

  const setDateFilter = (date: DateRange['dates']) => {
    const durationAsDays: number = intervalToDuration({
      start: date[0],
      end: date[1],
    }).days
    setAppointmentDateRange({
      dates: [startOfDay(date[0]), endOfDay(date[1])],
      numberOfDays: durationAsDays,
    })

    if (appointmentState) {
      const stateCopy = { ...appointmentState }
      stateCopy.Key = ++stateCopy.Key
      stateCopy.Filters.CurrentPage = 1
      setAppointmentState(stateCopy)
    }
  }

  const handleDateRangeSelect = (dates: DateRange['dates']) =>
    setDateFilter(dates)

  const PatientFormIcon = () => (
    <Tooltip
      title={'Patient Response'}
      placement='topLeft'
      trigger={['hover', 'click']}
      color={'var(--charcoal-400)'}
    >
      <BellFilled className={`status-icon-info`} />
    </Tooltip>
  )

  const columns: ColumnType[] = [
    {
      dataIndex: 'AppointmentDate',
      noFilter: true,
      width: 200,
      render: (value: string) => format(new Date(value), 'MM/dd/yyyy h:mm a'),
    },
    {
      dataIndex: ['PatientFirstName', 'PatientLastName', 'PatientUpdateForm'],
      noSort: true,
      onCell: (tableItem: EligibilityPatients) => ({
        onClick: () => handleGetPatient({ 
          PatientId: tableItem?.PatientId, 
          PatientFirstName: tableItem?.PatientFirstName, 
          PatientLastName: tableItem?.PatientLastName,
          Status: tableItem?.Status,
        }),
      }),
      render: (_value, tableItem: EligibilityPatients) => (
        <p className='cursor-pointer'>
          {tableItem.PatientFirstName} {tableItem.PatientLastName}{' '}
          {tableItem.PatientUpdateForm && <PatientFormIcon />}
        </p>
      ),
      title: 'Patient Name',
      width: 200,
    },
    {
      dataIndex: 'CarrierName',
      noSort: true,
      title: 'Carrier',
      type: 'auto',
      width: 200,
    },
    {
      dataIndex: 'Status',
      noSort: true,
      render: (value: string) => {
        const info = getEligibilityStatusInfo(
          value,
          GlobalState.EligibilityStatusMapping.peek(),
        )
        return (
          <StatusDisplay
            status={info.status}
            toolTip={info.toolTip}
            getStatusOverride={getStatusOverride}
          />
        )
      },
      width: 200,
    },
    {
      dataIndex: 'ResponseDate',
      noSort: true,
      title: 'Response Date',
      render: (value: string) => {
        return value && format(new Date(value), 'MM/dd/yyy')
      },
      type: 'range',
      width: 200,
    },
    {
      dataIndex: '',
      noSort: true,
      render: (_value, tableItem: EligibilityPatients) => (
        <ActionColumnCell tableItem={tableItem} />
      ),
      title: 'Action',
      type: 'range',
      width: 200,
    },
    {
      dataIndex: '',
      noSort: true,
      render: (_value, tableItem: EligibilityPatients, index: number) => (
        <EligibilityRowActionsMenu
          index={index}
          tableItem={tableItem}
        />
      ),
      width: 50,
    },
  ]

  const dateRangePicker = (
    <RangePicker
      allowClear={false}
      name='eligibility-appointments-range-picker'
      onChange={(dates, _dateStrings) =>
        dates[0] &&
        handleDateRangeSelect([dates[0].toDate(), dates[1].toDate()])
      }
      presets={appointmentRangePresets}
      theme={rangeThemeConfig}
      value={
        appointmentDateRange?.dates && [
          dayjs(appointmentDateRange.dates[0]),
          dayjs(appointmentDateRange.dates[1]),
        ]
      }
    />
  )

  return (
    <>
      <SummaryCardsContainer
        activeCard={activeSummaryCard}
        items={summaryCardItems.get()}
        testId='appointmentSummaryCardContainer'
        isVisible={showAppointmentsSummary}
      />
      <SmartTable
        columns={columns}
        datePicker={activeTab === 'appointments' ? dateRangePicker : null}
        defaultSearch={defaultSearch}
        getData={getAppointments}
        isFullViewport={true}
        loading={loading}
        rowKey={(tableItem: EligibilityPatients) =>
          `${tableItem.PatientFirstName}-${tableItem.PatientLastName}-${
            tableItem.AppointmentDate
          }-${Math.random()}`
        }
        setState={setAppointmentState}
        state={appointmentState}
        tableClass='appointments-table'
        toolbar={toolbar}
      />
    </>
  )
}

export default AppointmentsGrid
