import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingSpinner } from 'ui'
import { ErrorCodes, LogError } from 'utils'

import { logoutUser } from 'trellis:features/authentication/Login/utils/login-helpers'
import GlobalState from 'trellis:state/globalState'

const Logout: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const ldClient = useLDClient()

  useEffect(() => {
    setLoading(true)

    logoutUser(ldClient)
      .catch((error) => {
        if (
          error?.message != ErrorCodes.auth_refresh_failed &&
          error?.response?.status != 403
        ) {
          LogError(error, 'Error logging out')
        }
      })
      .finally(() => {
        setLoading(false)
        GlobalState?.IsAuthenticated?.set(false)
        navigate('/Account/Login', { replace: true })
      })
  }, [])

  return loading ? <LoadingSpinner /> : ''
}

export default Logout
