import { MenuOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { arrayMove, spacesToProperty } from 'trellis:utilities/general'

import { useClaimArchiveContext } from '../context/ClaimArchiveContext'
import { claimArchiveColumns } from '../utils/claim-archive-column-selector-list'

import 'trellis:components/claims/controls/columnSelection/columnSelection.scss'

import { VyneButton, VyneSwitch } from '@vynedental/design-system'

const store = window.localStorage

interface ClaimArchiveColumnSelectorProps {
  setShowingSelection: Dispatch<SetStateAction<boolean>>;
}

export const ClaimArchiveColumnSelector = ({ setShowingSelection }: ClaimArchiveColumnSelectorProps) => {
  const { state, setState, defaultColumns, storageColumns } =
    useClaimArchiveContext()
  const [items, setItems] = useState<any[]>([])

  const resetIds = (options: any[]) => {
    let counter = 0
    options.map((o: any) => {
      o.id = counter++
    })

    return options
  }

  useEffect(() => {
    let orderedColumns: any[] = []

    if (storageColumns) {
      storageColumns.map((d: any) => {
        const match = claimArchiveColumns.filter(
          (c: any) => c.property === d,
        )[0]
        match.show = true
        orderedColumns.push(match)
      })
      orderedColumns = orderedColumns.concat(
        claimArchiveColumns.filter((c: any) => !orderedColumns.includes(c)),
      )
    } else {
      orderedColumns = claimArchiveColumns
    }

    setItems(resetIds(orderedColumns))
  }, [])

  if (!items.length) return null

  const onChange = (property: number, show: boolean) => {
    const copy = [...items]
    const match = copy.filter((c) => c.property === property)[0]
    match.show = !show
    setItems(copy)
  }

  const setDefaultColumns = (columns: any) => {
    const copy = { ...state }
    copy.VisibleColumns = columns
    setState(copy)
    setShowingSelection(false)
    store.setItem('claim-archive-columns', JSON.stringify(columns))
  }

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let newOrder = arrayMove(items, oldIndex, newIndex)
    newOrder = resetIds(newOrder)
    setItems(newOrder)
  }

  const resetItems = () => {
    const values: any[] = []
    items.map((c: any) => {
      c.show = defaultColumns.includes(c.property)
      values.push(c)
    })

    resetIds(claimArchiveColumns)
    setItems(claimArchiveColumns)
    setDefaultColumns(defaultColumns)
    store.removeItem('claim-archive-columns')
  }

  const apply = () => {
    const values: any[] = []
    items.map((c: any) => c.show && values.push(c.property))
    setDefaultColumns(values)
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined className='column-menu-grab' />
  ))

  const SortableItem = SortableElement(({ property, label, show }: any) => {
    return (
      <Row className='column-select-row'>
        <Col>
          <DragHandle />
        </Col>
        <Col
          flex={1}
          className='pl-100'
        >
          {spacesToProperty(label)}
        </Col>
        <Col className='pl-100'>
          <VyneSwitch
            dataTestId={`claim-archive-column-${label}-switch`}
            checkedChildren='Yes'
            unCheckedChildren='No'
            checked={show}
            onChange={() => onChange(property, show)}
          />
        </Col>
      </Row>
    )
  })

  const SortableListContainer = SortableContainer(() => (
    <div>
      {items.map(({ id, property, label, show }: any) => (
        <SortableItem
          key={id}
          index={id}
          // @ts-expect-error TODO: fix this
          property={property}
          label={label}
          show={show}
        />
      ))}
      <Row className='claim-setting-actions'>
        <Col flex={1}>
          <VyneButton
            dataTestId='reset-claim-archive-column-selection-button'
            onClick={resetItems}
          >
            Reset
          </VyneButton>
        </Col>
        <Col>
          <VyneButton
            dataTestId='apply-claim-archive-column-selection-button'
            onClick={apply}
            type='primary'
          >
            Apply
          </VyneButton>
        </Col>
      </Row>
    </div>
  ))

  return (
    <SortableListContainer
      onSortEnd={onSortEnd}
      useDragHandle={true}
      lockAxis='y'
    />
  )
}
