const storageVariables = {
  Invoices: 'invoices',
  SelectedTin: 'selectedTin',
  RpReceipts: 'rpReceipts',
  tsepJson: 'tsep',
}

export const destroyStorage = (user: string) => {
  localStorage.removeItem('acknowledged_legal_business_info_alert')
  localStorage.removeItem('at_res')
  localStorage.removeItem('claim-grid-filters')
  localStorage.removeItem('jsoncache')
  localStorage.removeItem('lastUpdateDate')
  localStorage.removeItem('localPollDate')
  localStorage.removeItem('pd_res')
  localStorage.removeItem('pollDate')
  localStorage.removeItem('serialPoll')
  localStorage.removeItem('storedDateMax')
  localStorage.removeItem('storedDateMin')
  localStorage.removeItem('trellis-cust-hasPmsData')
  localStorage.removeItem('trellis-last-messages-call-time')
  localStorage.removeItem('trellis-total-unread-messages')
  localStorage.removeItem('ui_res')
  localStorage.removeItem('vdsHeaders')
  localStorage.removeItem('Pms-Sync-Status-Info')
  localStorage.removeItem(storageVariables.tsepJson)
  localStorage.removeItem(storageVariables.RpReceipts)
  localStorage.removeItem(storageVariables.Invoices)
  localStorage.removeItem(storageVariables.SelectedTin)

  if (user) {
    localStorage.removeItem(user + '-patient-grid-filters')
    localStorage.removeItem(user + '-claim-grid-filters')
  }
}




