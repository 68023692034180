import { ObservableObject, ObservablePrimitive } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { useQuery } from '@tanstack/react-query'
import { VyneButton } from '@vynedental/design-system'
import { FC } from 'react'

import { LogError } from 'utils'

import { StatementsQueryRequest } from 'trellis:api/statements/statements-client'
import {
  GetFilesCsv,
  GetStatementsCsv,
} from 'trellis:api/statements/statementsApi'
import { Errors } from 'trellis:constants/errors'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'
import { FileDownloadOutlined } from '@mui/icons-material'

type BillingDownloadButtonProps = {
  currentTab$: ObservablePrimitive<string>
  downloadTableRequest$: ObservableObject<StatementsQueryRequest>
}

export const BillingDownloadButton: FC<BillingDownloadButtonProps> = observer(
  ({ currentTab$, downloadTableRequest$ }) => {
    const authentication = GlobalState.Auth.peek()
    const currentTab = currentTab$.get()

    const downloadLink$ = useObservable<string>()

    const { data, error, isError } = useQuery({
      queryKey: ['billingDownloads', currentTab, downloadTableRequest$.get()],
      queryFn: () =>
        currentTab === 'Statements'
          ? GetStatementsCsv(authentication, downloadTableRequest$.peek())
          : GetFilesCsv(authentication, downloadTableRequest$.peek()),
    })

    if (data) downloadLink$.set(data.data.CsvLink)

    if (isError) {
      LogError(error)
      showMessage(Errors.getBillingDownloadsError)
    }

    return (
      <div>
        <a href={downloadLink$.get()}>
          <VyneButton
            dataTestId='billing-download-button'
            icon={<FileDownloadOutlined />}
          />
        </a>
      </div>
    )
  },
)
