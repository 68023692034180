import { InfoCircleFilled } from '@ant-design/icons'
import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { FC, useEffect } from 'react'

import GlobalState from 'trellis:state/globalState'

import styles from '../../downloads.module.scss'
import { usePearlDownload } from '../hooks/usePearlDownload'

import DownloadIcon from 'trellis:assets/cloud-download-icon.svg?react'

interface ImageSyncDownloadInstallerModal {
  isOpen: Observable<boolean>
  onClose: () => void
  onFailure: () => void
  imageSystemIdentifier: Observable<string>
}

export const ImageSyncDownloadInstallerModal: FC<ImageSyncDownloadInstallerModal> =
  observer(({ isOpen, onClose, onFailure, imageSystemIdentifier }) => {
    const { handleProvisioningFlow } = usePearlDownload()

    const isDownloading = useObservable<boolean>(false)

    useEffect(() => {
      isDownloading.set(false)
    }, [isOpen.get()])

    const downloadInstaller = async () => {
      isDownloading.set(true)
      try {
        const customerId = GlobalState.Auth.CustomerIdNumber.get()
        const success = await handleProvisioningFlow(
          customerId,
          imageSystemIdentifier.get(),
        )
        if (!success) {
          onFailure?.()
        } else {
          onClose()
        }
      } finally {
        isDownloading.set(false)
      }
    }

    return (
      <>
        <VyneModal
          dataTestId='image-sync-download-installer-modal'
          title='Download Image Sync'
          open={isOpen.get()}
          onCancel={onClose}
          footer={[
            <VyneButton
              dataTestId='cancel-image-sync-download-button'
              key='cancel'
              onClick={onClose}
            >
              Cancel
            </VyneButton>,
            <VyneButton
              dataTestId='download-image-sync-button'
              icon={
                <span
                  className={`anticon ${styles['download-item__icons--vertical-align']}`}
                >
                  <DownloadIcon />
                </span>
              }
              key='download'
              loading={isDownloading.get()}
              onClick={() => void downloadInstaller()}
              type='primary'
            >
              Download
            </VyneButton>,
          ]}
        >
          <div className='flex-column items-center text-center'>
            <InfoCircleFilled
              style={{
                color: 'var(--error-100, #f5222d)',
                fontSize: '2.5rem',
              }}
            />
            <p className='vyne-lg-text mt-075 mb-075'>
              <b>Important:</b> Image Sync is required to be installed{' '}
              <b>only on your imaging system server.</b>
            </p>
            <p style={{ color: 'var(--error-100, #f5222d)' }}>
              Image Sync will not function if installed on individual
              workstations.
            </p>
            <p>
              Please click download below to confirm you are downloading Image
              Sync on your server.
            </p>
          </div>
        </VyneModal>
      </>
    )
  })
