import type { MenuProps } from 'antd'

import {
  CloudDownloadOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MailOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  WarningFilled,
} from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { Dropdown } from 'antd'
import { differenceInMinutes } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { SubscriptionAlert } from 'ui'
import { LogError } from 'utils'

import { GlobalState, LDFlags$ } from 'trellis:state/globalState'
import api from 'trellis:utilities/api'
import { RoleHelper$ } from 'trellis:utilities/roleHelper'

import { usePostAuthLayoutContext } from '../../context/PostAuthLayoutContext'
import ContactSupport from './modals/ContactSupport'

import DownChevron from 'trellis:assets/down-chevron.svg?react'
import pendoIcon from 'trellis:assets/pendo-icon.png'
import rPracticeLogo from 'trellis:assets/rpractice.png'
import vyneTrellisLogo from 'trellis:assets/vyne-trellis-logo.png'

import './Header.scss'

// active services used in Header
export interface HeaderActiveServices {
  DENTAL_INTEL?: boolean
  TRELLIS_CLAIMS?: boolean
  EAGLESOFT?: boolean
  REMOTE_LITE?: boolean
}
export interface HeaderRules {
  headerClassModifier?: string
  showHeaderLogo?: boolean
  showRPracticeLogo?: boolean
  showTrellisLogo?: boolean
  showRemoteSupport?: boolean
}

export const getHeaderRules = (activeServices: HeaderActiveServices) => {
  const rules: HeaderRules = {
    headerClassModifier:
      activeServices.REMOTE_LITE && !activeServices.TRELLIS_CLAIMS
        ? 'header--rpractice'
        : 'header--trellis',
    showHeaderLogo: !activeServices.DENTAL_INTEL,
    showRPracticeLogo:
      activeServices.REMOTE_LITE && !activeServices.TRELLIS_CLAIMS,
    showTrellisLogo:
      !activeServices.DENTAL_INTEL && activeServices.TRELLIS_CLAIMS,
    // TODO: add anymore rules if needed
    showRemoteSupport: !activeServices.EAGLESOFT,
  }

  return rules
}

const Header: FC = observer(() => {
  // global state
  const activeServices = GlobalState.ActiveServices.get()
  const flags = LDFlags$.get()
  const practiceDetails = GlobalState.PracticeInfo.get()
  const userInfo = GlobalState.UserInfo.get()
  // context state
  const { setTotalUnreadMessages, totalUnreadMessages } =
    usePostAuthLayoutContext()
  // local state
  const [showingContactSupport, setShowingContactSupport] =
    useState<boolean>(false)
  const [PmsSyncExpired, setPmsSyncExpired] = useState<boolean>(false)

  const rules = getHeaderRules(activeServices)

  const getProfileName = (): string => {
    let name: string = userInfo.userName ? userInfo.userName : 'User Profile'
    if (userInfo?.firstName && userInfo?.lastName)
      name = `${userInfo.firstName} ${userInfo.lastName}`
    else if (userInfo?.userName) name = userInfo.userName

    return name
  }

  const profileName = getProfileName()

  useEffect(() => {
    if (location.pathname !== '/Messages') handleGetMessages()
    if (flags.vsPmsSyncStatus && flags.operaVynesyncAutomatedeligibility)
      checkPmsSyncStatus()

    const pendoImageBadge = document.querySelector('[id^="pendo-image-badge-"]')
    if (pendoImageBadge) pendoImageBadge.setAttribute('src', pendoIcon)
  }, [])

  const handleGetMessages = async () => {
    const storedTotalUnreadMessages: number = parseInt(
      JSON.parse(localStorage.getItem('trellis-total-unread-messages')),
    )
    if (storedTotalUnreadMessages >= 0)
      setTotalUnreadMessages(storedTotalUnreadMessages)

    const lastMessagesCallTime: string = localStorage.getItem(
      'trellis-last-messages-call-time',
    )

    let timeLapse: number = 0
    if (lastMessagesCallTime) {
      timeLapse = differenceInMinutes(
        new Date(),
        new Date(lastMessagesCallTime),
      )
    }

    if (!lastMessagesCallTime || timeLapse >= 30) getMessages()
  }

  const checkPmsSyncStatus = async () => {
    const pmsSyncStatusInfo = localStorage.getItem('Pms-Sync-Status-Info')

    if (pmsSyncStatusInfo) {
      const { dateChecked, pmsSyncIsExpired } = JSON.parse(pmsSyncStatusInfo)
      const lastChecked = new Date(dateChecked)

      // Check if the last checked time is within the last 10 minutes
      if (differenceInMinutes(new Date(), lastChecked) < 10) {
        setPmsSyncExpired(pmsSyncIsExpired)
        return
      }
    }

    getPmsSyncStatusFromApi()
  }

  const getPmsSyncStatusFromApi = async () => {
    await api
      .getPmsSyncStatus()
      .then(({ data }) => {
        const pmsSyncStatusInfo = JSON.stringify({
          dateChecked: new Date(),
          pmsSyncIsExpired: data.pmsSyncIsExpired,
        })

        localStorage.setItem('Pms-Sync-Status-Info', pmsSyncStatusInfo)
        setPmsSyncExpired(data.pmsSyncIsExpired)
      })
      .catch(() => {
        const pmsSyncStatusInfo = JSON.stringify({
          dateChecked: new Date(),
          pmsSyncIsExpired: false,
        })

        localStorage.setItem('Pms-Sync-Status-Info', pmsSyncStatusInfo)
      })
  }

  const getMessages = async () => {
    const getMessagesRequest = {
      startDate: new Date(),
      endDate: new Date(),
      range: 10,
      offset: 0,
      sortColumn: 'MessageDate',
      sortDirection: 'desc',
      exactFilters: {},
      searchFilters: {},
      searchText: '',
    }

    try {
      const { data } = await api.getMessages(getMessagesRequest)

      localStorage.setItem(
        'trellis-last-messages-call-time',
        new Date().toString(),
      )
      localStorage.setItem(
        'trellis-total-unread-messages',
        data.totalUnreadMessages,
      )
      setTotalUnreadMessages(data.totalUnreadMessages)
    } catch (err) {
      LogError(err, 'Failed to get messages')
    }
  }

  const userProfileMenuItems: MenuProps['items'] = [
    practiceDetails?.facilityID && {
      key: 'facilityID',
      label: (
        <p className='user-profile-menu__non-item-text text-center'>
          Facility ID: {practiceDetails.facilityID}
        </p>
      ),
    },
    {
      key: 'myPractice',
      label: (
        <NavLink
          className='user-profile-menu__item'
          to='/Practice/PracticeInfo'
        >
          <ShopOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>My Practice</p>
        </NavLink>
      ),
    },
    {
      key: 'downloads',
      label: (
        <NavLink
          className='user-profile-menu__item'
          to='/Practice/Downloads'
        >
          <CloudDownloadOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>Downloads</p>
        </NavLink>
      ),
    },
    RoleHelper$.isTrellis.get() &&
      !RoleHelper$.isDentalIntel.get() &&
      !flags.billingAndSubscription && {
        key: 'billingInfo',
        label: (
          <NavLink
            className='user-profile-menu__item'
            to='/Practice/BillingDetails'
          >
            <DollarOutlined className='user-profile-menu__item-icon mr-075' />
            <p className='user-profile-menu__item-label'>Billing Info</p>
          </NavLink>
        ),
      },
    // This check applies to both Trellis and RPractice customers - the billing and subscription
    // menu item will be shown if the user is not a Dental Intel user and the feature flag is enabled
    !RoleHelper$.isDentalIntel.get() &&
      flags.billingAndSubscription && {
        key: 'billingAndSubscription',
        label: (
          <NavLink
            className='user-profile-menu__item'
            to='/Practice/PracticeInfo'
            onClick={() => GlobalState.BillingSubscriptionNav.set(true)}
          >
            <DollarOutlined className='user-profile-menu__item-icon mr-075' />
            <p className='user-profile-menu__item-label'>
              Billing and Subscription
            </p>
          </NavLink>
        ),
      },
    {
      key: 'userManagement',
      label: (
        <NavLink
          className='user-profile-menu__item'
          to='/Practice/UserManagementSso'
        >
          <TeamOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>User Management</p>
        </NavLink>
      ),
    },
    {
      key: 'support',
      label: (
        <a
          className='user-profile-menu__item'
          onClick={() => setShowingContactSupport(true)}
        >
          <CustomerServiceOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>Contact Support</p>
        </a>
      ),
    },
    rules.showRemoteSupport && {
      key: 'webex',
      label: (
        <NavLink
          className='user-profile-menu__item'
          to='https://vynedental.screenconnect.com/'
          target='_blank'
          rel='noreferrer'
        >
          <GlobalOutlined className='user-profile-menu__item-icon mr-075' />
          <div className='user-profile-menu__item-label'>Remote Support</div>
        </NavLink>
      ),
    },
    {
      key: 'resources',
      label: (
        <NavLink
          className='user-profile-menu__item'
          to='/Resource/Index'
        >
          <InfoCircleOutlined className='user-profile-menu__item-icon mr-075' />
          <div className='user-profile-menu__item-label'>Resources</div>
        </NavLink>
      ),
    },
    {
      key: 'logout',
      label: (
        <a
          className='user-profile-menu__item'
          onClick={() => GlobalState.IsAuthenticated.set(false)}
        >
          <LogoutOutlined className='user-profile-menu__item-icon mr-075' />
          <p className='user-profile-menu__item-label'>Log Out</p>
        </a>
      ),
    },
    {
      key: 'copyRight',
      label: (
        <p className='user-profile-menu__non-item-text vyne-sm-text vyne-text-center'>
          &#169; {new Date().getFullYear()} Napa EA/MEDX, LLC.
        </p>
      ),
    },
  ]

  return (
    <>
      <ContactSupport
        isVisible={showingContactSupport}
        setIsVisible={setShowingContactSupport}
      />
      <div className='header-container'>
        <header className={`header ${rules.headerClassModifier}`}>
          {rules.showHeaderLogo && (
            <>
              {rules.showRPracticeLogo && !rules.showTrellisLogo ? (
                <img
                  alt='Vyne Dental RPractice'
                  className='header__logo'
                  data-testid='rpractice-logo'
                  src={rPracticeLogo}
                />
              ) : (
                <img
                  alt='Vyne Dental Trellis'
                  className='header__logo'
                  data-testid='trellis-logo'
                  src={vyneTrellisLogo}
                />
              )}
            </>
          )}
          {practiceDetails && userInfo && (
            <section className='header__controls-container'>
              {flags.vsPmsSyncStatus &&
                flags.operaVynesyncAutomatedeligibility &&
                PmsSyncExpired === true && (
                  <section
                    aria-label='Pms Sync Status'
                    className='header__control-item mr-125 pms-sync-status'
                  >
                    <WarningFilled className='header__control-item-icon' />
                    <p className='header__control-item-text ml-050 mr-025'>
                      Vyne Sync Offline
                    </p>
                  </section>
                )}
              <section
                aria-label='messages'
                className='header__control-item mr-200'
              >
                <NavLink
                  className='messages-icon'
                  to='/Messages'
                >
                  <MailOutlined className='header__control-item-icon' />
                  {totalUnreadMessages > 0 && (
                    <div className='messages-icon__unread-notification'></div>
                  )}
                </NavLink>
              </section>
              <section className='header__control-item mr-125'>
                <ShopOutlined className='header__control-item-icon' />
                <p className='header__control-item-text ml-025'>
                  {practiceDetails?.officeName}
                </p>
              </section>
              <Dropdown
                menu={{ items: userProfileMenuItems }}
                trigger={['click']}
              >
                <section
                  aria-label='user profile'
                  className='header__control-item'
                >
                  <UserOutlined className='header__control-item-icon' />
                  <p className='header__control-item-text ml-025 mr-025'>
                    {profileName}
                  </p>
                  <DownChevron className='header__control-item-icon' />
                </section>
              </Dropdown>
            </section>
          )}
        </header>
        <SubscriptionAlert
          hasBillingAndSubscription={flags.billingAndSubscription}
          endDate={GlobalState.CustomerSubscription.cancelDate.get()}
        />
      </div>
    </>
  )
})

export default Header
