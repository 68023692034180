import { useCallback } from 'react'
import html2pdf, { Html2PdfOptions } from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import { LogError } from 'utils'
import { showMessage } from 'trellis:utilities/general'

// Docs for html2pdf: https://ekoopmans.github.io/html2pdf.js/#options
// Currently returns a function that generates a single page PDF with preset options

interface UseGeneratePDFOptions {
  htmlHeight: number
  htmlContent: string | null | undefined
  fileName?: string
}

const useGeneratePDF = () => {
  const generatePDF = useCallback(
    ({
      htmlHeight,
      htmlContent,
      fileName = 'document.pdf',
    }: UseGeneratePDFOptions) => {
      
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlContent || ''

      tempDiv.style.width = '210mm' // Width of default paper size
      tempDiv.style.margin = '0 auto'

      const contentWidth = 775
      const contentHeight = htmlHeight

      const options: Html2PdfOptions = {
        margin: [10, 10, 10, 10],
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 1.5,
        },
        jsPDF: {
          unit: 'px',
          format: [contentWidth, contentHeight],
          orientation: 'portrait',
          hotfixes: ['px_scaling'],
        },
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      html2pdf()
        .set(options)
        .from(tempDiv)
        .toPdf()
        .get('pdf')
        .then((pdf: jsPDF) => {
           // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const pdfBlob = pdf.output('blob')
          const pdfUrl = URL.createObjectURL(pdfBlob);

          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(pdfUrl);
        })
        .catch((err) => {
          showMessage('There was an error generating the PDF', 'error')
          LogError('Error generating PDF', err)
        })
    },
    []
  )

  return { generatePDF }
}

export default useGeneratePDF
