import type { MenuProps } from 'antd'

import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  InfoCircleFilled,
} from '@ant-design/icons'
import { DatePicker, RangePickerProps, VyneModal } from '@vynedental/design-system'
import { Col, Dropdown, Empty, Row, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { FC, useRef } from 'react'

import { AttachmentDatePickerConstants } from 'trellis:components/attachmentsShared/shared/sharedConstants'

import {
  IAttachmentGridItem,
  IAttachmentImageGridCommon,
  ImageGridEventType,
  ImageOrientationType,
} from '../../attachment.interface'
import { RemoveImageDialog } from '../../constants'
import { optionType } from '../../imageDetails/components/imageTypeDropdown/dropdownOptions'
import ImageTypeDropDownBasic from '../../imageDetails/components/imageTypeDropdown/imageTypeDropdownBasic'

import './attachmentImageGrid.scss'

const { confirm } = VyneModal

const AttachmentImageGrid: FC<IAttachmentImageGridCommon> = ({
  editable,
  dataSource,
  onEvent,
  imageOptions,
  otherOptions,
}) => {
  const selectedEntry = useRef<IAttachmentGridItem>(null)

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      (current && current > dayjs().endOf('day')) ||
      current < dayjs(AttachmentDatePickerConstants.MINIMUM_DISABLED_DATE)
    )
  }

  const showConfirm = () => {
    const modal = confirm({
      cancelText: RemoveImageDialog.dialogDenyText,
      content: RemoveImageDialog.dialogText,
      okText: RemoveImageDialog.dialogConfirmText,
      onCancel() {
        modal.destroy()
      },
      onOk() {
        onEvent(selectedEntry.current, ImageGridEventType.DeleteClicked, null)
      },
      title: RemoveImageDialog.dialogTitle,
    })
  }

  const columns: ColumnsType<IAttachmentGridItem> = [
    {
      key: 'itemNumber',
      dataIndex: 'itemNumber',
      width: '5%',
      align: 'center',
      render: (_: string, record: IAttachmentGridItem) => {
        return <>{record.rowNumber}</>
      },
    },
    {
      title: 'Image',
      key: 'attachmentImagePath',
      dataIndex: 'attachmentImagePath',
      width: '15%',
      align: 'center',
      render: (_: string, record: IAttachmentGridItem) => {
        return (
          <img
            src={record.attachmentImagePath}
            className='img-preview'
          />
        )
      },
    },
    {
      title: 'Image Type',
      key: 'imageTypeName',
      dataIndex: 'imageTypeName',
      width: '25%',
      render: (_: string, record: IAttachmentGridItem) => {
        return editable && record.editable ? (
          <ImageTypeDropDownBasic
            activeImageTypeOption={record.imageTypeName}
            imageOptions={imageOptions}
            otherOptions={otherOptions}
            imageTypeChanged={(type: optionType) => {
              onEvent(record, ImageGridEventType.ImageTypeUpdated, type)
            }}
          />
        ) : (
          record.imageTypeName
        )
      },
    },
    {
      title: (
        <div className='orientation'>
          Orientation&nbsp;
          <Tooltip
            title="Right = Left side of the x-ray is the patient's right side (most common)
                Left = Left side of the x-ray is the patient's left side"
          >
            <InfoCircleFilled style={{ fontSize: '1.1rem' }} />
          </Tooltip>
        </div>
      ),
      width: '25%',
      dataIndex: 'orientation',
      key: 'orientation',
      render: (_: string, record: IAttachmentGridItem) => {
        const orientationItems: MenuProps['items'] = [
          {
            disabled: !(editable && record.editable),
            key: 'LEFT',
            label: 'Patient Left',
          },
          {
            disabled: !(editable && record.editable),
            key: 'RIGHT',
            label: 'Patient Right',
          },
        ]

        return (
          record.showOrientation && (
            <Row className='image-type-container'>
              <Col span={24}>
                <Dropdown
                  menu={{
                    disabled: !(editable && record.editable),
                    items: orientationItems,
                    onClick: ({ key }) => {
                      onEvent(
                        record,
                        ImageGridEventType.OrientationUpdated,
                        key === 'RIGHT'
                          ? ImageOrientationType.LeftIsRight
                          : ImageOrientationType.LeftIsLeft,
                      )
                    },
                  }}
                  overlayStyle={{ borderRadius: 0 }}
                >
                  <a className={`image-type__dropdown`}>
                    <span>
                      {record.orientation === 'RIGHT'
                        ? 'Patient Right'
                        : 'Patient Left'}{' '}
                      &nbsp;
                    </span>
                    <DownOutlined />
                  </a>
                </Dropdown>
              </Col>
            </Row>
          )
        )
      },
    },
    {
      title: 'Date Taken',
      key: 'date',
      dataIndex: 'date',
      width: '25%',
      render: (_: string, record: IAttachmentGridItem) => {
        return editable && record.editable ? (
          <DatePicker
            defaultValue={record.date ? dayjs(record.date) : null}
            disabledDate={disabledDate}
            name='attachment-image-grid-date-picker'
            placeholder='MM/DD/YYYY'
            onChange={(date) =>
              onEvent(
                record,
                ImageGridEventType.DateTakenUpdated,
                date?.toDate(),
              )
            }
          />
        ) : (
          record?.date?.toLocaleDateString()
        )
      },
    },
    {
      key: 'imageEdit',
      render: (_: string, record: IAttachmentGridItem, index: number) => {
        return (
          editable &&
          record.editable && (
            <div className='img-icons'>
              <EditOutlined
                className='img-icon img-edit-icon'
                onClick={() =>
                  onEvent(record, ImageGridEventType.EditClicked, null)
                }
              />
              <DeleteOutlined
                className='img-icon'
                onClick={() => {
                  selectedEntry.current = record
                  showConfirm()
                }}
              />
            </div>
          )
        )
      },
    },
  ]

  return (
    <>
      <div>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Capture or upload an attachment image'
              />
            ),
          }}
          className='attachment-table'
        />
      </div>
    </>
  )
}

export default AttachmentImageGrid
