import { Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'
import { getUnixTime } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'

import { StatusDisplay } from 'ui'

import { ArchiveClaim } from 'trellis:api/claim/claim-client'
import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import { formatColumnDate } from 'trellis:utilities/general'

import { ClaimArchiveGridState } from '../context/ClaimArchiveContext'

import FilePDF from 'trellis:assets/file-pdf.svg?react'

export const getClaimArchiveGridColumns = (
  state: ClaimArchiveGridState,
  setCurrentRowId: Dispatch<SetStateAction<number>>,
) => {
  return state.VisibleColumns?.map((column) => {
    const match: ColumnType<ArchiveClaim> = {
      dataIndex: column,
      width: 150,
    }

    switch (column) {
      case 'Actions':
        match.align = 'center'
        match.title = 'Actions'
        match.render = (_, record) => (
          <Tooltip title='Create letter of timely filing'>
            <FilePDF onClick={() => setCurrentRowId(record.CLAIM_ID)} />
          </Tooltip>
        )
        break
      case 'TRANSMIT_DATE':
        match.title = 'Received Date'
        match.sorter = (a, b) =>
          getUnixTime(new Date(a.TRANSMIT_DATE)) -
          getUnixTime(new Date(b.TRANSMIT_DATE))
        match.render = (value: string) => formatColumnDate(value, true)
        break
      case 'PAT_NAME':
        match.title = 'Patient Name'
        match.sorter = (a, b) => a.PAT_NAME?.localeCompare(b.PAT_NAME)
        break
      case 'SUB_NAME':
        match.title = 'Subscriber Name'
        match.sorter = (a, b) => a.SUB_NAME?.localeCompare(b.SUB_NAME)
        break
      case 'CARRIER':
        match.title = 'Carrier'
        match.sorter = (a, b) => a.CARRIER?.localeCompare(b.CARRIER)
        break
      case 'STATUS':
        match.title = 'Status'
        match.sorter = (a, b) => a.STATUS?.localeCompare(b.STATUS)
        match.render = (value: string, row) => (
          <StatusDisplay
            status={value}
            toolTip={row.Status_Description_Tooltip}
            getStatusOverride={getStatusOverride}
            whiteBackgroundOverride={true}
          />
        )
        break
      case 'Status_Description':
        match.title = 'Status Description'
        match.sorter = (a, b) =>
          a.Status_Description?.localeCompare(b.Status_Description)
        match.render = (value: string, row) =>
          value && (
            <Tooltip title={row.Status_Description_Tooltip}>
              {value
                .replace('(', '')
                .replace(')', '')
                .replace('NEA Attachment', 'NEA')
                .replace('please add', '')
                .replace(' for this claim', '')
                .replace(' for Claim.', '')
                .replace('Please verify information and try again.', '')}
            </Tooltip>
          )
        break
      case 'REMARKS_1':
        match.title = 'Remarks'
        match.sorter = (a, b) => a.REMARKS_1?.localeCompare(b.REMARKS_1)
        break
      case 'FEE_TOTAL':
        match.title = 'Fee Total'
        match.sorter = (a, b) => a.FEE_TOTAL?.localeCompare(b.FEE_TOTAL)
        break
      case 'TIN':
        match.title = 'Tin'
        match.sorter = (a, b) => a.TIN?.localeCompare(b.TIN)
        break
      case 'PRE_D':
        match.title = 'Pre D'
        match.sorter = (a, b) => a.PRE_D?.localeCompare(b.PRE_D)
        break
      case 'DOS':
        match.title = 'Service Date'
        match.sorter = (a, b) =>
          getUnixTime(new Date(a.DOS)) - getUnixTime(new Date(b.DOS))
        match.render = (value: string) => formatColumnDate(value)
        break
      case 'LAST_UPDATE_DATE':
        match.title = 'Last Update'
        match.sorter = (a, b) =>
          getUnixTime(new Date(a.LAST_UPDATE_DATE)) -
          getUnixTime(new Date(b.LAST_UPDATE_DATE))
        match.render = (value: string) => formatColumnDate(value, true)
        break
      case 'TREATMENT_ADD':
        match.title = 'Treatment Address'
        match.sorter = (a, b) => a.TREATMENT_ADD?.localeCompare(b.TREATMENT_ADD)
        break
      case 'TREATMENT_CITY':
        match.title = 'Treatment City'
        match.sorter = (a, b) =>
          a.TREATMENT_CITY?.localeCompare(b.TREATMENT_CITY)
        break
      case 'BILLING_ADD_1':
        match.title = 'Billing Address'
        match.sorter = (a, b) => a.BILLING_ADD_1?.localeCompare(b.BILLING_ADD_1)
        break
      case 'BILLING_CITY':
        match.title = 'Billing City'
        match.sorter = (a, b) => a.BILLING_CITY?.localeCompare(b.BILLING_CITY)
        break
      case 'NPI_GROUP':
        match.title = 'Npi Group'
        match.sorter = (a, b) => a.NPI_GROUP?.localeCompare(b.NPI_GROUP)
        break
      case 'HDD_SERIAL_ID':
        match.title = 'Install'
        match.sorter = (a, b) => a.HDD_SERIAL_ID?.localeCompare(b.HDD_SERIAL_ID)
        break
      case 'TREATING_SIGNATURE':
        match.title = 'Treating Signature'
        match.sorter = (a, b) =>
          a.TREATING_SIGNATURE?.localeCompare(b.TREATING_SIGNATURE)
        break
      case 'COB_FLAG':
        match.title = 'Cob Flag'
        match.sorter = (a, b) => a.COB_FLAG?.localeCompare(b.COB_FLAG)
        break
      case 'RP_USERNAME':
        match.title = 'Username'
        match.sorter = (a, b) => a.RP_USERNAME?.localeCompare(b.RP_USERNAME)
        break
    }

    return match
  })
}
