import { ObservableBoolean } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { Table } from 'antd'
import { FC, useState } from 'react'

import { LogError } from 'utils'

import { ClaimArchiveToolbar } from 'trellis:components/claims/controls/ClaimArchiveSearch/components/ClaimArchiveToolbar'
import { getClaimArchiveGridColumns } from 'trellis:components/claims/controls/ClaimArchiveSearch/utils/get-claim-archive-grid-columns'
import { showMessage } from 'trellis:utilities/general'

import { useClaimArchiveContext } from './context/ClaimArchiveContext'
import { useGetArchiveClaims } from './hooks/useGetArchiveClaims'
import { useGetLetterOfTimelyFiling } from './hooks/useGetLetterOfTimelyFiling'
import { getPagination, setRowClass } from './utils/table-helpers'

type ClaimArchiveSearchModalProps = {
  showArchiveSearchModal$: ObservableBoolean
}

export const ClaimArchiveSearchModal: FC<ClaimArchiveSearchModalProps> =
  observer(({ showArchiveSearchModal$ }) => {
    const { dateRange, state, setState } = useClaimArchiveContext()

    const [currentRowId, setCurrentRowId] = useState<number | null>(null)

    const { data, error, isFetching, status } = useGetArchiveClaims(
      dateRange,
      state,
      showArchiveSearchModal$,
    )

    const {
      data: letterData,
      error: letterError,
      isFetching: isFetchingLetterData,
      status: letterStatus,
    } = useGetLetterOfTimelyFiling(currentRowId)

    if (letterData?.data.LetterOfTimelyFilingHtml) {
      const letter = letterData?.data.LetterOfTimelyFilingHtml

      const newWindow = window.open()
      if (newWindow) {
        newWindow.document.write(letter)
        newWindow.stop()

        setCurrentRowId(null)
      }
    }

    if (status === 'error') {
      showMessage(
        'We’re having trouble completing your search. Please try again. If you continue to experience issues, contact support for help.',
      )
      LogError(error)
    }

    if (letterStatus === 'error') {
      showMessage(
        'There was a problem retrieving the letter of timely filing. Please try again.',
      )
      LogError(letterError)
    }

    const handleClose = () => {
      setCurrentRowId(null)
      showArchiveSearchModal$.set(false)
    }

    return (
      <VyneModal
        dataTestId='claim-archive-search-modal'
        destroyOnClose
        footer={[
          <VyneButton
            dataTestId='claim-archive-search-close-button'
            key='close'
            onClick={handleClose}
          >
            Close
          </VyneButton>,
        ]}
        onCancel={handleClose}
        open={showArchiveSearchModal$.get()}
        style={{ top: 20 }}
        styles={{
          body: {
            maxHeight: 'calc(100vh - 150px)',
            overflowY: 'hidden',
          },
        }}
        title='Archive Claim Search'
        width='80vw'
      >
        <h4 className='pb-150 pt-150'>
          To locate a claim submitted within the last two years, select a date
          range and search by patient name.
        </h4>
        <ClaimArchiveToolbar data={data?.data?.Data} />
        <Table
          className='archive-table'
          columns={getClaimArchiveGridColumns(state, setCurrentRowId)}
          dataSource={data?.data.Data}
          loading={isFetching || isFetchingLetterData}
          scroll={{ y: 'calc(100vh - 400px' }}
          pagination={getPagination(state, setState)}
          rowKey='CLAIM_ID'
          rowClassName={setRowClass}
        />
      </VyneModal>
    )
  })
