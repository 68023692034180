import { LeftCircleOutlined } from '@ant-design/icons'
import { observer } from '@legendapp/state/react'
import { Badge, Tabs, TabsProps, VyneButton } from '@vynedental/design-system'
import { Col, Row, Space } from 'antd'
import { useEffect, useState } from 'react'

import { StatusDisplay } from 'ui'

import { getStatusOverride } from 'trellis:constants/claimStatusDescriptionData'
import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { Labels } from '../../../constants/labels'
import { handleBackButtonClick } from '../../../utilities/general'
import { useEligibilityContext } from '../shared/context/EligibilityContext'
import { usePatientEligibilityDetailContext } from '../shared/context/PatientEligibilityDetailContext'
import { emptyPatient } from '../shared/form/utilities/eligibilityFormDataHandling'
import PatientInfo from './tabs/PatientEligibilityForm'
import History from './tabs/PatientEligibilityHistory'

const PatientEligibilityDetail = observer(() => {
  const trellisActiveService = GlobalState.ActiveServices.TRELLIS_CLAIMS.get()
  const dentalIntelActiveService = GlobalState.ActiveServices.DENTAL_INTEL.get()
  const flags = LDFlags$.get()

  const { setShowPatientUpdateFormModal } = useEligibilityContext()
  const {
    activeTab,
    formErrors,
    loading,
    patient,
    patientDisplayName,
    patientStatus,
    setActiveTab,
    setFormErrors,
    setPatient,
    setPatientDisplayName,
    setPatientStatus,
    setEligibilityHistory,
    setShowPatientDetail,
    verifying,
  } = usePatientEligibilityDetailContext()
  const [updateFormDisabled, setUpdateFormDisabled] = useState<boolean>(false)

  const backButtonHandler = () => {
    setFormErrors([])
    setPatient({ ...emptyPatient })
    setShowPatientDetail(false)
    setPatientDisplayName('New Patient')
    setPatientStatus(null)
    setEligibilityHistory([])
    setActiveTab('Patient')
  }

  useEffect(() => {
    handleBackButtonClick(backButtonHandler)
  }, [])

  //Form errors updated
  useEffect(() => {
    if (
      !patient.PatientFirstName ||
      !patient.PatientLastName ||
      !patient.PatientBirthdate ||
      !patient.PatientGender ||
      !patient.ProviderLastName ||
      !patient.IndividualNpi
    )
      setUpdateFormDisabled(true)
    else {
      const validatedFields = [
        'PatientFirstName',
        'PatientLastName',
        'PatientBirthdate',
        'PatientGender',
        'ProviderLastName',
        'IndividualNpi',
      ]
      const filteredErrors = formErrors.filter(
        (formError: { errors: string[] }) =>
          validatedFields.includes(formError.errors[0]),
      )

      setUpdateFormDisabled(filteredErrors.length > 0)
    }
  }, [formErrors, patient])

  const errorCount = formErrors?.length

  const tabItems: TabsProps['items'] = [
    {
      key: 'Patient',
      label: 'Patient Info',
      icon: errorCount ? (
        <Badge
          count={errorCount}
          name={`patient-info-tab`}
        />
      ) : null,
      children: <PatientInfo />,
    },
  ]

  if (
    (!flags.eligibilityUsevynedentalsync &&
      !flags.operaVynesyncAutomatedeligibility) ||
    patient.PatientId !== '0'
  ) {
    tabItems.push({
      key: 'Response History',
      label: 'History',
      children: <History />,
    })
  }

  return (
    <>
      <section>
        <Row
          align='middle'
          className='patient-header-row mb-050'
          justify='space-between'
        >
          <VyneButton
            className='back-to-all'
            dataTestId='back-to-all-patients-button'
            icon={<LeftCircleOutlined className='fs-125' />}
            onClick={backButtonHandler}
            type='text'
          >
            Back
          </VyneButton>
          {activeTab === 'Patient' && (
            <Space>
              {!dentalIntelActiveService && trellisActiveService && (
                <Col>
                  <VyneButton
                    dataTestId='send-update-form-button'
                    disabled={loading || updateFormDisabled}
                    onClick={() => setShowPatientUpdateFormModal(true)}
                    size='large'
                    tooltip={
                      updateFormDisabled
                        ? Labels.updateFormButtonDisabledTooltip
                        : null
                    }
                  >
                    Send Update Form
                  </VyneButton>
                </Col>
              )}
              <Col>
                <VyneButton
                  dataTestId='verify-patient-eligibility-button'
                  disabled={loading}
                  form='patientEligibilityForm'
                  htmlType='submit'
                  loading={verifying}
                  size='large'
                  type='primary'
                >
                  Verify
                </VyneButton>
              </Col>
            </Space>
          )}
        </Row>
        <Row align='middle'>
          <h2>{patientDisplayName}</h2>
          {patientStatus?.status && (
            <section className='status-badge-container'>
              <StatusDisplay
                status={patientStatus?.status}
                toolTip={patientStatus?.toolTip}
                type='badge'
                getStatusOverride={getStatusOverride}
              />
            </section>
          )}
        </Row>
      </section>
      <Tabs
        activeKey={activeTab}
        className='mt-150'
        items={tabItems}
        name='patient-detail-tabs'
        onChange={(key: string) => setActiveTab(key)}
      />
    </>
  )
})

export default PatientEligibilityDetail
