import { useObservable } from '@legendapp/state/react'
import { FC } from 'react'

import { ClaimModel } from 'trellis:api/claim/claim-client'
import { useClaimActionContext } from 'trellis:components/claims/context/claimActionContext'
import { RowActions } from 'trellis:components/claims/util/claimConstants'
import {
  ActionItem,
  RowActionsMenu,
} from 'trellis:components/RowActionsMenu/RowActionsMenu'
import { showMessage } from 'trellis:utilities/general'

import DeleteIcon from 'trellis:assets/delete-outline.svg?react'
import IgnoreAttachmentIcon from 'trellis:assets/ignore-attachment.svg?react'
import PaperclipIcon from 'trellis:assets/paperclip.svg?react'
import HoldReleaseIcon from 'trellis:assets/play-circle.svg?react'
import SendClaimIcon from 'trellis:assets/send-claim.svg?react'

interface ClaimRowActionsMenuProps {
  index: number
  currentClaim: ClaimModel
}

export const ClaimRowActionsMenu: FC<ClaimRowActionsMenuProps> = ({
  index,
  currentClaim,
}) => {
  const { handleRowAction } = useClaimActionContext()

  const isActionCalledFromTable$ = useObservable<boolean>(false)

  const actions: ActionItem[] = [
    {
      label: 'Send',
      icon: <SendClaimIcon />,
      clickHandler: async () => {
        if (currentClaim.Status === 'Failed')
          showMessage('To Send, please fix all claim errors.')
        else if (currentClaim.Status === 'Pending')
          showMessage('To Send, please select the Insurance Carrier')
        else await handleRowAction(RowActions.SEND, currentClaim.ClaimID)
      },
      isVisible: true,
    },
    {
      label: 'Add Attachment',
      icon: <PaperclipIcon />,
      clickHandler: () =>
        handleRowAction(RowActions.ADD_ATTACHMENT, currentClaim.ClaimID),
      isVisible: true,
    },
    {
      label: 'Ignore Requirement',
      icon: <IgnoreAttachmentIcon />,
      clickHandler: () =>
        handleRowAction(RowActions.IGNORE_REQUIREMENT, currentClaim.ClaimID),
      isVisible: true,
    },
    {
      label: 'Hold/Release',
      icon: <HoldReleaseIcon />,
      clickHandler: () =>
        handleRowAction(
          currentClaim.Status === 'Hold' ? RowActions.RELEASE : RowActions.HOLD,
          currentClaim.ClaimID,
        ),
      isVisible: true,
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      clickHandler: () =>
        handleRowAction(RowActions.DELETE, currentClaim.ClaimID),
      isVisible: true,
    },
  ]

  return (
    <RowActionsMenu
      index={index}
      actions={actions}
      isActionCalledFromTable$={isActionCalledFromTable$}
    />
  )
}
