import { observer } from '@legendapp/state/react'
import React, { FC } from 'react'

import { FormSelect } from 'ui'
import { SelectOption } from 'utils'

import GlobalState from 'trellis:state/globalState'

const getOptions = (): SelectOption[] => {
  const items = GlobalState.Constants.supportedPMS
    .get()
    ?.map<SelectOption>((pms) => ({
      text: pms.pmsName,
      value: pms.pmsName,
    }))
  return items ?? []
}

const PracticeManagementSystem: FC = observer(() => {
  return (
    <FormSelect
      label='Select PMS'
      name='p_Software'
      options={getOptions()}
      required
    />
  )
})

export default PracticeManagementSystem
