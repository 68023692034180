import React, { FC, useRef, useState } from 'react'

import { IAttachmentNarrative } from '../../attachment.interface'

import './attachmentNarrative.scss'

import { Col, Input, Row } from 'antd'

import { useClaimDetailContext } from '../../../claims/context/claimDetailContext'

import '../../attachment.scss'
import '../../../Attachments/AttachmentContent/AttachmentContent.scss'

import { observer } from '@legendapp/state/react'

const { TextArea } = Input

const AttachmentNarrative: FC<IAttachmentNarrative> = observer(
  ({ narrative, handleUpdateAttachmentNarrative }) => {
    const { setGlobalNarrativeRef, attachmentNarrativeStatusCodeError$ } =
      useClaimDetailContext()
    const [value, setValue] = useState(narrative ?? '')
    const timeoutRef = useRef(null)

    const attachmentNarrativeStatusCodeError =
      attachmentNarrativeStatusCodeError$.get()

    //handle change in narrative
    React.useEffect(() => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }

      //we won't update attachment till after timeout to prevent flicker
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null
        handleUpdateAttachmentNarrative(value)
      }, 500)
      setGlobalNarrativeRef(value)
    }, [value])

    const onChanged = (changedValue: string) => {
      attachmentNarrativeStatusCodeError$.set(null)
      setValue(changedValue)
    }

    return (
      <>
        <Row>
          <Col span={24}>
            <section className='flex-row items-center justify-between mt-16-px mb-16-px'>
              <h3>Narrative</h3>
              <p>{`${value?.length || 0}/2000 Characters`}</p>
            </section>
          </Col>
          <Col span={24}>
            <TextArea
              title='Narrative'
              value={value}
              maxLength={2000}
              onChange={(e) => onChanged(e.target.value)}
              rows={6}
              placeholder="Diagnostic or descriptive information supporting the patient's treatment plan, insurance claim and/or attached images"
            />
            {attachmentNarrativeStatusCodeError && (
              <div className='attachment-narrative--error-text'>
                {attachmentNarrativeStatusCodeError.message}
              </div>
            )}
          </Col>
        </Row>
      </>
    )
  },
)

export default AttachmentNarrative
