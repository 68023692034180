import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Col, Row } from 'antd'
import { FC } from 'react'

import { AddressControls } from 'trellis:components/_siteWide/address/addressControls'
import { FormInput } from 'trellis:components/_siteWide/form/formInput'
import { ProviderAutoComplete } from 'trellis:components/ProviderAutoComplete/ProviderAutoComplete'
import { ProviderDropdownOption } from 'trellis:features/providers/utils/provider-helpers'
import { validateNpi } from 'trellis:utilities/validators/npiValidators'
import { validateTin } from 'trellis:utilities/validators/tinValidator'

import { useClaimDetailContext } from '../../../context/claimDetailContext'
import styles from '../../content/ProviderContent.module.scss'

import AddUser from 'trellis:assets/add-user-icon.svg?react'

interface ClaimBillingProviderProps {
  providerOptions: Observable<ProviderDropdownOption[]>
  showAddProviderModal: Observable<boolean>
}

export const BillingControls: FC<ClaimBillingProviderProps> = observer(
  ({ providerOptions, showAddProviderModal }) => {
    const { claim, setClaim } = useClaimDetailContext()

    return (
      <>
        <div className='flex-row items-center justify-between mb-16-px'>
          <h3>Billing Info</h3>
          <div
            className={styles['add-provider-link']}
            onClick={() => showAddProviderModal.set(true)}
          >
            <AddUser className={styles['add-provider-link__icon']} />
            <p>
              <strong>Add a Provider</strong>
            </p>
          </div>
        </div>
        <Row>
          <Col span={12}>
            <ProviderAutoComplete
              name='BillingName'
              options={providerOptions}
              setState={setClaim}
              state={claim}
            />
          </Col>
          <Col
            span={12}
            className='pl-100'
          >
            <FormInput
              name='BillingPhone'
              label='Phone Number'
              element={claim}
              setElement={setClaim}
              initialValue={claim.BillingPhone}
              section='provider'
            />
          </Col>
        </Row>
        <AddressControls
          prefix='Billing'
          state={claim}
          setState={setClaim}
          section='provider'
        />
        <Row>
          <Col span={12}>
            <FormInput
              name='BillingLicense'
              label='License'
              element={claim}
              setElement={setClaim}
              initialValue={claim.BillingLicense}
            />
          </Col>
          <Col
            span={12}
            className='pl-100'
          >
            <FormInput
              name='NpiGroup'
              label='Group NPI'
              element={claim}
              setElement={setClaim}
              initialValue={claim.NpiGroup}
              section='provider'
              validator={validateNpi}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormInput
              name='Tin'
              label='Tax ID'
              element={claim}
              setElement={setClaim}
              initialValue={claim.Tin}
              section='provider'
              validator={validateTin}
              required
            />
          </Col>
          <Col
            span={12}
            className='pl-100'
          >
            <FormInput
              name='BillingProviderId'
              label='Provider ID'
              element={claim}
              setElement={setClaim}
              initialValue={claim.BillingProviderId}
            />
          </Col>
        </Row>
      </>
    )
  },
)
