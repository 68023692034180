import { CalendarOutlined } from '@ant-design/icons'
import { Col, Row, Tooltip } from 'antd'
import React from 'react'

import GlobalState, { LDFlags$ } from 'trellis:state/globalState'

import { FormCurrencyInput } from '../../../../_siteWide/form/formCurrencyInput'
import { FormInput } from '../../../../_siteWide/form/formInput'
import { FormMaskInput } from '../../../../_siteWide/form/formMaskInput'
import { FormSelect } from '../../../../_siteWide/form/formSelect'
import { validateBirthday } from '../../../../../utilities/validators/birthdayValidators'
import { useClaimDetailContext } from '../../../context/claimDetailContext'

const groupCodes = [
  { value: 'CO', text: '(CO) Contractual Obligation' },
  { value: 'CR', text: '(CR) Corrections and Reversal' },
  { value: 'OA', text: '(OA) Other Adjustment' },
  { value: 'PI', text: '(PI) Payer Initiated Reductions' },
  { value: 'PR', text: '(PR) Patient Responsibility' },
]

const codeTooltip = (
  <p>
    TIP: For secondary claim adjustment reasons, Providers most commonly use
    Group Code "OA" which means "Other Adjustment" and Reason Code "23" that
    indicates primary insurance did not pay the full amount.{' '}
    <span>
      <a
        href='https://x12.org/codes/claim-adjustment-reason-codes'
        target='_blank'
        rel='noreferrer'
      >
        Locate an X12 Code.
      </a>{' '}
      <i>
        This information is made available for general information purposes only
        and is not intended to be used as specific legal advice.
      </i>
    </span>
  </p>
)

export const PaymentControls = () => {
  const { claim, setClaim, secondaryCoverageRequirements } =
    useClaimDetailContext()

  const flags = LDFlags$.get()

  const formSection = 'procedures'

  return (
    <>
      <Row>
        <Col
          span={6}
          className='mr-100'
        >
          <FormCurrencyInput
            element={claim}
            initialValue={claim.COB_AMOUNT}
            label='Primary Payment Amount'
            name='COB_AMOUNT'
            section={formSection}
            setElement={setClaim}
          />
        </Col>
        {(flags.globalSecondaryClaimRequirements ||
          secondaryCoverageRequirements?.requiresPrimaryPaymentDate) && (
          <Col
            span={6}
            className='mr-100'
          >
            <FormMaskInput
              element={claim}
              format='##/##/####'
              initialValue={claim.PaymentDate}
              label='Primary Payment Date'
              mask='_'
              name='PaymentDate'
              prefix={<CalendarOutlined className='site-form-item-icon' />}
              section={formSection}
              setElement={setClaim}
              validator={validateBirthday}
            />
          </Col>
        )}
      </Row>
      {(flags.globalSecondaryClaimRequirements ||
        (secondaryCoverageRequirements?.requiresPatientLiability &&
          secondaryCoverageRequirements?.requiresAdjGroupCode &&
          secondaryCoverageRequirements?.requiresAdjReasonCode)) && (
        <Row>
          <Col
            span={6}
            className='mr-100'
          >
            <FormCurrencyInput
              element={claim}
              initialValue={claim.PatientLiability}
              label='Patient Liability Amount'
              name='PatientLiability'
              section={formSection}
              setElement={setClaim}
            />
          </Col>
          <Col
            span={6}
            className='mr-100'
          >
            <Tooltip
              title={codeTooltip}
              placement='left'
            >
              <FormSelect
                name='AdjustmentGroup'
                label='Group Code'
                element={claim}
                setElement={setClaim}
                initialValue={claim.AdjustmentGroup}
                options={groupCodes}
                section={formSection}
              />
            </Tooltip>
          </Col>
          <Col
            span={6}
            className='mr-100'
          >
            <FormInput
              name='AdjustmentReason'
              label='Reason Code'
              element={claim}
              setElement={setClaim}
              initialValue={claim.AdjustmentReason}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
