import { endOfDay, format, startOfDay, sub } from 'date-fns'
import { render } from 'json2html'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { ArchiveClaim } from 'trellis:api/claim/claim-client'
import { DateRange } from 'trellis:utilities/datePickerUtilities'
import { printHtmlContent, showMessage } from 'trellis:utilities/general'

import { claimArchiveColumns } from '../utils/claim-archive-column-selector-list'

type ClaimArchiveContextType = {
  state: ClaimArchiveGridState
  setState: Dispatch<SetStateAction<ClaimArchiveGridState>>
  dateRange: DateRange
  setDateRange: Dispatch<SetStateAction<DateRange>>
  ignoreClaim: boolean
  setIgnoreClaim: Dispatch<SetStateAction<boolean>>
  getDownloadRows: (data: ArchiveClaim[]) => ArchiveClaim[]
  print: (data: ArchiveClaim[]) => void
  searchTable: (value: string) => void
  toggleIgnore: () => void
  defaultColumns: string[]
  storageColumns: string[]
}

const ClaimArchiveContext = createContext<ClaimArchiveContextType>(null)
const store = window.localStorage

export type ClaimArchiveGridState = {
  Data: ArchiveClaim[]
  OriginalData: unknown[]
  Total: number
  Search: string | null
  Ignored: boolean
  VisibleColumns: string[]
  Filters: {
    CurrentPage: number
    PageSize: number
    Config: unknown
  }
}

export const ClaimArchiveContextProvider = ({ children }: any) => {
  const storageColumns = JSON.parse(
    store.getItem('claim-archive-columns'),
  ) as string[]
  const defaultColumns = [
    'TRANSMIT_DATE',
    'PAT_NAME',
    'SUB_NAME',
    'CARRIER',
    'STATUS',
    'Status_Description',
    'Actions',
  ]

  const [ignoreClaim, setIgnoreClaim] = useState(false)
  const [state, setState] = useState<ClaimArchiveGridState>({
    Data: [],
    OriginalData: [],
    Total: 0,
    Search: null,
    Ignored: false,
    VisibleColumns: storageColumns || defaultColumns,
    Filters: {
      CurrentPage: 1,
      PageSize: 50,
      Config: {},
    },
  })
  const [dateRange, setDateRange] = useState<DateRange>({
    dates: [startOfDay(sub(new Date(), { months: 6 })), endOfDay(new Date())],
    numberOfDays: 183,
  })

  const toggleIgnore = () => {
    const copy = { ...state }
    copy.Ignored = !state.Ignored
    setState(copy)
  }

  const print = (data: ArchiveClaim[]) => {
    if (!data.length) {
      showMessage('There are no claims to print.', 'error')
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    printHtmlContent(render(getDownloadRows(data)))
  }

  const getDownloadRows = (data: ArchiveClaim[]) => {
    const exportedData: ArchiveClaim[] = []
    data?.map((d) => {
      const item = {}
      Object.keys(d).map((k: string) => {
        const match = claimArchiveColumns.filter((c) => c.property === k)[0]
        if (match && state.VisibleColumns.includes(k)) {
          const label = match.label
          let value = d[k] as string
          if (
            label === 'Transmit Date' ||
            label === 'Service Date' ||
            label === 'Last Update'
          )
            value = format(new Date(), 'MM/dd/yyyy')

          item[label] = value
        }
      })

      exportedData.push(item)
    })

    return exportedData
  }

  const searchTable = (value: string) => {
    const copy = { ...state }
    copy.Search = value
    setState(copy)
  }

  return (
    <ClaimArchiveContext.Provider
      value={{
        state,
        setState,
        dateRange,
        setDateRange,
        ignoreClaim,
        setIgnoreClaim,

        // functions
        toggleIgnore,
        print,
        getDownloadRows,
        searchTable,

        // columns
        defaultColumns,
        storageColumns,
      }}
    >
      {children}
    </ClaimArchiveContext.Provider>
  )
}

export const useClaimArchiveContext = () => {
  const context = useContext(ClaimArchiveContext)
  if (!context) throw new Error('Context must be used within a Provider')
  return context
}
