/* tslint:disable */
/* eslint-disable */
/**
 * Claim
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArchiveClaim
 */
export interface ArchiveClaim {
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'CLAIM_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TRANSMIT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'PROCESSED_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TRANSMIT_DATE_STRING'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'PAT_NAME'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'SUB_NAME'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'CARRIER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'STATUS'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'Queued'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'Status_Tooltip_Id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'Status_Description_Tooltip_Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'REMARKS_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'REJECT_REASON_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'Status_Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'Status_Description_Tooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'LAST_UPDATE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'FEE_TOTAL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'BILLING_ADD_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TIN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TREATMENT_ADD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TREATMENT_CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'BILLING_CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'TREATING_SIGNATURE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'COB_FLAG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'NPI_GROUP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'PRE_D'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'HAS_ATTACHMENT'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'DOS'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveClaim
     */
    'EOBAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveClaim
     */
    'IS_EP_ELIGIBILE'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'HAS_835'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'HDD_SERIAL_ID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveClaim
     */
    'Ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'ATTACHMENT_REF_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'RP_USERNAME'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'StatusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'StatusComments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveClaim
     */
    'StatusDescriptionTooltip'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveClaim
     */
    'RowId'?: number;
}
/**
 * 
 * @export
 * @interface AuxiliaryClaimInformation
 */
export interface AuxiliaryClaimInformation {
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'AuxiliaryClaimInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'PayToProvider_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Ortho_BandingDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Ortho_TreatmentMonthsTotal_DN101'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Ortho_TreatmentMonthsRemaining_DN102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Ortho_TreatmentIndicator_DN104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Diagnosis_CodeListQualifier_HI01_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Diagnosis_PrincipalCode_HI01_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Diagnosis_SecondaryCode_HI02_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Diagnosis_SecondaryCode_HI03_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Diagnosis_SecondaryCode_HI04_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_TypeCode_CN101'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_Amount_CN102'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_Percentage_CN103'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_Code_CN104'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_DiscountPercentage_CN105'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Contract_VersionID_CN106'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_ReceivedDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_ClaimNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_AdjustedClaimNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_PricingMethodology_HCP01'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_AllowedAmount_HCP02'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_SavingAmount_HCP03'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_OrganizationIdentifier_HCP04'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_FlatRateAmount_HCP05'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_ApprovedAmbulatoryPatientGroupCode_HCP06'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_RejectReasonCode_HCP13'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_PolicyComplianceCode_HCP14'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuxiliaryClaimInformation
     */
    'Repricing_ExceptionCode_HCP15'?: string | null;
}
/**
 * 
 * @export
 * @interface BatchActionRequest
 */
export interface BatchActionRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BatchActionRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BatchActionRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BatchActionRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof BatchActionRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BatchActionRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchActionRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BatchActionRequest
     */
    'ProcessAll'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchActionRequest
     */
    'ClaimList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BuildQueryRequest
 */
export interface BuildQueryRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BuildQueryRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuildQueryRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildQueryRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildQueryRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BuildQueryRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildQueryRequest
     */
    'EndDate'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimActivityLogData
 */
export interface ClaimActivityLogData {
    /**
     * 
     * @type {Array<ClaimActivityLogFieldUpdate>}
     * @memberof ClaimActivityLogData
     */
    'FieldUpdates'?: Array<ClaimActivityLogFieldUpdate> | null;
}
/**
 * 
 * @export
 * @interface ClaimActivityLogFieldUpdate
 */
export interface ClaimActivityLogFieldUpdate {
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogFieldUpdate
     */
    'FieldName'?: string | null;
    /**
     * 
     * @type {ClaimActivityLogFieldUpdateType}
     * @memberof ClaimActivityLogFieldUpdate
     */
    'UpdateType'?: ClaimActivityLogFieldUpdateType;
    /**
     * Index for the line item if the field is on a line item
     * @type {number}
     * @memberof ClaimActivityLogFieldUpdate
     */
    'LineItemIndex'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClaimActivityLogFieldUpdateType = {
    Add: 'Add',
    Update: 'Update',
    Delete: 'Delete'
} as const;

export type ClaimActivityLogFieldUpdateType = typeof ClaimActivityLogFieldUpdateType[keyof typeof ClaimActivityLogFieldUpdateType];


/**
 * 
 * @export
 * @interface ClaimActivityLogRecord
 */
export interface ClaimActivityLogRecord {
    /**
     * 
     * @type {number}
     * @memberof ClaimActivityLogRecord
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'StatusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'StatusDescriptionTooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'UserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'AddDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimActivityLogRecord
     */
    'AddDateUtc'?: string | null;
    /**
     * 
     * @type {ClaimActivityLogData}
     * @memberof ClaimActivityLogRecord
     */
    'Data'?: ClaimActivityLogData;
}
/**
 * 
 * @export
 * @interface ClaimAdjustmentInformation
 */
export interface ClaimAdjustmentInformation {
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'ClaimAdjustmentInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OtherInsuranceInformationID'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_GroupCode_CAS01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS02'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS03'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS04'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS05'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS06'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS07'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS08'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS09'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS10'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS11'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS12'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS13'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS14'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS15'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS16'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_ReasonCode_CAS17'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Amount_CAS18'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimAdjustmentInformation
     */
    'OIAdjustment_Quantity_CAS19'?: number | null;
}
/**
 * 
 * @export
 * @interface ClaimDetailsModel
 */
export interface ClaimDetailsModel {
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'CLAIM_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TRANSMIT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TRANSMIT_DATE_UTC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PROCESSED_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PROCESSED_DATE_UTC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TRANSMIT_DATE_STRING'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'STATUS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLIENT_STATUS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLIENT_REJECT_REASON_1'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'Queued'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'Status_Tooltip_Id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'Status_Description_Tooltip_Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Remarks'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REJECT_REASON_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'StatusDescriptionTooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'StatusTooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'LAST_UPDATE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'FeeTotal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatmentAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatmentCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'NpiGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PRE_D'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'HAS_ATTACHMENT'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DOS'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'EOBAvailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'IS_EP_ELIGIBILE'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'HAS_835'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'HDD_SERIAL_ID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ATTACHMENT_REF_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_DOB_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_DOB_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_DOB_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ASSIGNMENT_SIGNATURE_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'FIRST_VISIT_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PRIOR_PLACEMENT_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'APPLIANCE_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'INJURY_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Accident_Date_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TREATING_SIGNATURE_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SIGNATURE_DATE_String'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Sent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'Id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Ignore_Attachment_Requirements'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Clear_Attachment_Requirements'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'Enqueue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'USER_TYPE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'CARRIER_MATCH_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CARRIER_METHOD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLAIM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'MEDICAID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'EPSDT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SPECIALTY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PRIOR_AUTH'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CarrierAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CarrierCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CarrierState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CarrierZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientBirthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_SEX'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REL_OTHER_DESC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_EMPLOYER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_EMPLOYER_ADD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberSsn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_EMPLOYER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobFlag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DENTAL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'MEDICAL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobSsn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_PHONE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobBirthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_SEX'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_EMPLOYER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_EMPLOYER_ADD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobCarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CobCarrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_CARRIER_ADDRESS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_CARRIER_CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_CARRIER_ST'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_CARRIER_ZIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SubscriberBirthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'MARITAL_STATUS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_SEX'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_EMPLOYED'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_PART_TIME'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_FT_STUDENT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_PT_STUDENT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_EMPLOYER_SCHOOL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_EMPLOYER_SCHOOL_ADD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SIGNATURE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SIGNATURE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ASSIGNMENT_SIGNATURE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ASSIGNMENT_SIGNATURE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingProviderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BILLING_ADD_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingLicense'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'FIRST_VISIT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PLACE_OF_TREATMENT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DateLastSrp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BillingZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'RAD_YES'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'RAD_NUMBER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'RAD_NO'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ORTHO_FLAG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PROSTHESIS_INITIAL_PLACEMENT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PROSTHESIS_REPLACEMENT_REASON'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PRIOR_PLACEMENT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'APPLIANCE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TREATMENT_MONTHS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'INJURY_FLAG'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'INJURY_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ACCIDENT_TYPE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ACCIDENT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ACCIDENT_ST'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'IndividualNpi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ATTACHMENT_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'NEA_ATTACHMENT_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'WTF_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CARRIER_ADD_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'BILLING_ADD_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SUB_ADD_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAT_ADD_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_AMOUNT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'MAX_ALLOWABLE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DEDUCTIBLE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CARRIER_PERCENT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CARRIER_PAYS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PATIENT_PAYS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TREAT_TMJ'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TREAT_ORTHO'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PERIO_TYPE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SURGERY_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DIFFICULTY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REMARKS_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REMARKS_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REMARKS_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'LocumTenens'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatmentProviderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatingLicense'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TREATING_SIGNATURE_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatmentState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'TreatmentZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REJECT_REASON_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'REJECT_REASON_3'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'YEAR_ID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'WEEK_ID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'X12_LOG_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'STATUS_CHANGE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PARTNER_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SEND_METHOD1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'SEND_METHOD2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ACCOUNT_TIN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLEARINGHOUSE_CLAIM_ID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PAYER_CLAIM_ID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'LEGACY_CLAIM_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLAIM_SOURCE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLAIM_SOURCE_ID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'APP_CLAIM_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'APP_GUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ATTACHMENT_STATUS'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'IS_EP_ELIGIBLE'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DiagCodeA'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DiagCodeB'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DiagCodeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'DiagCodeD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CodeListQualifier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'CAS_HOLD'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'CUSTOMER_ID'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'USER_ID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'NEA_FACILITY_ID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'AttachmentCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'EOB_HTML'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'CLAIM_RECEIPT_HTML'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ERA_HTML'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimDetailsModel
     */
    'VALIDATION_STATUS'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'VALIDATION_RESULTS'?: string | null;
    /**
     * 
     * @type {Array<FullClaimDetails>}
     * @memberof ClaimDetailsModel
     */
    'LineItems'?: Array<FullClaimDetails> | null;
    /**
     * 
     * @type {ClaimStatusInformation}
     * @memberof ClaimDetailsModel
     */
    'StatusInformation'?: ClaimStatusInformation;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'ClaimDetailsString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'RELATIONSHIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'COB_RELATIONSHIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'AdjustmentGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'AdjustmentReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'AdjustmentAmount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PatientLiability'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimDetailsModel
     */
    'PaymentDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Process'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'Resend'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimDetailsModel
     */
    'HoldClaim'?: boolean;
    /**
     * 
     * @type {Array<ClaimStatusHistoryLineItem>}
     * @memberof ClaimDetailsModel
     */
    'StatusHistory'?: Array<ClaimStatusHistoryLineItem> | null;
    /**
     * 
     * @type {Array<ClaimActivityLogRecord>}
     * @memberof ClaimDetailsModel
     */
    'ActivityLog'?: Array<ClaimActivityLogRecord> | null;
    /**
     * Fields altered by the user before saving/sending
     * @type {Array<ClaimActivityLogFieldUpdate>}
     * @memberof ClaimDetailsModel
     */
    'FieldUpdates'?: Array<ClaimActivityLogFieldUpdate> | null;
}
/**
 * 
 * @export
 * @interface ClaimModel
 */
export interface ClaimModel {
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CustomerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'ClaimID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'PatientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'SubscriberName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Carrier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CarrierZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Remarks'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'FeeTotal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Tin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TransmitDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TransmitDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'BillingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'BillingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'NpiGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Predetermination'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'ServiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'LastUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'LastUpdateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatmentAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatmentCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Install'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'MappedStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusTooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'StatusDescriptionTooltip'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasEob'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasEra'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasClaimReceipt'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'HasAttachment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'AttachmentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'Ignored'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'Queued'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'CobFlag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'TreatingSignature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'Employer'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimModel
     */
    'FilterTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimModel
     */
    'ValidationStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimModel
     */
    'ValidationResults'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimModel
     */
    'IsRealtime'?: boolean;
}
/**
 * 
 * @export
 * @interface ClaimPrintResponse
 */
export interface ClaimPrintResponse {
    /**
     * 
     * @type {string}
     * @memberof ClaimPrintResponse
     */
    'ImageSrc'?: string | null;
}
/**
 * Error status for sending/saving claims
 * @export
 * @enum {string}
 */

export const ClaimSaveSubmitErrorStatus = {
    BadRequest: 'BadRequest',
    T2ValidationFailure: 'T2ValidationFailure',
    FailedCarrierLookup: 'FailedCarrierLookup'
} as const;

export type ClaimSaveSubmitErrorStatus = typeof ClaimSaveSubmitErrorStatus[keyof typeof ClaimSaveSubmitErrorStatus];


/**
 * 
 * @export
 * @interface ClaimSaveSubmitProblemDetails
 */
export interface ClaimSaveSubmitProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'instance'?: string | null;
    /**
     * 
     * @type {ClaimSaveSubmitResponse}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'ClaimResponse'?: ClaimSaveSubmitResponse;
    /**
     * 
     * @type {ClaimSaveSubmitErrorStatus}
     * @memberof ClaimSaveSubmitProblemDetails
     */
    'ErrorStatus'?: ClaimSaveSubmitErrorStatus;
}
/**
 * 
 * @export
 * @interface ClaimSaveSubmitResponse
 */
export interface ClaimSaveSubmitResponse {
    /**
     * 
     * @type {number}
     * @memberof ClaimSaveSubmitResponse
     */
    'ClaimId'?: number;
    /**
     * 
     * @type {ClaimValidationResult}
     * @memberof ClaimSaveSubmitResponse
     */
    'ClaimValidationResult'?: ClaimValidationResult;
    /**
     * 
     * @type {string}
     * @memberof ClaimSaveSubmitResponse
     */
    'ClaimStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimSettingsResponse
 */
export interface ClaimSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimSettingsResponse
     */
    'Isolate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimSettingsResponse
     */
    'ManualClaimEntry'?: boolean;
    /**
     * 
     * @type {Array<SerialOption>}
     * @memberof ClaimSettingsResponse
     */
    'Serials'?: Array<SerialOption> | null;
}
/**
 * 
 * @export
 * @interface ClaimStatusCodes
 */
export interface ClaimStatusCodes {
    /**
     * 
     * @type {number}
     * @memberof ClaimStatusCodes
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'Code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'DescriptionOverride'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'Comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'Tooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'VyneTrellisTab'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'VyneTrellisField'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'TrellisFieldCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'FieldNameAttribute'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusCodes
     */
    'Source'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimStatusHistoryLineItem
 */
export interface ClaimStatusHistoryLineItem {
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusHistoryLineItem
     */
    'Status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusHistoryLineItem
     */
    'Tooltip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusHistoryLineItem
     */
    'StatusDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusHistoryLineItem
     */
    'StatusDateUtc'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimStatusInformation
 */
export interface ClaimStatusInformation {
    /**
     * 
     * @type {Array<ClaimStatusHistoryLineItem>}
     * @memberof ClaimStatusInformation
     */
    'StatusHistory'?: Array<ClaimStatusHistoryLineItem> | null;
}
/**
 * 
 * @export
 * @interface ClaimStatusSummaryItem
 */
export interface ClaimStatusSummaryItem {
    /**
     * 
     * @type {string}
     * @memberof ClaimStatusSummaryItem
     */
    'Status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimStatusSummaryItem
     */
    'Count'?: number;
}
/**
 * 
 * @export
 * @interface ClaimTransaction
 */
export interface ClaimTransaction {
    /**
     * 
     * @type {DentalClaims}
     * @memberof ClaimTransaction
     */
    'DentalClaims'?: DentalClaims;
    /**
     * 
     * @type {AuxiliaryClaimInformation}
     * @memberof ClaimTransaction
     */
    'AuxiliaryClaimInformation'?: AuxiliaryClaimInformation;
    /**
     * 
     * @type {Array<RemarksInformation>}
     * @memberof ClaimTransaction
     */
    'RemarksInformationList'?: Array<RemarksInformation> | null;
    /**
     * 
     * @type {Array<SupplementalInformation>}
     * @memberof ClaimTransaction
     */
    'SupplementalInformationList'?: Array<SupplementalInformation> | null;
    /**
     * 
     * @type {Array<ToothStatusInformation>}
     * @memberof ClaimTransaction
     */
    'ToothStatusInformationList'?: Array<ToothStatusInformation> | null;
    /**
     * 
     * @type {Array<TesiaOtherInsuranceInformation>}
     * @memberof ClaimTransaction
     */
    'TesiaOtherInsuranceInformationList'?: Array<TesiaOtherInsuranceInformation> | null;
    /**
     * 
     * @type {Array<TesiaServiceLine>}
     * @memberof ClaimTransaction
     */
    'TesiaServicesLineList'?: Array<TesiaServiceLine> | null;
    /**
     * 
     * @type {Array<OtherProviderInformation>}
     * @memberof ClaimTransaction
     */
    'OtherProviderInformationList'?: Array<OtherProviderInformation> | null;
    /**
     * 
     * @type {Array<FileInformation>}
     * @memberof ClaimTransaction
     */
    'FileInformationList'?: Array<FileInformation> | null;
}
/**
 * 
 * @export
 * @interface ClaimValidationCode
 */
export interface ClaimValidationCode {
    /**
     * 
     * @type {string}
     * @memberof ClaimValidationCode
     */
    'Code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimValidationCode
     */
    'ServiceLine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimValidationCode
     */
    'EntityCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimValidationCode
     */
    'StatusCategoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimValidationCode
     */
    'StatusMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimValidationResult
 */
export interface ClaimValidationResult {
    /**
     * 
     * @type {ClaimValidationStatus}
     * @memberof ClaimValidationResult
     */
    'ValidationStatus'?: ClaimValidationStatus;
    /**
     * 
     * @type {Array<ClaimValidationCode>}
     * @memberof ClaimValidationResult
     */
    'ValidationCodes'?: Array<ClaimValidationCode> | null;
    /**
     * 
     * @type {number}
     * @memberof ClaimValidationResult
     */
    'ApiErrorCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimValidationResult
     */
    'ValidationToken'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClaimValidationStatus = {
    Success: 'Success',
    Failed: 'Failed',
    Unknown: 'Unknown'
} as const;

export type ClaimValidationStatus = typeof ClaimValidationStatus[keyof typeof ClaimValidationStatus];


/**
 * 
 * @export
 * @interface ClaimsDeleteRequest
 */
export interface ClaimsDeleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsDeleteRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsDeleteRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsDeleteRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsDeleteRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsDeleteRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsDeleteRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsDeleteResponse
 */
export interface ClaimsDeleteResponse {
    /**
     * 
     * @type {Array<DeleteClaimResponse>}
     * @memberof ClaimsDeleteResponse
     */
    'claimResponses'?: Array<DeleteClaimResponse> | null;
}
/**
 * 
 * @export
 * @interface ClaimsExportRequest
 */
export interface ClaimsExportRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsExportRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsExportRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsExportRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsExportRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsExportRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClaimsExportRequest
     */
    'ColumnList': Array<string>;
}
/**
 * 
 * @export
 * @interface ClaimsExportResponse
 */
export interface ClaimsExportResponse {
    /**
     * 
     * @type {string}
     * @memberof ClaimsExportResponse
     */
    'CsvLink'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimsIgnoreAttachmentRequirementsRequest
 */
export interface ClaimsIgnoreAttachmentRequirementsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsIgnoreAttachmentRequirementsRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsIgnoreRequest
 */
export interface ClaimsIgnoreRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsIgnoreRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsIgnoreRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsIgnoreRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'MarkAsVisible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsIgnoreRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsIgnoreRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ClaimsPrintRequest
 */
export interface ClaimsPrintRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsPrintRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsPrintRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsPrintRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsPrintRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsPrintRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClaimsPrintRequest
     */
    'ColumnList': Array<string>;
}
/**
 * 
 * @export
 * @interface ClaimsPrintResponse
 */
export interface ClaimsPrintResponse {
    /**
     * 
     * @type {string}
     * @memberof ClaimsPrintResponse
     */
    'PdfLink'?: string | null;
}
/**
 * 
 * @export
 * @interface ClaimsProcessAllResponse
 */
export interface ClaimsProcessAllResponse {
    /**
     * 
     * @type {number}
     * @memberof ClaimsProcessAllResponse
     */
    'SuccessCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsProcessAllResponse
     */
    'FailureCount'?: number;
}
/**
 * 
 * @export
 * @interface ClaimsStatusRequest
 */
export interface ClaimsStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'Queued'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'Hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClaimsStatusRequest
     */
    'Range'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimsStatusRequest
     */
    'Offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SortColumn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SortDirection'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ClaimsStatusRequest
     */
    'Filters'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'SearchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'Serial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'StartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClaimsStatusRequest
     */
    'EndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'HoldClaims': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimsStatusRequest
     */
    'ProcessAll': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClaimsStatusRequest
     */
    'ClaimList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteClaimResponse
 */
export interface DeleteClaimResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteClaimResponse
     */
    'claimId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteClaimResponse
     */
    'result'?: number;
}
/**
 * 
 * @export
 * @interface DentalClaims
 */
export interface DentalClaims {
    /**
     * 
     * @type {number}
     * @memberof DentalClaims
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_TaxonomyCode_PRV03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_CurrencyCode_CUR02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_NPI_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_TINQualifier_REF01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_TIN_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_License_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_UPIN_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_LocationID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_SiteNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_ContactName_PER02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Phone_PER00'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_PhoneExtension_PER00'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_Email_PER00'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'BillingProvider_FaxNumber_PER00'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_PayerResponsibilitySequenceCode_SBR01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_GroupNumber_SBR03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_GroupName_SBR04'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_InsuranceTypeCode_SBR05'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_ClaimFilingIndicatorCode_SBR09'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Type_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_IDQualifier_NM108'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_ID_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_BirthDate_DMG02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_Gender_DMG03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Subscriber_SocialSecurityNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_Name_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_IDQualifier_NM108'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_ID_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_IdentificationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_EmployerIdentificationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_ClaimOfficeNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_NAICCode_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_BillingProviderID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Payer_BillingProviderLocationID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_RelationshipToSubscriber_SBR02_PAT01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_StudentStatusCode_PAT04'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_BirthDate_DMG02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Patient_Gender_DMG03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PatientAccountNumber_CLM01'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalClaims
     */
    'Claim_ChargeAmount_CLM02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PlaceOfService_CLM05_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_FrequencyCode_CLM05_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_ProviderSignatureIndicator_CLM06'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PlanParticipationIndicator_CLM07'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_BenefitsAssignedIndicator_CLM08'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_ReleaseOfInformationCode_CLM09'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_RelatedCausesCode_CLM11_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_RelatedCausesCode_CLM11_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_AutoAccidentState_CLM11_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_AutoAccidentCountry_CLM11_5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_SpecialProgramIndicator_CLM12'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PredeterminationCode_CLM19'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_DelayReasonCode_CLM20'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_AccidentDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_DateOfService_DTP03'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalClaims
     */
    'Claim_PatientPaidAmount_AMT02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PredeterminationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_ServiceAuthorizationExceptionCode_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PayerOriginalClaimNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_ReferralNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_PriorAuthorizationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'Claim_ClearinghouseTraceNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_NPI_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_TaxonomyCode_PRV03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_License_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_UPIN_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_CommercialID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'RenderingProvider_LocationID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_Name_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_NPI_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_CountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_License_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_CommercialID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalClaims
     */
    'ServiceFacility_LocationID_REF02'?: string | null;
}
/**
 * 
 * @export
 * @interface DentalServiceLines
 */
export interface DentalServiceLines {
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'DentalServiceLinesID'?: number;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_LineNumber_LX01'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCode_SV301_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCodeModifier_SV301_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCodeModifier_SV301_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCodeModifier_SV301_5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCodeModifier_SV301_6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCodeDescription_SV301_7'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureChargeAmount_SV302'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedurePlaceOfService_SV303'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_OralCavityCode_SV304_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_OralCavityCode_SV304_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_OralCavityCode_SV304_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_OralCavityCode_SV304_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_OralCavityCode_SV304_5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProsthesisCrownOrInlayCode_SV305'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureCount_SV306'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_DiagnosisCodePointer_SV311_1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_DiagnosisCodePointer_SV311_2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_DiagnosisCodePointer_SV311_3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_DiagnosisCodePointer_SV311_4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ProcedureDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_EstimatedPriorPlacementDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_PriorPlacementDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_AppliancePlacementDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ReplacementDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_TreatmentStartDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_TreatmentEndDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_PredeterminationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_PriorAuthorizationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_LineControlNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SL_ReferralNumber_REF02'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SL_SalesTaxAmount_AMT02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLContract_TypeCode_CN101'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLContract_Amount_CN102'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLContract_Percentage_CN103'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLContract_Code_CN104'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLContract_DiscountPercentage_CN105'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLContract_VersionID_CN106'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_ClaimNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_AdjustedClaimNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_PricingMethodology_HCP01'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLRepricing_AllowedAmount_HCP02'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLRepricing_SavingsAmount_HCP03'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_OrganizationIdentifier_HCP04'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLRepricing_FlatRateAmount_HCP05'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_ApprovedHCPCSCode_HCP10'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DentalServiceLines
     */
    'SLRepricing_ApprovedUnitCount_HCP12'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_RejectReasonCode_HCP13'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_PolicyComplianceCode_HCP14'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DentalServiceLines
     */
    'SLRepricing_ExceptionCode_HCP15'?: string | null;
}
/**
 * 
 * @export
 * @interface FileInformation
 */
export interface FileInformation {
    /**
     * 
     * @type {number}
     * @memberof FileInformation
     */
    'FileInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInformation
     */
    'File_FixedFormatInformation_K301'?: string | null;
}
/**
 * 
 * @export
 * @interface FilterDropdowns
 */
export interface FilterDropdowns {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Carrier'?: Array<string> | null;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof FilterDropdowns
     */
    'Queued'?: Array<boolean> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'BillingAddress'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Tin'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Install'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatmentAddress'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatmentCity'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'BillingCity'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'TreatingSignature'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'CobFlag'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'NpiGroup'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Predetermination'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Username'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDropdowns
     */
    'Status'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface FullClaimDetails
 */
export interface FullClaimDetails {
    /**
     * 
     * @type {number}
     * @memberof FullClaimDetails
     */
    'CLAIM_ID'?: number;
    /**
     * 
     * @type {number}
     * @memberof FullClaimDetails
     */
    'LineNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ProcedureDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'SERVICE_DATE_String'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'OralCavityCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ToothSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ToothNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ToothSurfaces'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'DiagPointer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ProcedureCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'Quantity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ProcedureCodeDesc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullClaimDetails
     */
    'ProcedureChargeAmount'?: string | null;
}
/**
 * 
 * @export
 * @interface GetClaimsFiltersResponse
 */
export interface GetClaimsFiltersResponse {
    /**
     * 
     * @type {number}
     * @memberof GetClaimsFiltersResponse
     */
    'FilteredClaims'?: number;
    /**
     * 
     * @type {FilterDropdowns}
     * @memberof GetClaimsFiltersResponse
     */
    'FilterDropdowns'?: FilterDropdowns;
}
/**
 * 
 * @export
 * @interface GetLetterOfTimelyFilingResponse
 */
export interface GetLetterOfTimelyFilingResponse {
    /**
     * 
     * @type {WebServiceResult}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'WSResult'?: WebServiceResult;
    /**
     * 
     * @type {number}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'LetterOfTimelyFilingHtml'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CARRIER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CARRIER_ADD'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CARRIER_CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CARRIER_ST'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'CARRIER_ZIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'TREATING_SIGNATURE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'TREATING_LICENSE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'TIN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'PAT_NAME'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'DOS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'TRANSMIT_DATE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'FEE_TOTAL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'STATUS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLetterOfTimelyFilingResponse
     */
    'PARTNER_ID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPaginatedClaimsResponse
 */
export interface GetPaginatedClaimsResponse {
    /**
     * 
     * @type {Array<ClaimModel>}
     * @memberof GetPaginatedClaimsResponse
     */
    'Claims'?: Array<ClaimModel> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedClaimsResponse
     */
    'FilteredClaims'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedClaimsResponse
     */
    'TotalClaims'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetPaginatedClaimsResponse
     */
    'RealTimeCarriers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPaginatedClaimsResponse
     */
    'HasUnsentClaims'?: boolean;
    /**
     * 
     * @type {FilterDropdowns}
     * @memberof GetPaginatedClaimsResponse
     */
    'FilterDropdowns'?: FilterDropdowns;
    /**
     * 
     * @type {Array<ClaimStatusSummaryItem>}
     * @memberof GetPaginatedClaimsResponse
     */
    'QueuedStatusSummary'?: Array<ClaimStatusSummaryItem> | null;
}
/**
 * 
 * @export
 * @interface LineAdjustmentInformation
 */
export interface LineAdjustmentInformation {
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'LineAdjustmentInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'LineOtherInsuranceInformationID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_GroupCode_CAS01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS02'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS03'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS04'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS05'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS06'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS07'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS08'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS09'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS10'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS11'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS12'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS13'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS14'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS15'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS16'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_ReasonCode_CAS17'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Amount_CAS18'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineAdjustmentInformation
     */
    'SLOIAdjustment_Quantity_CAS19'?: number | null;
}
/**
 * 
 * @export
 * @interface LineFileInformation
 */
export interface LineFileInformation {
    /**
     * 
     * @type {number}
     * @memberof LineFileInformation
     */
    'LineFileInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineFileInformation
     */
    'DentalServiceLinesID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineFileInformation
     */
    'SLFile_FixedFormatInformation_K301'?: string | null;
}
/**
 * 
 * @export
 * @interface LineOtherInsuranceInformation
 */
export interface LineOtherInsuranceInformation {
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'LineOtherInsuranceInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'DentalServiceLinesID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerID_SVD01'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerPaidAmount_SVD02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureCode_SVD03_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureModifier_SVD03_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureModifier_SVD03_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureModifier_SVD03_5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureModifier_SVD03_6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureDescription_SVD03_7'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PayerAdjudicatedProcedureCount_SVD05'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_OriginalLineNumber_SVD06'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PaymentDate_DTP03'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_RemainingPatientLiability_AMT02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PredeterminationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_PriorAuthorizationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineOtherInsuranceInformation
     */
    'SLOI_ReferralNumber_REF02'?: string | null;
}
/**
 * 
 * @export
 * @interface LineProviderIdentifierInformation
 */
export interface LineProviderIdentifierInformation {
    /**
     * 
     * @type {number}
     * @memberof LineProviderIdentifierInformation
     */
    'LineProviderIdentifierInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineProviderIdentifierInformation
     */
    'LineProviderInformationID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineProviderIdentifierInformation
     */
    'SLProvider_IdentifierQualifier_REF01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderIdentifierInformation
     */
    'SLProvider_Identifier_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderIdentifierInformation
     */
    'SLProvider_OIPayerID_REF04_2'?: string | null;
}
/**
 * 
 * @export
 * @interface LineProviderInformation
 */
export interface LineProviderInformation {
    /**
     * 
     * @type {number}
     * @memberof LineProviderInformation
     */
    'LineProviderInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineProviderInformation
     */
    'DentalServiceLinesID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_EntityCode_NM101'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_NPI_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_ProviderCode_PRV01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_TaxonomyCode_PRV03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_Street1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_Street2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_City_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_State_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_Zip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_Country_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineProviderInformation
     */
    'SLProvider_CountrySubdivision_N407'?: string | null;
}
/**
 * 
 * @export
 * @interface LineToothInformation
 */
export interface LineToothInformation {
    /**
     * 
     * @type {number}
     * @memberof LineToothInformation
     */
    'LineToothInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof LineToothInformation
     */
    'DentalServiceLinesID'?: number;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Number_TOO02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Surface_TOO03_1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Surface_TOO03_2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Surface_TOO03_3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Surface_TOO03_4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LineToothInformation
     */
    'SLTooth_Surface_TOO03_5'?: string | null;
}
/**
 * 
 * @export
 * @interface OIPayerProviderIdentifierInformation
 */
export interface OIPayerProviderIdentifierInformation {
    /**
     * 
     * @type {number}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OIPayerProviderIdentifierInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OtherInsuranceInformationID'?: number;
    /**
     * 
     * @type {string}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OIPayerProvider_EntityCode_NM101'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OIPayerProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OIPayerProvider_IdentifierQualifer_REF01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OIPayerProviderIdentifierInformation
     */
    'OIPayerProvider_Identifier_REF02'?: string | null;
}
/**
 * 
 * @export
 * @interface OtherInsuranceInformation
 */
export interface OtherInsuranceInformation {
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OtherInsuranceInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerResponsibilitySequenceCode_SBR01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_RelationshipCode_SBR02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_GroupNumber_SBR03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_GroupName_SBR04'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_InsuranceTypeCode_SBR05'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimFilingIndicatorCode_SBR09'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPayerPaidAmount_AMT02'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimRemainingPatientLiabilityAmount_AMT02'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimNonCoveredChargeAmount_AMT02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberBirthDate_DMG02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberGender_DMG03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_BenefitsAssignedIndicator_OI03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ReleaseOfInformationIndicator_OI06'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimReimbursementRate_MOA01'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimHCPCSPayableAmount_MOA02'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPaymentRemarkCode_MOA03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPaymentRemarkCode_MOA04'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPaymentRemarkCode_MOA05'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPaymentRemarkCode_MOA06'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimPaymentRemarkCode_MOA07'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtherInsuranceInformation
     */
    'OI_ClaimNonPayableAmount_MOA09'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberEntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberLastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberFirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberMiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberSuffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberIDQualifier_NM108'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberID_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberStreet1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberStreet2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberCity_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberState_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberZip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberCountry_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberCountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_SubscriberSocialSecurityNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerIDQualifier_NM108'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerID_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerStreet1_N301'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerStreet2_N302'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerCity_N401'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerState_N402'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerZip_N403'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerCountry_N404'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerCountrySubdivision_N407'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerPaymentDate_DTP03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerIdentificationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerEmployerIdentificationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerClaimOfficeNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerNAICCode_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerPriorAuthorizationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerReferralNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerClaimAdjustmentIndicator_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerPredeterminationNumber_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherInsuranceInformation
     */
    'OI_PayerClaimNumber_REF02'?: string | null;
}
/**
 * 
 * @export
 * @interface OtherProviderInformation
 */
export interface OtherProviderInformation {
    /**
     * 
     * @type {number}
     * @memberof OtherProviderInformation
     */
    'OtherProviderInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherProviderInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_EntityCode_NM101'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_EntityType_NM102'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_LastName_NM103'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_FirstName_NM104'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_MiddleName_NM105'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_Suffix_NM107'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_NPI_NM109'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_TaxonomyCode_PRV03'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_License_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_UPIN_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_CommercialID_REF02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtherProviderInformation
     */
    'OtherProvider_LocationID_REF02'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface QueueProcessClaimsResponse
 */
export interface QueueProcessClaimsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof QueueProcessClaimsResponse
     */
    'ClaimIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface QueuedClaimStatusSummaryResponse
 */
export interface QueuedClaimStatusSummaryResponse {
    /**
     * 
     * @type {Array<ClaimStatusSummaryItem>}
     * @memberof QueuedClaimStatusSummaryResponse
     */
    'ClaimStatusList'?: Array<ClaimStatusSummaryItem> | null;
}
/**
 * 
 * @export
 * @interface RemarksInformation
 */
export interface RemarksInformation {
    /**
     * 
     * @type {number}
     * @memberof RemarksInformation
     */
    'RemarksInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof RemarksInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemarksInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemarksInformation
     */
    'Remarks_Note_NTE02'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchArchivedClaimsRequest
 */
export interface SearchArchivedClaimsRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchArchivedClaimsRequest
     */
    'MinDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchArchivedClaimsRequest
     */
    'MaxDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchArchivedClaimsRequest
     */
    'ArchiveText'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchArchivedClaimsResponse
 */
export interface SearchArchivedClaimsResponse {
    /**
     * 
     * @type {Array<ArchiveClaim>}
     * @memberof SearchArchivedClaimsResponse
     */
    'Data'?: Array<ArchiveClaim> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchArchivedClaimsResponse
     */
    'ReturnCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchArchivedClaimsResponse
     */
    'ReturnCodeDesc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchArchivedClaimsResponse
     */
    'Result'?: number | null;
}
/**
 * 
 * @export
 * @interface SerialOption
 */
export interface SerialOption {
    /**
     * 
     * @type {string}
     * @memberof SerialOption
     */
    'Serial_ID': string;
    /**
     * 
     * @type {string}
     * @memberof SerialOption
     */
    'PC_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SerialOption
     */
    'Serial_Nickname': string;
}
/**
 * 
 * @export
 * @interface SupplementalInformation
 */
export interface SupplementalInformation {
    /**
     * 
     * @type {number}
     * @memberof SupplementalInformation
     */
    'SupplementalInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplementalInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplementalInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalInformation
     */
    'Attachment_TypeCode_PWK01'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalInformation
     */
    'Attachment_TransmissionCode_PWK02'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplementalInformation
     */
    'Attachment_ID_PWK06'?: string | null;
}
/**
 * 
 * @export
 * @interface TesiaOtherInsuranceInformation
 */
export interface TesiaOtherInsuranceInformation {
    /**
     * 
     * @type {OtherInsuranceInformation}
     * @memberof TesiaOtherInsuranceInformation
     */
    'OtherInsuranceInformation'?: OtherInsuranceInformation;
    /**
     * 
     * @type {Array<ClaimAdjustmentInformation>}
     * @memberof TesiaOtherInsuranceInformation
     */
    'ClaimAdjustmentInformationList'?: Array<ClaimAdjustmentInformation> | null;
    /**
     * 
     * @type {Array<OIPayerProviderIdentifierInformation>}
     * @memberof TesiaOtherInsuranceInformation
     */
    'OIPayerProviderIdentifierInformationList'?: Array<OIPayerProviderIdentifierInformation> | null;
}
/**
 * 
 * @export
 * @interface TesiaServiceLine
 */
export interface TesiaServiceLine {
    /**
     * 
     * @type {DentalServiceLines}
     * @memberof TesiaServiceLine
     */
    'DentalServiceLines'?: DentalServiceLines;
    /**
     * 
     * @type {Array<LineToothInformation>}
     * @memberof TesiaServiceLine
     */
    'LineToothInformationList'?: Array<LineToothInformation> | null;
    /**
     * 
     * @type {Array<TesiaServiceLineOtherInsuranceWithAdjustmentInformation>}
     * @memberof TesiaServiceLine
     */
    'TesiaServiceLineOtherInsuranceWithAdjustmentInformationList'?: Array<TesiaServiceLineOtherInsuranceWithAdjustmentInformation> | null;
    /**
     * 
     * @type {Array<LineFileInformation>}
     * @memberof TesiaServiceLine
     */
    'LineFileInformationList'?: Array<LineFileInformation> | null;
    /**
     * 
     * @type {Array<TesiaServiceLineProviderWithIdentifier>}
     * @memberof TesiaServiceLine
     */
    'TesiaServiceLineProviderWithIdentifierList'?: Array<TesiaServiceLineProviderWithIdentifier> | null;
}
/**
 * 
 * @export
 * @interface TesiaServiceLineOtherInsuranceWithAdjustmentInformation
 */
export interface TesiaServiceLineOtherInsuranceWithAdjustmentInformation {
    /**
     * 
     * @type {LineOtherInsuranceInformation}
     * @memberof TesiaServiceLineOtherInsuranceWithAdjustmentInformation
     */
    'LineOtherInsuranceInformation'?: LineOtherInsuranceInformation;
    /**
     * 
     * @type {Array<LineAdjustmentInformation>}
     * @memberof TesiaServiceLineOtherInsuranceWithAdjustmentInformation
     */
    'LineAdjustmentInformationList'?: Array<LineAdjustmentInformation> | null;
}
/**
 * 
 * @export
 * @interface TesiaServiceLineProviderWithIdentifier
 */
export interface TesiaServiceLineProviderWithIdentifier {
    /**
     * 
     * @type {LineProviderInformation}
     * @memberof TesiaServiceLineProviderWithIdentifier
     */
    'LineProviderInformation'?: LineProviderInformation;
    /**
     * 
     * @type {Array<LineProviderIdentifierInformation>}
     * @memberof TesiaServiceLineProviderWithIdentifier
     */
    'LineProviderIdentifierInformationList'?: Array<LineProviderIdentifierInformation> | null;
}
/**
 * 
 * @export
 * @interface ToothStatusInformation
 */
export interface ToothStatusInformation {
    /**
     * 
     * @type {number}
     * @memberof ToothStatusInformation
     */
    'ToothStatusInformationID'?: number;
    /**
     * 
     * @type {number}
     * @memberof ToothStatusInformation
     */
    'DentalClaimsID'?: number;
    /**
     * 
     * @type {string}
     * @memberof ToothStatusInformation
     */
    'TransactionToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToothStatusInformation
     */
    'Tooth_Number_DN201'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ToothStatusInformation
     */
    'Tooth_StatusCode_DN202'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateClaimSettingsRequest
 */
export interface UpdateClaimSettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClaimSettingsRequest
     */
    'Isolate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClaimSettingsRequest
     */
    'ManualClaimEntry'?: boolean;
}
/**
 * 
 * @export
 * @interface WebServiceResult
 */
export interface WebServiceResult {
    /**
     * 
     * @type {number}
     * @memberof WebServiceResult
     */
    'Result'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebServiceResult
     */
    'ReturnCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebServiceResult
     */
    'ReturnCodeDesc'?: string | null;
}

/**
 * ArchiveClaimsApi - axios parameter creator
 * @export
 */
export const ArchiveClaimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveClaimsGetLetterOfTimelyFiling: async (customerId: number, claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('archiveClaimsGetLetterOfTimelyFiling', 'customerId', customerId)
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('archiveClaimsGetLetterOfTimelyFiling', 'claimId', claimId)
            const localVarPath = `/{customerId}/archiveclaims/{claimId}/LetterOfTimelyFiling`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchArchivedClaimsRequest} [searchArchivedClaimsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveClaimsSearchArchiveClaims: async (customerId: number, searchArchivedClaimsRequest?: SearchArchivedClaimsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('archiveClaimsSearchArchiveClaims', 'customerId', customerId)
            const localVarPath = `/{customerId}/archiveclaims/search`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchArchivedClaimsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchiveClaimsApi - functional programming interface
 * @export
 */
export const ArchiveClaimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchiveClaimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveClaimsGetLetterOfTimelyFiling(customerId: number, claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLetterOfTimelyFilingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveClaimsGetLetterOfTimelyFiling(customerId, claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchArchivedClaimsRequest} [searchArchivedClaimsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveClaimsSearchArchiveClaims(customerId: number, searchArchivedClaimsRequest?: SearchArchivedClaimsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArchivedClaimsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveClaimsSearchArchiveClaims(customerId, searchArchivedClaimsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArchiveClaimsApi - factory interface
 * @export
 */
export const ArchiveClaimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchiveClaimsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveClaimsGetLetterOfTimelyFiling(customerId: number, claimId: number, options?: any): AxiosPromise<GetLetterOfTimelyFilingResponse> {
            return localVarFp.archiveClaimsGetLetterOfTimelyFiling(customerId, claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {SearchArchivedClaimsRequest} [searchArchivedClaimsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveClaimsSearchArchiveClaims(customerId: number, searchArchivedClaimsRequest?: SearchArchivedClaimsRequest, options?: any): AxiosPromise<SearchArchivedClaimsResponse> {
            return localVarFp.archiveClaimsSearchArchiveClaims(customerId, searchArchivedClaimsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchiveClaimsApi - object-oriented interface
 * @export
 * @class ArchiveClaimsApi
 * @extends {BaseAPI}
 */
export class ArchiveClaimsApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchiveClaimsApi
     */
    public archiveClaimsGetLetterOfTimelyFiling(customerId: number, claimId: number, options?: AxiosRequestConfig) {
        return ArchiveClaimsApiFp(this.configuration).archiveClaimsGetLetterOfTimelyFiling(customerId, claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {SearchArchivedClaimsRequest} [searchArchivedClaimsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchiveClaimsApi
     */
    public archiveClaimsSearchArchiveClaims(customerId: number, searchArchivedClaimsRequest?: SearchArchivedClaimsRequest, options?: AxiosRequestConfig) {
        return ArchiveClaimsApiFp(this.configuration).archiveClaimsSearchArchiveClaims(customerId, searchArchivedClaimsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimApi - axios parameter creator
 * @export
 */
export const ClaimApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCreateClaimPrintAdaForm: async (claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimCreateClaimPrintAdaForm', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/print-ada-form`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimDeleteClaim: async (claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimDeleteClaim', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a claim
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaim: async (claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetClaim', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/details`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimT2: async (claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimGetClaimT2', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/T2`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimPostClaim: async (claimDetailsModel: ClaimDetailsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimDetailsModel' is not null or undefined
            assertParamExists('claimPostClaim', 'claimDetailsModel', claimDetailsModel)
            const localVarPath = `/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimDetailsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimResubmitAttachmentClaimId: async (claimId: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimResubmitAttachmentClaimId', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/saved/attachment-resubmit`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimUpdateClaim: async (claimId: number, claimDetailsModel: ClaimDetailsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimUpdateClaim', 'claimId', claimId)
            // verify required parameter 'claimDetailsModel' is not null or undefined
            assertParamExists('claimUpdateClaim', 'claimDetailsModel', claimDetailsModel)
            const localVarPath = `/claim/{claimId}`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimDetailsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimValidateClaim: async (claimId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimId' is not null or undefined
            assertParamExists('claimValidateClaim', 'claimId', claimId)
            const localVarPath = `/claim/{claimId}/Validate`
                .replace(`{${"claimId"}}`, encodeURIComponent(String(claimId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimApi - functional programming interface
 * @export
 */
export const ClaimApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimCreateClaimPrintAdaForm(claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimPrintResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimCreateClaimPrintAdaForm(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimDeleteClaim(claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimDeleteClaim(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a claim
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetClaim(claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetClaim(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimGetClaimT2(claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimGetClaimT2(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimPostClaim(claimDetailsModel: ClaimDetailsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimPostClaim(claimDetailsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimResubmitAttachmentClaimId(claimId: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimResubmitAttachmentClaimId(claimId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimUpdateClaim(claimId: number, claimDetailsModel: ClaimDetailsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimSaveSubmitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimUpdateClaim(claimId, claimDetailsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimValidateClaim(claimId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimValidateClaim(claimId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimApi - factory interface
 * @export
 */
export const ClaimApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimApiFp(configuration)
    return {
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimCreateClaimPrintAdaForm(claimId: number, options?: any): AxiosPromise<ClaimPrintResponse> {
            return localVarFp.claimCreateClaimPrintAdaForm(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimDeleteClaim(claimId: number, options?: any): AxiosPromise<void> {
            return localVarFp.claimDeleteClaim(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a claim
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaim(claimId: number, options?: any): AxiosPromise<ClaimDetailsModel> {
            return localVarFp.claimGetClaim(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimGetClaimT2(claimId: number, options?: any): AxiosPromise<ClaimTransaction> {
            return localVarFp.claimGetClaimT2(claimId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimPostClaim(claimDetailsModel: ClaimDetailsModel, options?: any): AxiosPromise<number> {
            return localVarFp.claimPostClaim(claimDetailsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimResubmitAttachmentClaimId(claimId: number, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.claimResubmitAttachmentClaimId(claimId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {ClaimDetailsModel} claimDetailsModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimUpdateClaim(claimId: number, claimDetailsModel: ClaimDetailsModel, options?: any): AxiosPromise<ClaimSaveSubmitResponse> {
            return localVarFp.claimUpdateClaim(claimId, claimDetailsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} claimId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimValidateClaim(claimId: number, options?: any): AxiosPromise<ClaimTransaction> {
            return localVarFp.claimValidateClaim(claimId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimApi - object-oriented interface
 * @export
 * @class ClaimApi
 * @extends {BaseAPI}
 */
export class ClaimApi extends BaseAPI {
    /**
     * 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimCreateClaimPrintAdaForm(claimId: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimCreateClaimPrintAdaForm(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimDeleteClaim(claimId: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimDeleteClaim(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a claim
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetClaim(claimId: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetClaim(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimGetClaimT2(claimId: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimGetClaimT2(claimId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClaimDetailsModel} claimDetailsModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimPostClaim(claimDetailsModel: ClaimDetailsModel, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimPostClaim(claimDetailsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimResubmitAttachmentClaimId(claimId: number, customerId?: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimResubmitAttachmentClaimId(claimId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {ClaimDetailsModel} claimDetailsModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimUpdateClaim(claimId: number, claimDetailsModel: ClaimDetailsModel, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimUpdateClaim(claimId, claimDetailsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} claimId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimApi
     */
    public claimValidateClaim(claimId: number, options?: AxiosRequestConfig) {
        return ClaimApiFp(this.configuration).claimValidateClaim(claimId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimResourcesApi - axios parameter creator
 * @export
 */
export const ClaimResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimResourcesGetStatusCodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources/ClaimStatusCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimResourcesApi - functional programming interface
 * @export
 */
export const ClaimResourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimResourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimResourcesGetStatusCodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClaimStatusCodes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimResourcesGetStatusCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimResourcesApi - factory interface
 * @export
 */
export const ClaimResourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimResourcesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimResourcesGetStatusCodes(options?: any): AxiosPromise<Array<ClaimStatusCodes>> {
            return localVarFp.claimResourcesGetStatusCodes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimResourcesApi - object-oriented interface
 * @export
 * @class ClaimResourcesApi
 * @extends {BaseAPI}
 */
export class ClaimResourcesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimResourcesApi
     */
    public claimResourcesGetStatusCodes(options?: AxiosRequestConfig) {
        return ClaimResourcesApiFp(this.configuration).claimResourcesGetStatusCodes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimSettingsApi - axios parameter creator
 * @export
 */
export const ClaimSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsGetClaimSettings: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimSettingsGetClaimSettings', 'customerId', customerId)
            const localVarPath = `/{customerId}/Settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateClaimSettingsRequest} [updateClaimSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsUpdateClaimSettings: async (customerId: number, updateClaimSettingsRequest?: UpdateClaimSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimSettingsUpdateClaimSettings', 'customerId', customerId)
            const localVarPath = `/{customerId}/Settings`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClaimSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} serialId 
         * @param {SerialOption} [serialOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsUpdateSerialOption: async (customerId: number, serialId: string, serialOption?: SerialOption, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimSettingsUpdateSerialOption', 'customerId', customerId)
            // verify required parameter 'serialId' is not null or undefined
            assertParamExists('claimSettingsUpdateSerialOption', 'serialId', serialId)
            const localVarPath = `/{customerId}/Settings/SerialOptions/{serialId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"serialId"}}`, encodeURIComponent(String(serialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serialOption, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimSettingsApi - functional programming interface
 * @export
 */
export const ClaimSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimSettingsGetClaimSettings(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimSettingsGetClaimSettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateClaimSettingsRequest} [updateClaimSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimSettingsUpdateClaimSettings(customerId: number, updateClaimSettingsRequest?: UpdateClaimSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimSettingsUpdateClaimSettings(customerId, updateClaimSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} serialId 
         * @param {SerialOption} [serialOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimSettingsUpdateSerialOption(customerId: number, serialId: string, serialOption?: SerialOption, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerialOption>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimSettingsUpdateSerialOption(customerId, serialId, serialOption, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimSettingsApi - factory interface
 * @export
 */
export const ClaimSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsGetClaimSettings(customerId: number, options?: any): AxiosPromise<ClaimSettingsResponse> {
            return localVarFp.claimSettingsGetClaimSettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {UpdateClaimSettingsRequest} [updateClaimSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsUpdateClaimSettings(customerId: number, updateClaimSettingsRequest?: UpdateClaimSettingsRequest, options?: any): AxiosPromise<ClaimSettingsResponse> {
            return localVarFp.claimSettingsUpdateClaimSettings(customerId, updateClaimSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {string} serialId 
         * @param {SerialOption} [serialOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimSettingsUpdateSerialOption(customerId: number, serialId: string, serialOption?: SerialOption, options?: any): AxiosPromise<SerialOption> {
            return localVarFp.claimSettingsUpdateSerialOption(customerId, serialId, serialOption, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimSettingsApi - object-oriented interface
 * @export
 * @class ClaimSettingsApi
 * @extends {BaseAPI}
 */
export class ClaimSettingsApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimSettingsApi
     */
    public claimSettingsGetClaimSettings(customerId: number, options?: AxiosRequestConfig) {
        return ClaimSettingsApiFp(this.configuration).claimSettingsGetClaimSettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {UpdateClaimSettingsRequest} [updateClaimSettingsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimSettingsApi
     */
    public claimSettingsUpdateClaimSettings(customerId: number, updateClaimSettingsRequest?: UpdateClaimSettingsRequest, options?: AxiosRequestConfig) {
        return ClaimSettingsApiFp(this.configuration).claimSettingsUpdateClaimSettings(customerId, updateClaimSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {string} serialId 
     * @param {SerialOption} [serialOption] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimSettingsApi
     */
    public claimSettingsUpdateSerialOption(customerId: number, serialId: string, serialOption?: SerialOption, options?: AxiosRequestConfig) {
        return ClaimSettingsApiFp(this.configuration).claimSettingsUpdateSerialOption(customerId, serialId, serialOption, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimsCustomerApi - axios parameter creator
 * @export
 */
export const ClaimsCustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerCreateClaimsExport: async (customerId: number, claimsExportRequest: ClaimsExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerCreateClaimsExport', 'customerId', customerId)
            // verify required parameter 'claimsExportRequest' is not null or undefined
            assertParamExists('claimsCustomerCreateClaimsExport', 'claimsExportRequest', claimsExportRequest)
            const localVarPath = `/customer/{customerId}/claims/export`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsPrintRequest} claimsPrintRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerCreateClaimsPrint: async (customerId: number, claimsPrintRequest: ClaimsPrintRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerCreateClaimsPrint', 'customerId', customerId)
            // verify required parameter 'claimsPrintRequest' is not null or undefined
            assertParamExists('claimsCustomerCreateClaimsPrint', 'claimsPrintRequest', claimsPrintRequest)
            const localVarPath = `/customer/{customerId}/claims/print`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsPrintRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerDeleteClaims: async (customerId: number, claimsDeleteRequest: ClaimsDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerDeleteClaims', 'customerId', customerId)
            // verify required parameter 'claimsDeleteRequest' is not null or undefined
            assertParamExists('claimsCustomerDeleteClaims', 'claimsDeleteRequest', claimsDeleteRequest)
            const localVarPath = `/customer/{customerId}/claims`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerGetClaims: async (customerId: number, buildQueryRequest: BuildQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerGetClaims', 'customerId', customerId)
            // verify required parameter 'buildQueryRequest' is not null or undefined
            assertParamExists('claimsCustomerGetClaims', 'buildQueryRequest', buildQueryRequest)
            const localVarPath = `/customer/{customerId}/claims`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerGetFilterDropdowns: async (customerId: number, buildQueryRequest: BuildQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerGetFilterDropdowns', 'customerId', customerId)
            // verify required parameter 'buildQueryRequest' is not null or undefined
            assertParamExists('claimsCustomerGetFilterDropdowns', 'buildQueryRequest', buildQueryRequest)
            const localVarPath = `/customer/{customerId}/claims/filter-dropdowns`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsIgnore: async (customerId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsIgnore', 'customerId', customerId)
            // verify required parameter 'claimsIgnoreRequest' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsIgnore', 'claimsIgnoreRequest', claimsIgnoreRequest)
            const localVarPath = `/customer/{customerId}/claims/ignore-claim`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsIgnoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsIgnoreAttachmentRequirements: async (customerId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsIgnoreAttachmentRequirements', 'customerId', customerId)
            // verify required parameter 'claimsIgnoreAttachmentRequirementsRequest' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsIgnoreAttachmentRequirements', 'claimsIgnoreAttachmentRequirementsRequest', claimsIgnoreAttachmentRequirementsRequest)
            const localVarPath = `/customer/{customerId}/claims/ignore-attachment-requirements`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsIgnoreAttachmentRequirementsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsStatus: async (customerId: number, claimsStatusRequest: ClaimsStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsStatus', 'customerId', customerId)
            // verify required parameter 'claimsStatusRequest' is not null or undefined
            assertParamExists('claimsCustomerUpdateClaimsStatus', 'claimsStatusRequest', claimsStatusRequest)
            const localVarPath = `/customer/{customerId}/claims/status`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimsStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimsCustomerApi - functional programming interface
 * @export
 */
export const ClaimsCustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimsCustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerCreateClaimsExport(customerId: number, claimsExportRequest: ClaimsExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerCreateClaimsExport(customerId, claimsExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsPrintRequest} claimsPrintRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerCreateClaimsPrint(customerId: number, claimsPrintRequest: ClaimsPrintRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsPrintResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerCreateClaimsPrint(customerId, claimsPrintRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerDeleteClaims(customerId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerDeleteClaims(customerId, claimsDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerGetClaims(customerId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaginatedClaimsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerGetClaims(customerId, buildQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerGetFilterDropdowns(customerId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClaimsFiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerGetFilterDropdowns(customerId, buildQueryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerUpdateClaimsIgnore(customerId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerUpdateClaimsIgnore(customerId, claimsIgnoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId, claimsIgnoreAttachmentRequirementsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsCustomerUpdateClaimsStatus(customerId: number, claimsStatusRequest: ClaimsStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsCustomerUpdateClaimsStatus(customerId, claimsStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimsCustomerApi - factory interface
 * @export
 */
export const ClaimsCustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimsCustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsExportRequest} claimsExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerCreateClaimsExport(customerId: number, claimsExportRequest: ClaimsExportRequest, options?: any): AxiosPromise<ClaimsExportResponse> {
            return localVarFp.claimsCustomerCreateClaimsExport(customerId, claimsExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsPrintRequest} claimsPrintRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerCreateClaimsPrint(customerId: number, claimsPrintRequest: ClaimsPrintRequest, options?: any): AxiosPromise<ClaimsPrintResponse> {
            return localVarFp.claimsCustomerCreateClaimsPrint(customerId, claimsPrintRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsDeleteRequest} claimsDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerDeleteClaims(customerId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: any): AxiosPromise<ClaimsDeleteResponse> {
            return localVarFp.claimsCustomerDeleteClaims(customerId, claimsDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerGetClaims(customerId: number, buildQueryRequest: BuildQueryRequest, options?: any): AxiosPromise<GetPaginatedClaimsResponse> {
            return localVarFp.claimsCustomerGetClaims(customerId, buildQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {BuildQueryRequest} buildQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerGetFilterDropdowns(customerId: number, buildQueryRequest: BuildQueryRequest, options?: any): AxiosPromise<GetClaimsFiltersResponse> {
            return localVarFp.claimsCustomerGetFilterDropdowns(customerId, buildQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsIgnore(customerId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsCustomerUpdateClaimsIgnore(customerId, claimsIgnoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId, claimsIgnoreAttachmentRequirementsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {ClaimsStatusRequest} claimsStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsCustomerUpdateClaimsStatus(customerId: number, claimsStatusRequest: ClaimsStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.claimsCustomerUpdateClaimsStatus(customerId, claimsStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimsCustomerApi - object-oriented interface
 * @export
 * @class ClaimsCustomerApi
 * @extends {BaseAPI}
 */
export class ClaimsCustomerApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsExportRequest} claimsExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerCreateClaimsExport(customerId: number, claimsExportRequest: ClaimsExportRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerCreateClaimsExport(customerId, claimsExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsPrintRequest} claimsPrintRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerCreateClaimsPrint(customerId: number, claimsPrintRequest: ClaimsPrintRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerCreateClaimsPrint(customerId, claimsPrintRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsDeleteRequest} claimsDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerDeleteClaims(customerId: number, claimsDeleteRequest: ClaimsDeleteRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerDeleteClaims(customerId, claimsDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {BuildQueryRequest} buildQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerGetClaims(customerId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerGetClaims(customerId, buildQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {BuildQueryRequest} buildQueryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerGetFilterDropdowns(customerId: number, buildQueryRequest: BuildQueryRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerGetFilterDropdowns(customerId, buildQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsIgnoreRequest} claimsIgnoreRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerUpdateClaimsIgnore(customerId: number, claimsIgnoreRequest: ClaimsIgnoreRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerUpdateClaimsIgnore(customerId, claimsIgnoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsIgnoreAttachmentRequirementsRequest} claimsIgnoreAttachmentRequirementsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId: number, claimsIgnoreAttachmentRequirementsRequest: ClaimsIgnoreAttachmentRequirementsRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerUpdateClaimsIgnoreAttachmentRequirements(customerId, claimsIgnoreAttachmentRequirementsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {ClaimsStatusRequest} claimsStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsCustomerApi
     */
    public claimsCustomerUpdateClaimsStatus(customerId: number, claimsStatusRequest: ClaimsStatusRequest, options?: AxiosRequestConfig) {
        return ClaimsCustomerApiFp(this.configuration).claimsCustomerUpdateClaimsStatus(customerId, claimsStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClaimsQueueApi - axios parameter creator
 * @export
 */
export const ClaimsQueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsQueueGetQueuedClaimStatusSummary: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('claimsQueueGetQueuedClaimStatusSummary', 'customerId', customerId)
            const localVarPath = `/claim-queue/{customerId}/summary`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        claimsQueueProcessAllClaims: async (batchActionRequest?: BatchActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes all claims for the current filter or the provided claim ids
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsQueueProcessClaims: async (batchActionRequest?: BatchActionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchActionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClaimsQueueApi - functional programming interface
 * @export
 */
export const ClaimsQueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClaimsQueueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsQueueGetQueuedClaimStatusSummary(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueuedClaimStatusSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsQueueGetQueuedClaimStatusSummary(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async claimsQueueProcessAllClaims(batchActionRequest?: BatchActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClaimsProcessAllResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsQueueProcessAllClaims(batchActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Processes all claims for the current filter or the provided claim ids
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimsQueueProcessClaims(batchActionRequest?: BatchActionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueProcessClaimsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimsQueueProcessClaims(batchActionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClaimsQueueApi - factory interface
 * @export
 */
export const ClaimsQueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClaimsQueueApiFp(configuration)
    return {
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsQueueGetQueuedClaimStatusSummary(customerId: number, options?: any): AxiosPromise<QueuedClaimStatusSummaryResponse> {
            return localVarFp.claimsQueueGetQueuedClaimStatusSummary(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        claimsQueueProcessAllClaims(batchActionRequest?: BatchActionRequest, options?: any): AxiosPromise<ClaimsProcessAllResponse> {
            return localVarFp.claimsQueueProcessAllClaims(batchActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes all claims for the current filter or the provided claim ids
         * @param {BatchActionRequest} [batchActionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimsQueueProcessClaims(batchActionRequest?: BatchActionRequest, options?: any): AxiosPromise<QueueProcessClaimsResponse> {
            return localVarFp.claimsQueueProcessClaims(batchActionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClaimsQueueApi - object-oriented interface
 * @export
 * @class ClaimsQueueApi
 * @extends {BaseAPI}
 */
export class ClaimsQueueApi extends BaseAPI {
    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsQueueApi
     */
    public claimsQueueGetQueuedClaimStatusSummary(customerId: number, options?: AxiosRequestConfig) {
        return ClaimsQueueApiFp(this.configuration).claimsQueueGetQueuedClaimStatusSummary(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BatchActionRequest} [batchActionRequest] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ClaimsQueueApi
     */
    public claimsQueueProcessAllClaims(batchActionRequest?: BatchActionRequest, options?: AxiosRequestConfig) {
        return ClaimsQueueApiFp(this.configuration).claimsQueueProcessAllClaims(batchActionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes all claims for the current filter or the provided claim ids
     * @param {BatchActionRequest} [batchActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClaimsQueueApi
     */
    public claimsQueueProcessClaims(batchActionRequest?: BatchActionRequest, options?: AxiosRequestConfig) {
        return ClaimsQueueApiFp(this.configuration).claimsQueueProcessClaims(batchActionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


