import { VyneButton } from '@vynedental/design-system'
import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { Form, NewFormInput } from 'ui'
import { newValidateEmail } from 'utils'

import { PublicLayoutWrapper } from 'trellis:components/layouts/PreAuthLayout/PreAuthLayout'

import { LoginFormProps } from '../Login'
import LoginErrorBox from './LoginErrorBox/LoginErrorBox'

const TrellisLoginForm: FC<LoginFormProps> = ({
  formData,
  formErrors,
  handleFormChange,
  handleFormSubmit,
  loginError,
  setFormValidations,
  verifying,
}): ReactElement | null => {
  return (
    <PublicLayoutWrapper>
      <LoginErrorBox loginError={loginError} />
      <Form
        autoComplete='on'
        data-testid='trellis-login-form'
        method='post'
        name='loginForm'
        onSubmit={handleFormSubmit}
      >
        <NewFormInput
          autoFocus
          errors={formErrors}
          label='Username (Email)'
          name='username'
          onChange={handleFormChange('username')}
          required
          setValidations={setFormValidations}
          validator={newValidateEmail}
          value={formData.username}
          data-testid='username'
        />
        <NewFormInput
          errors={formErrors}
          name='password'
          label='Password'
          onChange={handleFormChange('password')}
          required
          setValidations={setFormValidations}
          type='password'
          value={formData.password}
          data-testid='password'
        />
        <VyneButton
          block={true}
          dataTestId='loginButton'
          htmlType='submit'
          loading={verifying}
          size='large'
          type='primary'
        >
          Log In
        </VyneButton>
      </Form>
      <Link
        className='text-link text-align-right mt-100'
        to='/Account/ForgotPassword'
      >
        Forgot My Password
      </Link>
    </PublicLayoutWrapper>
  )
}

export default TrellisLoginForm
