import { LaptopOutlined } from '@ant-design/icons'
import { VyneButton, VyneModal } from '@vynedental/design-system'
import { message } from 'antd'
import React, { ClipboardEvent, FC, useEffect, useState } from 'react'

import { ImageCaptureMethod, ImageQuality } from '../../../attachment.interface'

import '../../../../Attachments/AttachmentView/KeyboardCapture.scss'

import { IImageCaptureClaimAttachment } from '../../../../attachmentsShared/shared/shared.interface'
import {
  FileExtensions,
  MimeTypes,
} from '../../../../attachmentsShared/shared/shared.types'
import { canvasImageConversion } from '../../../../attachmentsShared/shared/sharedFunctions'

const KeyboardCapture: FC<IImageCaptureClaimAttachment> = ({
  updateAttachmentImage,
}) => {
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false)

  const handlePaste = async (e: ClipboardEvent) => {
    if (visibleDialog && e.clipboardData.files.length) {
      const imageData = e.clipboardData.files[0]
      if (imageData.type.indexOf('image') > -1) {
        const filename = new Date().getTime() + FileExtensions.JPG
        const convertedFile: File = await canvasImageConversion(
          imageData,
          filename,
          MimeTypes.ImageJpeg,
          ImageQuality.MaxUnity,
        )
        updateAttachmentImage(
          {
            isNewImage: true,
            imageData: convertedFile,
            imageDateTaken: new Date(),
            imageCaptureMethod: ImageCaptureMethod.KeyboardCapture,
          },
          false,
        )
        setVisibleDialog(false)
      } else {
        //File is not an image type. type = ', imageData.type
        message.error('File is not an image type: ' + imageData.name)
      }
    } else if (visibleDialog) {
      //No file data was found in clipboard
      message.error('No file data was found in clipboard')
    }
  }

  useEffect(() => {
    window.addEventListener('paste', handlePaste as unknown as EventListener)

    return () => {
      window.removeEventListener(
        'paste',
        handlePaste as unknown as EventListener,
      )
    }
  }, [visibleDialog])

  return (
    <>
      <VyneButton
        className='keyboard-capture-btn'
        dataTestId='keyboard-capture-button'
        icon={<LaptopOutlined />}
        onClick={() => setVisibleDialog(true)}
      >
        Keyboard Capture
      </VyneButton>
      <VyneModal
        dataTestId='claim-attachments-keyboard-capture-modal'
        destroyOnClose={true}
        footer={null}
        onCancel={() => setVisibleDialog(false)}
        open={visibleDialog}
        title={<h4>Keyboard Capture</h4>}
      >
        <div className='sa-keyboard-capture-modal__acquisition'>
          <p className='vyne-lg-text mb-050'>
            <strong>Capture an image using keyboard shortcuts.</strong>
          </p>
          <p className='vyne-lg-text'>
            Upload images using the following steps:
            <ul className='sa-keyboard-capture-modal__list'>
              <li>Click on the window you want to capture.</li>
              <li>
                Press the buttons, <b>"alt" + "Print Screen."</b>
              </li>
              <li>Return to this window.</li>
              <li>
                Press the <b>"ctrl" + "v"</b> to upload the image.
              </li>
            </ul>
          </p>
        </div>
        <div className='sa-keyboard-capture-modal__acquisition-note'>
          *Keep this window open when using the capture tool.
        </div>
      </VyneModal>
    </>
  )
}

export default KeyboardCapture
