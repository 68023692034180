import { PlusOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons'
import {
  RangePicker,
  RangePickerProps,
  VyneButton,
} from '@vynedental/design-system'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { SearchBar, SearchStates } from 'ui'

import {
  DateRange,
  rangeThemeConfig,
  sentAttachmentRangePresets,
} from 'trellis:utilities/datePickerUtilities'

import {
  refreshSentAttachmentGrid,
  refreshUnsentAttachmentGrid,
  sendSelectedAttachments,
} from '../AttachmentsGrid/_attachmentGridServices'
import { useAttachmentsContext } from '../Context/AttachmentsContext'
import PatientSearchModal from '../Patient/PatientSearchModal/PatientSearchModal'
import RequirementsLookupModal from '../RequirementsLookupModal/RequirementsLookupModal'
import styles from './AttachmentsHeader.module.scss'

type AttachmentsHeaderProps = {
  dateRange: DateRange
  handleSearch: any
  readyToSendTotal?: number
  searchInput: SearchStates
  setDateRange: Dispatch<SetStateAction<DateRange>>
  setSearchInput: Dispatch<SetStateAction<SearchStates>>
  unsentAttachments: number[]
}

const AttachmentsHeader: FC<AttachmentsHeaderProps> = ({
  dateRange,
  handleSearch,
  readyToSendTotal = 0,
  searchInput,
  setSearchInput,
  unsentAttachments,
}) => {
  const {
    activeTab,
    authentication,
    gridLoading,
    setAttachmentId,
    facilityId,
    setAttachments,
    setSentAttachments,
    setGridLoading,
    getSortedAttachments,
  } = useAttachmentsContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [showPatientSearchModal, setShowPatientSearchModal] =
    useState<boolean>(false)
  const [
    showAttachmentRequirementsLookup,
    setShowAttachmentRequirementsLookup,
  ] = useState<boolean>(false)

  const instructions =
    activeTab === 'Unsent'
      ? 'Create a new standalone attachment, independent of a claim.'
      : 'Search previously sent attachments.'

  const handleSendAttachmentClick = async () => {
    setLoading(true)

    await sendSelectedAttachments(unsentAttachments)
    onGridRefresh()

    setLoading(false)
  }

  const onGridRefresh = async () => {
    setGridLoading(true)

    activeTab === 'Unsent'
      ? await refreshUnsentAttachmentGrid(
          facilityId,
          setAttachments,
          getSortedAttachments,
        )
      : await refreshSentAttachmentGrid(
          facilityId,
          setSentAttachments,
          dateRange.dates,
          getSortedAttachments,
        )

    setGridLoading(false)
  }

  const handleDateRangeSelect = (dates: DateRange['dates']) =>
    handleSearch(dates)

  const disabledDate: RangePickerProps['disabledDate'] = (
    current,
    { from },
  ) => {
    return (
      (current && current > dayjs().endOf('day')) ||
      current.diff(from, 'days') >= 31
    )
  }

  return (
    <>
      {showAttachmentRequirementsLookup && (
        <RequirementsLookupModal
          isVisible={showAttachmentRequirementsLookup}
          setIsVisible={setShowAttachmentRequirementsLookup}
        />
      )}
      {showPatientSearchModal && (
        <PatientSearchModal
          authentication={authentication}
          isVisible={showPatientSearchModal}
          setIsVisible={setShowPatientSearchModal}
          setAttachmentId={setAttachmentId}
        />
      )}
      <section className={styles['sa-header']}>
        <Row
          className={styles['sa-header--send']}
          justify={'space-between'}
        >
          <h1>Attachments</h1>
          <div className={styles['sa-header--send-button']}>
            {activeTab === 'Unsent' && (
              <VyneButton
                dataTestId='send-attachments-button'
                disabled={gridLoading}
                icon={<SendOutlined />}
                type='primary'
                loading={loading}
                onClick={handleSendAttachmentClick}
              >
                {`Send (${readyToSendTotal}) Ready`}
              </VyneButton>
            )}
          </div>
        </Row>
        <Row className={styles['sa-header--actions']}>
          <Col>
            <p className='mb-100'>{instructions}</p>
            {activeTab === 'Unsent' ? (
              <section className={styles['sa-header--actions-unsent']}>
                <VyneButton
                  dataTestId=''
                  disabled={gridLoading}
                  icon={
                    <PlusOutlined
                      style={{
                        color: 'var(--charcoal-200, #87878a)',
                        fontSize: '1em',
                      }}
                    />
                  }
                  onClick={() => setShowPatientSearchModal(true)}
                >
                  New Attachment
                </VyneButton>
                <VyneButton
                  dataTestId='attachment-requirements-lookup-button'
                  disabled={gridLoading}
                  icon={<SearchOutlined />}
                  onClick={() => setShowAttachmentRequirementsLookup(true)}
                  type='link'
                >
                  Requirement Lookup
                </VyneButton>
              </section>
            ) : (
              <div className={styles['sa-header--actions-sent']}>
                <div className={styles['sa-header--actions-sent-search']}>
                  <SearchBar
                    disabled={gridLoading}
                    state={searchInput}
                    setState={setSearchInput}
                    placeholder='Patient name, carrier or NEA#'
                  />
                </div>
                <div className='ml-100'>
                  <RangePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    name='attachments-header-range-picker'
                    onChange={(dates, _dateStrings) =>
                      dates[0] &&
                      handleDateRangeSelect([
                        dates[0].toDate(),
                        dates[1].toDate(),
                      ])
                    }
                    presets={sentAttachmentRangePresets}
                    theme={rangeThemeConfig}
                    value={
                      dateRange?.dates && [
                        dayjs(dateRange.dates[0]),
                        dayjs(dateRange.dates[1]),
                      ]
                    }
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </section>
    </>
  )
}

export default AttachmentsHeader
