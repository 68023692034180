import { observer } from '@legendapp/state/react'

import './paymentsPortal.scss'

import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

export const PaymentsPortal = observer(() => {
  const getPaymentUrl = (): string => {
    return `${
      trellisConfiguration.payments_baseUrl
    }?authToken=${GlobalState.Auth.AuthToken.get()}&registrationToken=${GlobalState.Auth.RegistrationToken.get()}`
  }

  return (
    <article className='page-content__container'>
      <iframe
        id='iframe_paymentProcessor'
        src={getPaymentUrl()}
        title='Payment Processor Microsite'
        allow='clipboard-read; clipboard-write'
        style={{ width: '100%', minHeight: '100%', border: 0 }}
      ></iframe>
    </article>
  )
})
