import * as React from 'react'

export const UnsentAttachmentRequirementsTooltip = (
  <span>
    The requirements listed in this grid are the standard attachment
    requirements provided to Vyne Dental by the selected payer.
    <br />
    Selecting <strong>Ignore</strong> clears any attachment related validation
    errors, allowing the claim the option to be processed with different
    attachment contents from what's listed, or without an attachment entirely.
  </span>
)

export const SentAttachmentRequirementsTooltipAnt = (
  <span>
    The requirements listed in this grid are the standard attachment
    requirements provided to Vyne Dental by the selected payer.
  </span>
)

export const NeaNumberTooltip = (
  <span>
    Attachments may be modified until their associated claims are submitted, at
    which point the claim and attachment are submitted to the payer together and
    the NEA# will be displayed above
  </span>
)

export const MaxAttachmentSizeError =
  'Attachment is over the maximum size and the image cannot be saved'

export const RemoveImageDialog = {
  dialogTitle: 'Remove Image',
  dialogText: 'Are you sure you want remove the image?',
  dialogDenyText: 'No',
  dialogConfirmText: 'Yes',
}

export const ScreenCaptureInstallUrl =
  'https://downloads.rpractice.com/Public/ScreenCaptureUtility/Release/ScreenCaptureUtility1.0000.11902.exe'

export const PNotifyText = {
  screenCaptureError:
    'There was a problem launching Screen Capture. Please try again later.',
  imageTypesError: 'Error loading image types.',
  ignoreAttachmentRequirementsError: 'Error ignoring attachment requirements.',
  loadIgnoreAttachmentRequirementsStateError: 'Error loading claim data.',
}

export const AttachmentRequirementsText = {
  attachment: 'Attachment',
  fmXray: 'FM Xray',
  narrative: 'Narrative',
  panXray: 'Pan Xray',
  perioCharting: 'Perio Charting',
  xray: 'Xray',
}

export const SliderConstraints = {
  brightenMax: 100,
  brightenMin: -100,
  contrastMax: 200,
  contrastMin: -200,
  sharpenMax: 1,
  sharpenMin: -1,
}

export const CropperConfiguration = {
  minWidthConstraint: 100,
  minHeightConstraints: 100,
  autoCropAreaSizeUnity: 1.0,
}

export const XrayGenericImageTypes = [1, 2, 3, 4, 5, 14, 360]

//15MB attachment limit
export const MaxAttachmentSize: number = 15000000

