import { Col, Row } from 'antd'
import { FC } from 'react'

import { FormInput, FormMaskInput, FormSelect } from 'ui'
import { SelectOption, validateEmail, validateName, validatePhone } from 'utils'

const jobPositions: SelectOption[] = [
  { text: 'Director', value: 1 },
  { text: 'GM', value: 2 },
  { text: 'VP', value: 3 },
  { text: 'CEO', value: 4 },
  { text: 'CFO', value: 5 },
  { text: 'General Counsel', value: 6 },
  { text: 'Other', value: 7 },
]

const PrimaryContactInformation: FC = () => {
  return (
    <section className='page-section'>
      <h3 className='mb-14-px'>Primary Contact Information</h3>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            label='First Name'
            name='firstName'
            required
            validator={validateName}
          />
        </Col>
        <Col span={12}>
          <FormInput
            label='Last Name'
            name='lastName'
            required
            validator={validateName}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Email'
            name='email'
            required
            validator={validateEmail}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormInput
            label='Title'
            name='title'
            required
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormMaskInput
            format='###-###-####'
            label='Main Business Phone Number'
            mask='_'
            name='phone'
            required
            validator={validatePhone}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormSelect
            label='Job Position'
            name='jobPosition'
            options={jobPositions}
            required
          />
        </Col>
      </Row>
    </section>
  )
}

export default PrimaryContactInformation
