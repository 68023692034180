import GlobalState from 'trellis:state/globalState'
import trellisConfiguration from 'trellis:utilities/config'

import {
  Configuration,
  PearlCredentials,
  PearlProvisionApiFactory,
  ProvisionRequest,
} from './pearl-provision-client'

const apiEndpoint: string =
  trellisConfiguration.trellis_apiUrl + 'services/pearl'

export const GetImagingSystems = async () => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).pearlProvisionGetImagingSystems()
}

export const GetPearlInstaller = async (customerId: number) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).pearlProvisionInstaller(customerId)
}

export const SavePearlCredentials = async (
  customerId: number,
  credentials: PearlCredentials,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).pearlProvisionSaveCredentials(customerId, credentials)
}

export const ProvisionPearl = async (
  customerId: number,
  request: ProvisionRequest,
) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).pearlProvisionProvision(customerId, request)
}

export const GetPearlStatus = async (customerId: number) => {
  return await PearlProvisionApiFactory(
    new Configuration({
      basePath: apiEndpoint,
      accessToken: GlobalState?.Auth?.AccessToken?.peek(),
    }),
  ).pearlProvisionPearlStatus(customerId)
}

