/* tslint:disable */
/* eslint-disable */
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateAccountRequest
     */
    'globalCustomerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'registrationPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'confirmPassword'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    'expirePassword'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'securityQuestion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'securityAnswer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    'activatedUser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerPaymentStatusResponse
 */
export interface CustomerPaymentStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPaymentStatusResponse
     */
    'paymentInformationRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerSubscription
 */
export interface CustomerSubscription {
    /**
     * 
     * @type {number}
     * @memberof CustomerSubscription
     */
    'customerSubscriptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerSubscription
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscription
     */
    'cancelDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscription
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscription
     */
    'lastModifiedDate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmailTemplateType = {
    None: 'None',
    VyneTrellis: 'VyneTrellis',
    DentalIntelligence: 'DentalIntelligence'
} as const;

export type EmailTemplateType = typeof EmailTemplateType[keyof typeof EmailTemplateType];


/**
 * 
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * 
     * @type {EmailTemplateType}
     * @memberof ForgotPasswordRequest
     */
    'appCompanyName'?: EmailTemplateType;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'userName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductType = {
    Sso: 'SSO',
    Trellis: 'Trellis',
    Opera: 'Opera',
    FastAttach: 'FastAttach',
    DentalIntel: 'DentalIntel',
    Simplifeye: 'Simplifeye'
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];


/**
 * 
 * @export
 * @interface SSOTokenLoginRequest
 */
export interface SSOTokenLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof SSOTokenLoginRequest
     */
    'ssoToken': string;
}
/**
 * 
 * @export
 * @interface SsoOutboundUrlResponse
 */
export interface SsoOutboundUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof SsoOutboundUrlResponse
     */
    'ssoUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface SsoTokenRequest
 */
export interface SsoTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof SsoTokenRequest
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SsoTokenRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SsoTokenRequest
     */
    'destinationProduct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SsoTokenRequest
     */
    'emailAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface SsoTokenUrlRequest
 */
export interface SsoTokenUrlRequest {
    /**
     * 
     * @type {ProductType}
     * @memberof SsoTokenUrlRequest
     */
    'destinationProduct': ProductType;
    /**
     * 
     * @type {string}
     * @memberof SsoTokenUrlRequest
     */
    'returnPath': string;
}
/**
 * 
 * @export
 * @interface SsoUsernamePasswordLoginRequest
 */
export interface SsoUsernamePasswordLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof SsoUsernamePasswordLoginRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SsoUsernamePasswordLoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'expiresInSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'authToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'registrationToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'customerTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'pmgId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'pmgRegionId'?: number | null;
}
/**
 * 
 * @export
 * @interface TrellisSsoSingleUseTokenLoginRequest
 */
export interface TrellisSsoSingleUseTokenLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof TrellisSsoSingleUseTokenLoginRequest
     */
    'singleUseToken': string;
}
/**
 * 
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePasswordResponse
 */
export interface UpdatePasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordResponse
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyTokenRequest
 */
export interface VerifyTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyTokenResponse
 */
export interface VerifyTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenResponse
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerifyTokenResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface VerifyUserRegistrationRequest
 */
export interface VerifyUserRegistrationRequest {
    /**
     * 
     * @type {number}
     * @memberof VerifyUserRegistrationRequest
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VerifyUserRegistrationRequest
     */
    'registrationPassword'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountRequest} [createAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateUser: async (createAccountRequest?: CreateAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/create-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword: async (forgotPasswordRequest?: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword: async (updatePasswordRequest?: UpdatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyForgotPasswordToken: async (verifyTokenRequest?: VerifyTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/verify-password-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyUserRegistrationRequest} [verifyUserRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyUserRegistration: async (verifyUserRegistrationRequest?: VerifyUserRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/verify-user-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyUserRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAccountRequest} [createAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountCreateUser(createAccountRequest?: CreateAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountCreateUser(createAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountForgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountForgotPassword(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdatePassword(updatePasswordRequest?: UpdatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdatePassword(updatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyForgotPasswordToken(verifyTokenRequest?: VerifyTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyForgotPasswordToken(verifyTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyUserRegistrationRequest} [verifyUserRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountVerifyUserRegistration(verifyUserRegistrationRequest?: VerifyUserRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountVerifyUserRegistration(verifyUserRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAccountRequest} [createAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountCreateUser(createAccountRequest?: CreateAccountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountCreateUser(createAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountForgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): AxiosPromise<string> {
            return localVarFp.accountForgotPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePasswordRequest} [updatePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword(updatePasswordRequest?: UpdatePasswordRequest, options?: any): AxiosPromise<UpdatePasswordResponse> {
            return localVarFp.accountUpdatePassword(updatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTokenRequest} [verifyTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyForgotPasswordToken(verifyTokenRequest?: VerifyTokenRequest, options?: any): AxiosPromise<VerifyTokenResponse> {
            return localVarFp.accountVerifyForgotPasswordToken(verifyTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyUserRegistrationRequest} [verifyUserRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountVerifyUserRegistration(verifyUserRegistrationRequest?: VerifyUserRegistrationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountVerifyUserRegistration(verifyUserRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {CreateAccountRequest} [createAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountCreateUser(createAccountRequest?: CreateAccountRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountCreateUser(createAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountForgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountForgotPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePasswordRequest} [updatePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdatePassword(updatePasswordRequest?: UpdatePasswordRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountUpdatePassword(updatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTokenRequest} [verifyTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyForgotPasswordToken(verifyTokenRequest?: VerifyTokenRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyForgotPasswordToken(verifyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyUserRegistrationRequest} [verifyUserRegistrationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountVerifyUserRegistration(verifyUserRegistrationRequest?: VerifyUserRegistrationRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountVerifyUserRegistration(verifyUserRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationCreateAccessToken: async (authToken: string, registrationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authToken' is not null or undefined
            assertParamExists('authenticationCreateAccessToken', 'authToken', authToken)
            // verify required parameter 'registrationToken' is not null or undefined
            assertParamExists('authenticationCreateAccessToken', 'registrationToken', registrationToken)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authToken !== undefined && authToken !== null) {
                localVarHeaderParameter['authToken'] = String(authToken);
            }

            if (registrationToken !== undefined && registrationToken !== null) {
                localVarHeaderParameter['registrationToken'] = String(registrationToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout: async (authToken: string, registrationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authToken' is not null or undefined
            assertParamExists('authenticationLogout', 'authToken', authToken)
            // verify required parameter 'registrationToken' is not null or undefined
            assertParamExists('authenticationLogout', 'registrationToken', registrationToken)
            const localVarPath = `/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authToken !== undefined && authToken !== null) {
                localVarHeaderParameter['authToken'] = String(authToken);
            }

            if (registrationToken !== undefined && registrationToken !== null) {
                localVarHeaderParameter['registrationToken'] = String(registrationToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TrellisSsoSingleUseTokenLoginRequest} trellisSsoSingleUseTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSingleUseLogin: async (trellisSsoSingleUseTokenLoginRequest: TrellisSsoSingleUseTokenLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trellisSsoSingleUseTokenLoginRequest' is not null or undefined
            assertParamExists('authenticationSingleUseLogin', 'trellisSsoSingleUseTokenLoginRequest', trellisSsoSingleUseTokenLoginRequest)
            const localVarPath = `/credentials/SingleUseLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trellisSsoSingleUseTokenLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SsoUsernamePasswordLoginRequest} ssoUsernamePasswordLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSsoLogin: async (ssoUsernamePasswordLoginRequest: SsoUsernamePasswordLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssoUsernamePasswordLoginRequest' is not null or undefined
            assertParamExists('authenticationSsoLogin', 'ssoUsernamePasswordLoginRequest', ssoUsernamePasswordLoginRequest)
            const localVarPath = `/credentials/SsoLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoUsernamePasswordLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SSOTokenLoginRequest} sSOTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSsoTokenLogin: async (sSOTokenLoginRequest: SSOTokenLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sSOTokenLoginRequest' is not null or undefined
            assertParamExists('authenticationSsoTokenLogin', 'sSOTokenLoginRequest', sSOTokenLoginRequest)
            const localVarPath = `/credentials/SsoTokenLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sSOTokenLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationValidateAccessToken: async (authToken: string, registrationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authToken' is not null or undefined
            assertParamExists('authenticationValidateAccessToken', 'authToken', authToken)
            // verify required parameter 'registrationToken' is not null or undefined
            assertParamExists('authenticationValidateAccessToken', 'registrationToken', registrationToken)
            const localVarPath = `/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authToken !== undefined && authToken !== null) {
                localVarHeaderParameter['authToken'] = String(authToken);
            }

            if (registrationToken !== undefined && registrationToken !== null) {
                localVarHeaderParameter['registrationToken'] = String(registrationToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationCreateAccessToken(authToken: string, registrationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationCreateAccessToken(authToken, registrationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogout(authToken: string, registrationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLogout(authToken, registrationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TrellisSsoSingleUseTokenLoginRequest} trellisSsoSingleUseTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest: TrellisSsoSingleUseTokenLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SsoUsernamePasswordLoginRequest} ssoUsernamePasswordLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationSsoLogin(ssoUsernamePasswordLoginRequest: SsoUsernamePasswordLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationSsoLogin(ssoUsernamePasswordLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SSOTokenLoginRequest} sSOTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationSsoTokenLogin(sSOTokenLoginRequest: SSOTokenLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationSsoTokenLogin(sSOTokenLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationValidateAccessToken(authToken: string, registrationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationValidateAccessToken(authToken, registrationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationCreateAccessToken(authToken: string, registrationToken: string, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.authenticationCreateAccessToken(authToken, registrationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout(authToken: string, registrationToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationLogout(authToken, registrationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TrellisSsoSingleUseTokenLoginRequest} trellisSsoSingleUseTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest: TrellisSsoSingleUseTokenLoginRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SsoUsernamePasswordLoginRequest} ssoUsernamePasswordLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSsoLogin(ssoUsernamePasswordLoginRequest: SsoUsernamePasswordLoginRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.authenticationSsoLogin(ssoUsernamePasswordLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SSOTokenLoginRequest} sSOTokenLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationSsoTokenLogin(sSOTokenLoginRequest: SSOTokenLoginRequest, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.authenticationSsoTokenLogin(sSOTokenLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authToken 
         * @param {string} registrationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationValidateAccessToken(authToken: string, registrationToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationValidateAccessToken(authToken, registrationToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} authToken 
     * @param {string} registrationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationCreateAccessToken(authToken: string, registrationToken: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationCreateAccessToken(authToken, registrationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authToken 
     * @param {string} registrationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogout(authToken: string, registrationToken: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationLogout(authToken, registrationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrellisSsoSingleUseTokenLoginRequest} trellisSsoSingleUseTokenLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest: TrellisSsoSingleUseTokenLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationSingleUseLogin(trellisSsoSingleUseTokenLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SsoUsernamePasswordLoginRequest} ssoUsernamePasswordLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationSsoLogin(ssoUsernamePasswordLoginRequest: SsoUsernamePasswordLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationSsoLogin(ssoUsernamePasswordLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SSOTokenLoginRequest} sSOTokenLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationSsoTokenLogin(sSOTokenLoginRequest: SSOTokenLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationSsoTokenLogin(sSOTokenLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authToken 
     * @param {string} registrationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationValidateAccessToken(authToken: string, registrationToken: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationValidateAccessToken(authToken, registrationToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetCustomerSubscription: async (authtoken?: string, registrationtoken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sso/get-customer-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        ssoGetOperaApiBaseUrl: async (authtoken?: string, registrationtoken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OperaApiBaseUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenRequest} [ssoTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetSsoToken: async (authtoken?: string, registrationtoken?: string, ssoTokenRequest?: SsoTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SsoToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenUrlRequest} [ssoTokenUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetSsoUrl: async (authtoken?: string, registrationtoken?: string, ssoTokenUrlRequest?: SsoTokenUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SsoUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ssoTokenUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoSsoEnabledAppWide: async (authtoken?: string, registrationtoken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SsoEnabledAppWide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [globalCustomerId] 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoVerifyCustomerPayment: async (globalCustomerId?: number, authtoken?: string, registrationtoken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sso/verify-customer-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (globalCustomerId !== undefined) {
                localVarQueryParameter['globalCustomerId'] = globalCustomerId;
            }

            if (authtoken !== undefined && authtoken !== null) {
                localVarHeaderParameter['authtoken'] = String(authtoken);
            }

            if (registrationtoken !== undefined && registrationtoken !== null) {
                localVarHeaderParameter['registrationtoken'] = String(registrationtoken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoGetCustomerSubscription(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoGetCustomerSubscription(authtoken, registrationtoken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async ssoGetOperaApiBaseUrl(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoGetOperaApiBaseUrl(authtoken, registrationtoken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenRequest} [ssoTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoGetSsoToken(authtoken?: string, registrationtoken?: string, ssoTokenRequest?: SsoTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoGetSsoToken(authtoken, registrationtoken, ssoTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenUrlRequest} [ssoTokenUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoGetSsoUrl(authtoken?: string, registrationtoken?: string, ssoTokenUrlRequest?: SsoTokenUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoOutboundUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoGetSsoUrl(authtoken, registrationtoken, ssoTokenUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoSsoEnabledAppWide(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoSsoEnabledAppWide(authtoken, registrationtoken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [globalCustomerId] 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoVerifyCustomerPayment(globalCustomerId?: number, authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPaymentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoVerifyCustomerPayment(globalCustomerId, authtoken, registrationtoken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetCustomerSubscription(authtoken?: string, registrationtoken?: string, options?: any): AxiosPromise<CustomerSubscription> {
            return localVarFp.ssoGetCustomerSubscription(authtoken, registrationtoken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        ssoGetOperaApiBaseUrl(authtoken?: string, registrationtoken?: string, options?: any): AxiosPromise<string> {
            return localVarFp.ssoGetOperaApiBaseUrl(authtoken, registrationtoken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenRequest} [ssoTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetSsoToken(authtoken?: string, registrationtoken?: string, ssoTokenRequest?: SsoTokenRequest, options?: any): AxiosPromise<string> {
            return localVarFp.ssoGetSsoToken(authtoken, registrationtoken, ssoTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {SsoTokenUrlRequest} [ssoTokenUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoGetSsoUrl(authtoken?: string, registrationtoken?: string, ssoTokenUrlRequest?: SsoTokenUrlRequest, options?: any): AxiosPromise<SsoOutboundUrlResponse> {
            return localVarFp.ssoGetSsoUrl(authtoken, registrationtoken, ssoTokenUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoSsoEnabledAppWide(authtoken?: string, registrationtoken?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.ssoSsoEnabledAppWide(authtoken, registrationtoken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [globalCustomerId] 
         * @param {string} [authtoken] 
         * @param {string} [registrationtoken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoVerifyCustomerPayment(globalCustomerId?: number, authtoken?: string, registrationtoken?: string, options?: any): AxiosPromise<CustomerPaymentStatusResponse> {
            return localVarFp.ssoVerifyCustomerPayment(globalCustomerId, authtoken, registrationtoken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
    /**
     * 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoGetCustomerSubscription(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoGetCustomerSubscription(authtoken, registrationtoken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoGetOperaApiBaseUrl(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoGetOperaApiBaseUrl(authtoken, registrationtoken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {SsoTokenRequest} [ssoTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoGetSsoToken(authtoken?: string, registrationtoken?: string, ssoTokenRequest?: SsoTokenRequest, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoGetSsoToken(authtoken, registrationtoken, ssoTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {SsoTokenUrlRequest} [ssoTokenUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoGetSsoUrl(authtoken?: string, registrationtoken?: string, ssoTokenUrlRequest?: SsoTokenUrlRequest, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoGetSsoUrl(authtoken, registrationtoken, ssoTokenUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoSsoEnabledAppWide(authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoSsoEnabledAppWide(authtoken, registrationtoken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [globalCustomerId] 
     * @param {string} [authtoken] 
     * @param {string} [registrationtoken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoVerifyCustomerPayment(globalCustomerId?: number, authtoken?: string, registrationtoken?: string, options?: AxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoVerifyCustomerPayment(globalCustomerId, authtoken, registrationtoken, options).then((request) => request(this.axios, this.basePath));
    }
}


