import { VyneSwitch } from '@vynedental/design-system'
import { Collapse } from 'antd'
import { FC } from 'react'

import { PatientEligibilityForm } from '../../../../api/eligibility/eligibility-client'
import insuranceUpdateIcon from '../../../../assets/insurance-update.png'
import ReadOnlyInput from './readOnlyInput'

const { Panel } = Collapse

type EligibilityResponseInsuranceUpdateProps = {
  patientResponse: PatientEligibilityForm
}

const EligibilityResponseInsuranceUpdate: FC<
  EligibilityResponseInsuranceUpdateProps
> = ({ patientResponse }) => {
  const patientDOB =
    patientResponse.PatientBirthdate &&
    new Date(patientResponse.PatientBirthdate).toLocaleDateString()
  const subscriberDOB =
    patientResponse.SubscriberBirthdate &&
    new Date(patientResponse.SubscriberBirthdate).toLocaleDateString()
  const patientIsSubscriber =
    patientResponse.PatientIsSubscriber &&
    JSON.parse(patientResponse.PatientIsSubscriber?.toLowerCase())

  return (
    <>
      <article className='eligibility-response--alert'>
        <p>The following information was used to verify eligibility.</p>
        <p>Please update your records appropriately.</p>
      </article>
      <Collapse defaultActiveKey={['insurance-update']}>
        <Panel
          key='insurance-update'
          className='eligibility-response__patient-response'
          header={<InsuranceUpdateHeader />}
        >
          <header className='eligibility-response__patient-response--title'>
            Patient
          </header>
          <section className='eligibility-response__patient-response--row'>
            <ReadOnlyInput
              label='Last'
              text={patientResponse.PatientLastName}
              allowCopy
            />
            <ReadOnlyInput
              label='First'
              text={patientResponse.PatientFirstName}
              allowCopy
            />
            <ReadOnlyInput
              label='Middle'
              text={patientResponse.PatientMiddleName}
              allowCopy
            />
          </section>
          <section className='eligibility-response__patient-response--row'>
            <ReadOnlyInput
              label='Suffix'
              text={patientResponse.PatientSuffix}
              allowCopy
            />
            <ReadOnlyInput
              label='Birthdate'
              text={patientDOB}
              allowCopy
            />
            <ReadOnlyInput
              label='Gender'
              text={patientResponse.PatientGender}
              allowCopy
            />
          </section>
          <header className='eligibility-response__patient-response--title subscriber-title'>
            Subscriber
          </header>
          <section className='eligibility-response__patient-response--subscriber'>
            <p>Patient is Subscriber</p>
            <VyneSwitch
              dataTestId='pat-sub-eligibility-response-update-switch'
              checkedChildren='Yes'
              unCheckedChildren='No'
              defaultChecked={patientIsSubscriber}
              disabled
            />
          </section>
          {!patientIsSubscriber && (
            <>
              <section className='eligibility-response__patient-response--row'>
                <ReadOnlyInput
                  label='Last'
                  text={patientResponse.SubscriberLastName}
                  allowCopy
                />
                <ReadOnlyInput
                  label='First'
                  text={patientResponse.SubscriberFirstName}
                  allowCopy
                />
                <ReadOnlyInput
                  label='Middle'
                  text={patientResponse.SubscriberMiddleName}
                  allowCopy
                />
              </section>
              <section className='eligibility-response__patient-response--row'>
                <ReadOnlyInput
                  label='Suffix'
                  text={patientResponse.SubscriberSuffix}
                  allowCopy
                />
                <ReadOnlyInput
                  label='Birthdate'
                  text={subscriberDOB}
                  allowCopy
                />
                <ReadOnlyInput
                  label='Gender'
                  text={patientResponse.SubscriberGender}
                  allowCopy
                />
              </section>
            </>
          )}
          <header className='eligibility-response__patient-response--title'>
            Insurance
          </header>
          <section className='eligibility-response__patient-response--row'>
            <ReadOnlyInput
              label='Carrier'
              text={patientResponse.CarrierName}
              allowCopy
              style={{ width: '60%' }}
            />
            <ReadOnlyInput
              label='Group #'
              text={patientResponse.GroupNumber}
              allowCopy
              style={{ width: '20%' }}
            />
            <ReadOnlyInput
              label='Subscriber ID'
              text={patientResponse.SubscriberId}
              allowCopy
              style={{ width: '20%' }}
            />
          </section>
        </Panel>
      </Collapse>
    </>
  )
}

const InsuranceUpdateHeader: FC = () => {
  return (
    <div className='eligibility-response__patient-response--header'>
      <p className='eligibility-response__patient-response--header-text'>
        Insurance Update
      </p>
      <span className='eligibility-response__patient-response--header-image'>
        <img
          alt='Insurance Update'
          src={insuranceUpdateIcon}
        />
      </span>
    </div>
  )
}

export default EligibilityResponseInsuranceUpdate
