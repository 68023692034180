import { observer, useObservable } from '@legendapp/state/react'
import { Tabs, TabsProps } from '@vynedental/design-system'

import { StatementsQueryRequest } from 'trellis:api/statements/statements-client'
import { BillingDownloadButton } from 'trellis:features/payments/components/BillingDownloadButton'
import { BillingFilesTable } from 'trellis:features/payments/components/BillingFilesTable'
import { BillingStatementSettings } from 'trellis:features/payments/components/BillingStatementSettings'
import { BillingStatementsTable } from 'trellis:features/payments/components/BillingStatementsTable'
import { LDFlags$ } from 'trellis:state/globalState'

import styles from './BillingStatements.module.scss'

export const BillingStatements = observer(() => {
  // TODO: add tabs and routes for each tab
  // TODO: maybe retrieve each tab data via a loader

  const downloadTableRequest$ = useObservable<StatementsQueryRequest>()

  const tabItems: TabsProps['items'] = [
    {
      key: 'Batch Files',
      label: <div data-testid='files'>Batch Files</div>,
      children: (
        <BillingFilesTable downloadTableRequest$={downloadTableRequest$} />
      ),
    },
    {
      key: 'Statements',
      label: <div data-testid='statements'>Statements</div>,
      children: (
        <BillingStatementsTable downloadTableRequest$={downloadTableRequest$} />
      ),
    },
  ]

  const currentTab$ = useObservable<string>(tabItems[0].key)
  const flags = LDFlags$.get()

  return (
    <section
      className={
        'page-content__container page-content__container--with-page-header ' +
        styles['billing-statements-container']
      }
    >
      <h1 className='page-content-header__title mb-100'>Billing Statements</h1>
      <Tabs
        items={tabItems}
        name='billing-statements-tabs'
        onChange={(activeKey) => currentTab$.set(activeKey)}
        tabBarExtraContent={
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
            <BillingDownloadButton
              currentTab$={currentTab$}
              downloadTableRequest$={downloadTableRequest$}
            />
            <BillingStatementSettings />
          </div>
        }
      />
    </section>
  )
})
