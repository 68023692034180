import {
  CloseOutlined,
  LeftCircleOutlined,
  PrinterOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { Col, Row } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useReactToPrint } from 'react-to-print'

import { EligibilityPatient } from '../../../../api/eligibility/eligibility-client'
import { useEligibilityContext } from '../../shared/context/EligibilityContext'
import EligibilityResponse, {
  EligibilityResponseProps,
} from '../../shared/EligibilityResponse'
import { emptyPatient } from '../../shared/form/utilities/eligibilityFormDataHandling'
import CarrierList from './lists/SearchCarrierList'
import PatientList from './lists/SearchPatientList'
import EligibilityForm from './OneTimeRequestEligibilityForm/OneTimeRequestEligibilityForm'

import './OneTimeRequestModal.scss'

import { VyneButton, VyneModal } from '@vynedental/design-system'

interface OneTimeRequestModalProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const OneTimeRequestModal: FC<OneTimeRequestModalProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const { oneTimeRequestState, setOneTimeRequestState } =
    useEligibilityContext()

  const [showContent, setShowContent] = useState<string>('One-time Request')
  const [hasPatient, setHasPatient] = useState<boolean>(false)
  const [patient, setPatient] = useState<EligibilityPatient>({
    ...emptyPatient,
  })
  const [patientStatus, setPatientStatus] = useState<{
    status: string
    toolTip: string
  }>(null)
  const [verifying, setVerifying] = useState<boolean>(false)
  const [verificationResponse, setVerificationResponse] =
    useState<EligibilityResponseProps>(null)

  const responseElementRef = useRef(null)

  useEffect(() => {
    if (verificationResponse) getModalBodyElement()
  }, [verificationResponse])

  const getModalBodyElement = () => {
    const modalBody = document.querySelector(
      '.one-time-request-modal .eligibility-response',
    )
    setTimeout(() => {
      if (modalBody) responseElementRef.current = modalBody
      else getModalBodyElement()
    }, 250)
  }

  const handlePrintResponse = useReactToPrint({
    content: () => responseElementRef.current,
    pageStyle: 'width: 100%;',
  })

  const handleModalClose = () => {
    if (verificationResponse)
      setOneTimeRequestState({
        ...oneTimeRequestState,
        Key: ++oneTimeRequestState.Key,
      })
    setPatient(emptyPatient)
    setVerificationResponse(null)
    setShowContent('One-time Request')
    setIsVisible(false)
  }

  const handleBackButton = () => {
    if (!patient.PatientId && showContent === 'Add Patient')
      setShowContent('One-time Request')
    else if (verificationResponse) setVerificationResponse(null)
    else setPatient(emptyPatient)
  }

  const modalHeader = (
    <section className='one-time-request-modal__header'>
      <h4>
        {showContent === 'One-time Request'
          ? 'One-time Request'
          : 'Add Patient'}
      </h4>
      {verificationResponse && (
        <div
          aria-label='print verification'
          className='eligibility-response-modal__header-print'
          onClick={handlePrintResponse}
        >
          <PrinterOutlined />
        </div>
      )}
      <div
        aria-label='close verification'
        className='eligibility-response-modal__header-close'
        onClick={handleModalClose}
      >
        <CloseOutlined />
      </div>
    </section>
  )

  return (
    <VyneModal
      className='one-time-request-modal'
      closable={false}
      dataTestId='one-time-request-modal'
      destroyOnClose
      footer={
        !verificationResponse
          ? [
              <Row
                align='middle'
                justify='space-between'
                key='one-time-request-modal-footer'
              >
                <Col>
                  {!patient.PatientId && showContent === 'One-time Request' && (
                    <Row align='middle'>
                      <p className='mr-16-px'>Not an existing patient?</p>
                      <VyneButton
                        dataTestId='add-patient-button'
                        icon={
                          <UserAddOutlined
                            style={{ color: 'var(--charcoal-200, #87878A)' }}
                          />
                        }
                        onClick={() => setShowContent('Add Patient')}
                        type='link'
                      >
                        Add Patient
                      </VyneButton>
                    </Row>
                  )}
                  {(patient.PatientId || showContent === 'Add Patient') && (
                    <VyneButton
                      dataTestId='one-time-request-back-button'
                      icon={
                        <LeftCircleOutlined
                          style={{ color: 'var(--charcoal-200, #87878A)' }}
                        />
                      }
                      onClick={handleBackButton}
                      type='link'
                    >
                      Back
                    </VyneButton>
                  )}
                </Col>
                <Col className='one-time-request-modal__footer--actions'>
                  <VyneButton
                    dataTestId='cancel-one-time-request-button'
                    onClick={handleModalClose}
                  >
                    Cancel
                  </VyneButton>
                  {patient.PatientId && (
                    <VyneButton
                      dataTestId='verify-one-time-request-button'
                      form='oneTimeRequestEligibilityForm'
                      htmlType='submit'
                      loading={verifying}
                      type='primary'
                    >
                      Verify
                    </VyneButton>
                  )}
                </Col>
              </Row>,
            ]
          : null
      }
      onCancel={handleModalClose}
      open={isVisible}
      style={{
        top: 20,
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'hidden',
      }}
      styles={{
        body: {
          minWidth: verificationResponse ? 0 : 768,
          maxHeight: 'calc(100vh - 200px)',
          overflow: 'auto',
        },
        content: {
          maxHeight: 'calc(100vh - 40px)',
          overflow: 'hidden',
        },
      }}
      title={modalHeader}
      width={verificationResponse ? '90vw' : 'fit-content'}
    >
      {!patient.PatientId && showContent === 'One-time Request' && (
        <PatientList
          patient={patient}
          setHasPatient={setHasPatient}
          setPatient={setPatient}
          setPatientStatus={setPatientStatus}
        />
      )}
      {!patient.PatientId && showContent === 'Add Patient' && (
        <CarrierList
          patient={patient}
          setPatient={setPatient}
        />
      )}
      {patient.PatientId && !verificationResponse && (
        <EligibilityForm
          hasPatient={hasPatient}
          patient={patient}
          patientStatus={patientStatus}
          setHasPatient={setHasPatient}
          setPatient={setPatient}
          setPatientStatus={setPatientStatus}
          setVerificationResponse={setVerificationResponse}
          setVerifying={setVerifying}
        />
      )}
      {patient.PatientId && verificationResponse && (
        <EligibilityResponse response={verificationResponse} />
      )}
    </VyneModal>
  )
}

export default OneTimeRequestModal
