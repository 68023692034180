import { Dispatch, SetStateAction } from 'react'

import { ArchiveClaim } from 'trellis:api/claim/claim-client'

import { ClaimArchiveGridState } from '../context/ClaimArchiveContext'

export const setRowClass = (_: ArchiveClaim, index: number) => {
  return index % 2 !== 0 ? 'row-gray' : ''
}

export const getPagination = (
  state: ClaimArchiveGridState,
  setState: Dispatch<SetStateAction<ClaimArchiveGridState>>,
) => {
  return {
    showSizeChanger: true,
    current: state.Filters.CurrentPage,
    pageSize: state.Filters.PageSize,
    total: state.Total,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number, pageSize: number) =>
      updatePaging(page, pageSize, state, setState),
  }
}

const updatePaging = (
  CurrentPage: number,
  PageSize: number,
  state: ClaimArchiveGridState,
  setState: Dispatch<SetStateAction<ClaimArchiveGridState>>,
) => {
  const copy = { ...state }
  const { Filters } = copy
  Filters.CurrentPage = CurrentPage
  Filters.PageSize = PageSize
  setState(copy)
}
