import { Observable } from '@legendapp/state'
import { useObservable } from '@legendapp/state/react'

import { PearlCredentials } from 'trellis:api/pearl/pearl-provision-client'
import {
  GetPearlConfigurationStatus,
  GetPearlInstallerInfo,
  ProvisionPearlSystem,
  SaveCredentials,
} from 'trellis:api/pearl/pearlProvisionApiService'

interface PearlStatus {
  hasCredentials: boolean
  hasImageSync: boolean
  error?: string
}

interface UsePearlDownloadReturn {
  status: Observable<PearlStatus>
  isLoading: Observable<boolean>
  refreshStatus: (customerId: number) => Promise<void>
  handleDirectDownload: (customerId: number) => Promise<boolean>
  handleProvisioningFlow: (
    customerId: number,
    imageSystemIdentifier: string,
  ) => Promise<boolean>
  handleSupportCredentials: (
    customerId: number,
    credentials: PearlCredentials,
  ) => Promise<boolean>
}

export const usePearlDownload = (): UsePearlDownloadReturn => {
  const status = useObservable<PearlStatus>({
    hasCredentials: false,
    hasImageSync: false,
  })
  const isLoading = useObservable(false)
  const shouldContinuePolling = useObservable(true)

  const refreshStatus = async (customerId: number) => {
    try {
      isLoading.set(true)
      const pearlStatus = await GetPearlConfigurationStatus(customerId)

      if (pearlStatus?.data) {
        status.set({
          hasCredentials: pearlStatus.data.hasCredentials,
          hasImageSync: pearlStatus.data.hasImageSync,
        })
      }
    } catch (error) {
      status.set({
        ...status.get(),
        error:
          error instanceof Error ? error.message : 'Failed to fetch status',
      })
    } finally {
      isLoading.set(false)
    }
  }

  const handleDirectDownload = async (customerId: number): Promise<boolean> => {
    try {
      isLoading.set(true)
      const installerInfo = await GetPearlInstallerInfo(customerId)

      if (installerInfo?.isReady && installerInfo.installerUrl) {
        window.location.href = installerInfo.installerUrl
        return true
      }

      status.set({
        ...status.get(),
        error: 'Failed to download installer',
      })
      return false
    } finally {
      isLoading.set(false)
    }
  }

  const handleProvisioningFlow = async (
    customerId: number,
    imageSystemIdentifier: string,
  ): Promise<boolean> => {
    try {
      isLoading.set(true)
      shouldContinuePolling.set(true)

      const provisionResult = await ProvisionPearlSystem(customerId, {
        imageSystemIdentifier,
      })

      if (!provisionResult?.facilityId) {
        return false
      }

      let attempts = 0
      const maxAttempts = 15
      const pollingInterval = 5000

      while (shouldContinuePolling.get() && attempts < maxAttempts) {
        const pollResult = await GetPearlConfigurationStatus(customerId)
        const installerInfo = await GetPearlInstallerInfo(customerId)

        if (
          pollResult.data?.hasImageSync &&
          installerInfo?.isReady &&
          installerInfo.installerUrl
        ) {
          window.location.href = installerInfo.installerUrl
          return true
        }

        attempts++
        await new Promise((resolve) => setTimeout(resolve, pollingInterval))
      }

      return false
    } catch (error) {
      status.set({
        ...status.get(),
        error: error instanceof Error ? error.message : 'Failed to provision',
      })
      return false
    } finally {
      shouldContinuePolling.set(false)
      isLoading.set(false)
    }
  }

  const handleSupportCredentials = async (
    customerId: number,
    credentials: PearlCredentials,
  ): Promise<boolean> => {
    try {
      isLoading.set(true)
      const success = await SaveCredentials(customerId, credentials)

      if (success) {
        await refreshStatus(customerId)
        return true
      }

      status.set({
        ...status.get(),
        error: 'Failed to save credentials',
      })
      return false
    } catch (error) {
      status.set({
        ...status.get(),
        error:
          error instanceof Error ? error.message : 'Failed to save credentials',
      })
      return false
    } finally {
      isLoading.set(false)
    }
  }

  return {
    status,
    isLoading,
    refreshStatus,
    handleDirectDownload,
    handleProvisioningFlow,
    handleSupportCredentials,
  }
}
