import { isEmptyValue, spacesToProperty } from 'utils'

export const validateProperty = (
  validator: any,
  formData: any,
  name: any,
  parentProperty: string | null = null,
  required: boolean = false,
  section: string = null,
  label: string = null,
  useLabelForErrors: boolean = false,
): void => {
  let propertyName: string = null
  let lineItemIndex: number = null
  let value: any = null

  if (Array.isArray(name)) {
    propertyName = name[1]
    lineItemIndex = name[0] && parseInt(name[0])
    value = formData[parentProperty][lineItemIndex][propertyName]
  } else {
    propertyName = name
    value = formData[name]
  }

  formData.errors = formData?.errors?.filter((error: any) => {
    if (Array.isArray(name)) {
      return error.property !== propertyName || error.index !== lineItemIndex
    } else {
      return error.property !== propertyName
    }
  })

  let error = null

  if (parentProperty) {
    const lineItems = formData[parentProperty]
    lineItems?.forEach((item, index: number) => {
      error = null
      if (lineItemIndex === index) {
        if (item[propertyName] && validator)
          error = validator(item[propertyName], formData, name)
        if (isEmptyValue(item[propertyName]) && required)
          error = useLabelForErrors
            ? `${label} is required`
            : `${spacesToProperty(propertyName)} is required`
      }

      if (!error) return

      addError(formData, propertyName, error, section, parentProperty, index)
    })
  }

  if (validator) error = validator(value, formData, name)
  if (isEmptyValue(value) && required)
    error = useLabelForErrors
      ? `${label} is required`
      : `${spacesToProperty(propertyName)} is required`

  error && !parentProperty && addError(formData, name, error, section)
}

const addError = (
  formData: any,
  property: string,
  error: string,
  section: string = null,
  parentProperty: string = null,
  index: number = null,
) => {
  formData.errors.push({
    property: property,
    parentProperty: parentProperty,
    message: error,
    section: section,
    index: index,
  })
}
