import { Col, Row } from 'antd'
import { FC } from 'react'

import { handleDateOfServiceDisplay } from '../../shared/sharedFunctions'
import { ImageListPatientDetailsProps } from '../imageSync.interface'

import './imageListPatientDetails.scss'

import { VyneButton } from '@vynedental/design-system'

const ImageListPatientDetails: FC<ImageListPatientDetailsProps> = ({
  loading,
  attachmentInformation,
  imagesSelectedCount,
  handleAddAndClose,
}) => {
  return (
    <>
      <Row className='patient-details__patient-info'>
        <Col span={12}>
          <p>
            <b>
              {attachmentInformation.patientFirstName}{' '}
              {attachmentInformation.patientLastName}
            </b>
          </p>
        </Col>
        <Col
          span={12}
          className='patient-details__header-actions'
        >
          <VyneButton
            dataTestId='select-add-image-button'
            disabled={loading || imagesSelectedCount == 0}
            onClick={() => handleAddAndClose()}
            type='primary'
          >
            {imagesSelectedCount > 0
              ? `Add (${imagesSelectedCount}) Images`
              : `Select Images`}
          </VyneButton>
        </Col>
      </Row>
      <Row className='patient-details__patient-info'>
        <Col span={12}>
          <p className='patient-details__patient-dob'>
            Date of Service:{' '}
            {handleDateOfServiceDisplay(
              attachmentInformation.dateOfServiceFrom,
              attachmentInformation.dateOfServiceTo,
            )}
          </p>
        </Col>
      </Row>
      <Row>&nbsp;</Row>
    </>
  )
}

export default ImageListPatientDetails
